<template>
    <div>
        <div class="form-group">
            <label for="titel" class="required">Titel</label>
            <input type="text" class="form-control" id="titel"
                   name="titel" :value="aufgabe_in.titel"
                   placeholder="Bitte eingeben" required autocomplete="off" required>
            <div class="valid-feedback">
                                                <span class="feather-icon success">
                                                    <i data-feather="check"></i></span>
            </div>
            <div class="invalid-feedback">
                Bitte Bezeichnung eingeben
            </div>
        </div>
        <div class="form-group">
            <label for="text">Text</label>
            <textarea name="text" id="text"
                      class="form-control" rows="6">{{aufgabe_in.text}}</textarea>
        </div>
        <div class="form-group">
            <div class="card">
                <div class="card-header card-header-action bg-light">
                    <h6>Frist</h6>
                </div>
                <div class="card-body">
                    <div class="row mt-15">
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary"
                                 style="">
                                <b-form-radio v-model="frist_datum_switch" name="first_datum_switch" value="0">Ohne Frist
                                </b-form-radio>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary"
                                 style="margin-left: -20px !important;">
                                <b-form-radio v-model="frist_datum_switch" name="first_datum_switch" value="1">Datum
                                    (berechnet)
                                </b-form-radio>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary">
                                <b-form-radio v-model="frist_datum_switch" name="first_datum_switch" value="2">Datum
                                    (fix)
                                </b-form-radio>
                            </div>
                        </div>
                    </div>
                    <div v-if="frist_datum_switch != 0">
                        <br>
                        <div class="row" v-if="frist_datum_switch == 1">
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupMonth">+ Monate</span>
                                    </div>
                                    <input type="text" class="form-control" id="frist_datum_berechnet_monate"
                                           name="frist_datum_berechnet_monate" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupMonth" v-model="frist_datum_berechnet_monate">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupWeek">+ Wochen</span>
                                    </div>
                                    <input type="text" class="form-control" id="frist_datum_berechnet_wochen"
                                           name="frist_datum_berechnet_wochen" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupWeek" v-model="frist_datum_berechnet_wochen">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupDay">+ Tage</span>
                                    </div>
                                    <input type="text" class="form-control" id="frist_datum_berechnet_tage"
                                           name="frist_datum_berechnet_tage" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupDay" v-model="frist_datum_berechnet_tage">
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else-if="frist_datum_switch == 2">
                            <div class="col-md-6 mb-2">
                                <b-form-datepicker id="frist_fix_datum" name="frist_fix_datum" v-model="datum_fix_datum"
                                                   class="mb-2" autocomplete="off"></b-form-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="card">
                <div class="card-header card-header-action bg-light">
                    <h6>Wiederkehrend</h6>
                </div>
                <div class="card-body">
                    <div class="row mt-15">
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary">
                                <b-form-radio v-model="wiederkehrend_switch" name="wiederkehrend_switch" value="0">
                                    Nicht wiederkehrend
                                </b-form-radio>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary"
                                 style="margin-left: -20px !important;">
                                <b-form-radio v-model="wiederkehrend_switch" name="wiederkehrend_switch" value="1">
                                    Intervall
                                    (berechnet)
                                </b-form-radio>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-control custom-radio radio-primary">
                                <b-form-radio v-model="wiederkehrend_switch" name="wiederkehrend_switch" value="2">
                                    Intervall
                                    (Muster)
                                </b-form-radio>
                            </div>
                        </div>
                    </div>
                    <div v-if="wiederkehrend_switch != 0">
                        <br>
                        <div class="row" v-if="wiederkehrend_switch == 1">
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupMonth">+ Monate</span>
                                    </div>
                                    <input type="text" class="form-control" id="wiederkehrend_berechnet_monate"
                                           name="wiederkehrend_berechnet_monate" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupMonth" v-model="wiederkehrend_berechnet_monate">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupWeek">+ Wochen</span>
                                    </div>
                                    <input type="text" class="form-control" id="wiederkehrend_berechnet_wochen"
                                           name="wiederkehrend_berechnet_wochen" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupWeek" v-model="wiederkehrend_berechnet_wochen">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupDay">+ Tage</span>
                                    </div>
                                    <input type="text" class="form-control" id="wiederkehrend_berechnet_tage"
                                           name="wiederkehrend_berechnet_tage" placeholder=""
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupDay" v-model="wiederkehrend_berechnet_tage">
                                </div>
                            </div>
                        </div>
                        <div v-else-if="wiederkehrend_switch == 2">
                            <div class="row">
                                <div class="col-md-4 mb-2">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span
                                                class="input-group-text">{{ wiederkehrend_muster_einheit_label }}</span>
                                        </div>
                                        <b-form-select
                                            v-model="wiederkehrend_muster_einheit"
                                            :options="options_muster_einheit"
                                            id="wiederkehrend_muster_einheit"
                                            name="wiederkehrend_muster_einheit"
                                            autocomplete="off"
                                            @change="change_wiederkehrend_muster_einheit"
                                        ></b-form-select>
                                    </div>
                                </div>

                                <div class="col-md-4 mb-2">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ wiederkehrend_muster_wert_label }}</span>
                                        </div>
                                        <b-form-select
                                            v-model="wiederkehrend_muster_wert"
                                            :options="options_muster_wert"
                                            id="wiederkehrend_muster_wert"
                                            name="wiederkehrend_muster_wert"
                                            autocomplete="off"
                                            @change="change_wiederkehrend_muster_wert"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header card-header-action bg-light">
                <h6>Teilaufgaben <span class="d-inline text-danger"><small>(Nicht mehr editierbar)</small></span></h6>
                <div class="d-flex align-items-center card-action-wrap">
                    <a v-if="false" class="btn btn-primary btn-xs inline-block mr-15 text-white" style="cursor: pointer;"
                       @click="showTeilaufgabeModal">
                        <i class="ion ion-md-add-circle-outline"></i> Teilaufgabe hinzufügen
                    </a>
                    <a class="inline-block mr-15" data-toggle="collapse" href="#collapse_1"
                       aria-expanded="true">
                        <i class="zmdi zmdi-chevron-down"></i>
                    </a>
                </div>
            </div>
            <div id="collapse_1" class="collapse show">
                <div class="card-body bg-light">
                    <div class="row">
                        <div class="col-md-12">
                            <div v-if="teilaufhaben.length">
                                <draggable
                                    :list="teilaufhaben"
                                    :disabled="!enabled"
                                    class="list-group-drag"
                                    ghost-class="ghost"
                                    :move="draggElement"
                                    @start="dragging = true"
                                    @end="function(){dragStoreElement(); dragging = false}"
                                >
                                    <div class="row mt-10" v-for="(teilaufhabe,index) in teilaufhaben">
                                        <div class="col-md-8 d-inline-flex">
                                            <i class="zmdi zmdi-dehaze font-30 mt-5 mr-25 text-light-40"></i>
                                            <b-form-input v-model="teilaufhaben[index].bezeichnung"
                                                          placeholder="Bitte eingeben"
                                                          :id="'bezeichnung' + index"
                                                          :name="'option_'+index"
                                                          :value="teilaufhaben[index].bezeichnung"
                                                          :disabled=true
                                            >
                                            </b-form-input>
                                        </div>
                                        <div class="col-md-2 d-inline-flex text-center" v-if="false">
                                            <b-button @click="removeTeilaufgabe(index)" variant="danger" class="mr-10">
                                                X
                                            </b-button>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                            <div v-else>
                                <div class="alert alert-info">Bisher keine Teilaufgabe angelegt!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group mt-30 mb-30">
            <label for="ids_mitarbeiter" class="required">Empfänger auswählen</label>
            <div style="height: 300px">
                <treeselect_component
                    name_input='ids_mitarbeiter[]'
                    options_input=[]
                    :value_input='values'
                    include_ids_input=[]
                    exclude_ids_input=[]
                    exclude_own_switch='false'
                    multi_switch='true'
                    :api_url='api_url_in'
                    max_height_in='260'
                    limit_in='Infinity'
                />
            </div>
        </div>
        <b-modal id="add_teilaufgabe" title="Teilaufgabe hinzufügen" @ok="addTeilaufgabe()">
            <div class="row mb-15">
                <div class="col-md-12">
                    <div>
                        <div class="form-group mt-15">
                            <label class="required">Bezeichnung</label>
                            <b-form-input id="label" type="text" v-model="add_teilaufgabe.bezeichnung"
                                          placeholder="Bitte eingeben..."></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="add_teilaufgabe.beschreibung"
                                placeholder="Beschreibung..."
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

import draggable from "vuedraggable";

export default {
    props: ['api_url_in', 'aufgabe_in', 'aufgabe_teilaufgaben_in','options_input_in'],
    name: 'AufgabenComponentCreateAufgabe',
    components: {draggable},
    data() {
        return {
            values: '[]',
            enabled: false,
            frist_datum_switch: 0,
            frist_datum_berechnet_monate: null,
            frist_datum_berechnet_wochen: null,
            frist_datum_berechnet_tage: null,
            datum_fix_datum: null,
            wiederkehrend_switch: 0,
            teilaufhaben: [],
            add_teilaufgabe:
                {
                    id: 0,
                    id_baustein_typ: 1,
                    bezeichnung: '',
                    beschreibung: '',
                },
            lang: null,
            wiederkehrend_berechnet_monate: null,
            wiederkehrend_berechnet_wochen: null,
            wiederkehrend_berechnet_tage: null,
            wiederkehrend_muster_einheit: null,
            wiederkehrend_muster_wert: null,
            wiederkehrend_muster_einheit_label: 'Jeden',
            wiederkehrend_muster_wert_label: 'am',
            options_muster_einheit: [
                {
                    value: 2,
                    text: 'Woche',
                    einheitLabel: 'Jede',
                    valueLabel: 'am',
                    musterWertOptions: [
                        {
                            value: 1,
                            text: 'Mo',
                        }, {
                            value: 2,
                            text: 'Di',
                        }, {
                            value: 3,
                            text: 'Mi',
                        }, {
                            value: 4,
                            text: 'Do',
                        }, {
                            value: 5,
                            text: 'Fr',
                        }, {
                            value: 6,
                            text: 'Sa',
                        }, {
                            value: 7,
                            text: 'So',
                        }]
                }, {
                    value: 3,
                    text: 'Monat',
                    einheitLabel: 'Jeden',
                    valueLabel: 'am',
                    musterWertOptions: [{
                        value: 1,
                        text: 'ersten. Tag',
                    }, {
                        value: 2,
                        text: '15.',
                    }, {
                        value: 3,
                        text: 'letzten Tag',
                    }]
                }, {
                    value: 4,
                    text: 'Quartal',
                    einheitLabel: 'Jedes',
                    valueLabel: 'am',
                    musterWertOptions: [
                        {
                            value: 1,
                            text: 'ersten Tag',
                        },
                        {
                            value: 2,
                            text: '15. Tag',
                        },
                        {
                            value: 3,
                            text: 'letzten Tag',
                        }
                    ]
                }, {
                    value: 5,
                    text: 'Halbjahr',
                    einheitLabel: 'Jedes',
                    valueLabel: 'am',
                    musterWertOptions: [
                        {
                            value: 1,
                            text: 'ersten Tag',
                        },
                        {
                            value: 2,
                            text: '15. Tag',
                        },
                        {
                            value: 3,
                            text: 'letzten Tag',
                        }
                    ]
                }, {
                    value: 6,
                    text: 'Jahr',
                    einheitLabel: 'Jedes',
                    valueLabel: 'am',
                    musterWertOptions: [
                        {
                            value: 1,
                            text: 'ersten Januar',
                        }, {
                            value: 2,
                            text: 'ersten Februar',
                        }, {
                            value: 3,
                            text: 'ersten März',
                        }, {
                            value: 4,
                            text: 'ersten April',
                        }, {
                            value: 5,
                            text: 'ersten Mai',
                        }, {
                            value: 6,
                            text: 'ersten Juni',
                        }, {
                            value: 7,
                            text: 'ersten Juli',
                        }, {
                            value: 8,
                            text: 'ersten August',
                        }, {
                            value: 9,
                            text: 'ersten September',
                        }, {
                            value: 10,
                            text: 'ersten Oktober',
                        }, {
                            value: 11,
                            text: 'ersten November',
                        }, {
                            value: 12,
                            text: 'ersten Dezember',
                        },
                    ]
                }],
            options_muster_wert: null,
        }
    },
    computed: {},
    created() {
        var lang = new Lang();
        lang.setLocale(this.locale_in);
        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }

        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
        for (let teilaufgabe of this.aufgabe_teilaufgaben_in) {
            if(teilaufgabe.titel) {
                this.teilaufhaben.push({
                    id: teilaufgabe.id,
                    id_baustein_typ: 1,
                    bezeichnung: teilaufgabe.titel,
                    beschreibung: teilaufgabe.text,
                });
            }
        }

        this.values = this.options_input_in;

        // Check Fristen
        this.frist_datum_switch = this.aufgabe_in.first_datum_switch;

        // Wenn Datum Fix
        if(this.aufgabe_in.frist_fix_datum) {
            this.datum_fix_datum = this.aufgabe_in.frist_fix_datum;
        }

        // Wenn Datum berechnet
        if(this.aufgabe_in.frist_datum_berechnet_monate || this.aufgabe_in.frist_datum_berechnet_wochen || this.aufgabe_in.frist_datum_berechnet_tage) {

            this.frist_datum_berechnet_monate = this.aufgabe_in.frist_datum_berechnet_monate;
            this.frist_datum_berechnet_wochen = this.aufgabe_in.frist_datum_berechnet_wochen;
            this.frist_datum_berechnet_tage = this.aufgabe_in.frist_datum_berechnet_tage;
        }

        // Check Wiederkehrend
        this.wiederkehrend_switch = this.aufgabe_in.wiederkehrend_switch;

        if(this.aufgabe_in.wiederkehrend_berechnet_monate || this.aufgabe_in.wiederkehrend_berechnet_wochen || this.aufgabe_in.wiederkehrend_berechnet_tage){

            this.wiederkehrend_berechnet_monate = this.aufgabe_in.wiederkehrend_berechnet_monate;
            this.wiederkehrend_berechnet_wochen = this.aufgabe_in.wiederkehrend_berechnet_wochen;
            this.wiederkehrend_berechnet_tage = this.aufgabe_in.wiederkehrend_berechnet_tage;
        }

        if(this.aufgabe_in.wiederkehrend_muster_einheit || this.aufgabe_in.wiederkehrend_muster_wert){
            this.wiederkehrend_muster_einheit = this.aufgabe_in.wiederkehrend_muster_einheit;
            this.change_wiederkehrend_muster_einheit();
            this.wiederkehrend_muster_wert = this.aufgabe_in.wiederkehrend_muster_wert;
            this.change_wiederkehrend_muster_wert();
        }
    },
    methods: {
        addTeilaufgabe() {

            let new_element = {...this.add_teilaufgabe};
            this.teilaufhaben.push(new_element);
        },
        removeTeilaufgabe(index) {
            if (index > -1) {
                this.teilaufhaben.splice(index, 1);
            }
        },
        showTeilaufgabeModal() {
            this.$bvModal.show('add_teilaufgabe');
        },
        change_wiederkehrend_muster_einheit() {
            this.options_muster_wert = this.options_muster_einheit.find(item => item.value == this.wiederkehrend_muster_einheit).musterWertOptions;
            this.wiederkehrend_muster_einheit_label = this.options_muster_einheit.find(item => item.value == this.wiederkehrend_muster_einheit).einheitLabel;
        },
        change_wiederkehrend_muster_wert() {
            this.options_muster_wert = this.options_muster_einheit.find(item => item.value == this.wiederkehrend_muster_einheit).musterWertOptions;
            this.wiederkehrend_wert_einheit_label = this.options_muster_einheit.find(item => item.value == this.wiederkehrend_muster_einheit).valueLabel;
        },
        draggElement: function (e) {
            //window.console.log("Future index: " + e.draggedContext.futureIndex);
            //this.triggerStore();
        },
        dragStoreElement: function () {
            //
        },
    },
}
</script>

<style lang="scss">

.custom_control_radio {
    padding-left: 1rem !important;
}

</style>
