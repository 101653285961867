<template>
    <div>
        <div v-for="geltung,geltung_key in geltungen">
            <div class="card">
                <div class="card-header card-header-action bg-light">
                    <h6>{{ geltung.label }} </h6>
                    <div class="d-flex align-items-center card-action-wrap">
                        <a v-if="geltung_key == 0" class="btn btn-primary btn-xs inline-block mr-15 text-white"
                           style="cursor: pointer;" @click.prevent="add_geltung(geltung_key)">
                            <i class="ion ion-md-add-circle-outline"></i> Weitere Geltung
                        </a>
                        <a v-else-if="geltung_key != 0"
                           class="btn btn-danger btn-xs inline-block mr-15 text-white" style="cursor: pointer;"
                           @click.prevent="remove_geltung(geltung_key)">
                            <i class="ion ion-ios-remove-circle-outline"></i> Entfernen
                        </a>
                        <a class="inline-block mr-15" data-toggle="collapse"
                           :href="'#collapse_'+ geltung_key" aria-expanded="true">
                            <i class="zmdi zmdi-chevron-down"></i>
                        </a>
                    </div>
                </div>
                <div :id="'collapse_'+ geltung_key" class="collapse show">
                    <div class="card-body bg-light">
                        <h6 class="text-primary float-right"></h6>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label
                                        :for="'id_org_gruppe_' + geltung_key">Organisationsgruppe</label><br>
                                    <b-form-select
                                        :id="'id_org_gruppe_' + geltung_key"
                                        :name="'id_org_gruppe_' + geltung_key"
                                        v-model="geltung.id_org_gruppe"
                                        :options="filter_org_gruppen(geltung_key, org_gruppen_in)"
                                        value-field="id"
                                        text-field="bezeichnung"
                                        @change="get_org_elemente_by_org_gruppe(geltung_key)"
                                    >
                                    </b-form-select>
                                </div>
                                <div v-if="geltung_key == 0 && geltung.qmb" class="alert alert-info" id="div_qmb"
                                     style="">QMB(s): <br>
                                    <span v-for="qmb,qmb_key in geltung.qmb">
                                        {{ qmb }}<br>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label
                                        :for="'id_org_element_' + geltung_key">Organisationselement</label><br>
                                    <div class="tree-style">
                                        <tree
                                            v-if="geltung.visible"
                                            :data="geltung.org_element_options"
                                            :options="geltung.treeOptions"
                                            :ref="geltung.tree_component_name"
                                        />
                                        <input type="hidden" :name="'ids_org_elemente_' + geltung_key"
                                               :id="'ids_org_elemente_' + geltung_key"
                                               v-model="geltung.ids_org_element_selected">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" name="geltung_count" id="geltung_count"
               :value="geltungen.length -1">
    </div>
</template>
<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

export default {
    props: ['org_gruppen_in', 'locale_in', 'dokument_in', 'geltungen_in'],
    components: {},
    name: "DokmanComponentTreeviewOrgElemente",
    data() {
        return {
            geltungen: [],
            lang: null,
        }
    },
    mounted() {
        if (this.dokument_in && this.geltungen_in) {
            this.get_dokument_geltungen();
        } else {

            let geltung = this.setup_geltung('Hauptgeltung');
            this.geltungen.push(geltung);

            this.geltungen[0].org_element_options = this.get_org_elemente_by_org_gruppe(0);
            this.geltungen[0].visible = 1;
        }
    },
    methods: {

        filter_org_gruppen(geltung_key, org_gruppen) {
            if (geltung_key !== 0) {
                return org_gruppen;
            }

            return org_gruppen.filter(gruppe => gruppe.id_org_typ === 1);
        },

        get_org_elemente_by_org_gruppe(geltung_key) {

            let id_org_element_gruppe = this.geltungen[geltung_key].id_org_gruppe;
            this.geltungen[geltung_key].ids_org_element_selected = [];

            self = this;

            axios.get('/dokman/mein_bereich/api/' + id_org_element_gruppe + '/api_get_org_elemente_with_parent/')
                .then(function (res) {

                    self.geltungen[geltung_key].org_element_options = res.data[0];
                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].setModel(self.geltungen[geltung_key].org_element_options);

                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].$on('node:checked', function (node) {
                        self.compute_nodes_checked(geltung_key, node)
                    });
                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].$on('node:unchecked', function (node) {
                        self.compute_nodes_unchecked(geltung_key, node)
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        get_org_elemente_by_geltung(geltung_key, org_element) {
            self = this;

            let url = '/dokman/mein_bereich/api/api_get_org_elemente_with_parent_by_org_element/' + this.dokument_in.id + '/' + org_element;

            axios.get(url)
                .then(function (res) {
                    self.geltungen[geltung_key].org_element_options = res.data[0];
                    self.geltungen[geltung_key].id_org_gruppe = res.data[0].id_org_element_gruppe;
                    self.geltungen[geltung_key].ids_org_element_selected.push(org_element);
                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].setModel(self.geltungen[geltung_key].org_element_options);

                  /*  console.log('geltung_key')
                    console.log(geltung_key)
                    console.log('self.$refs')
                    console.log(self.$refs)
                    console.log('[self.geltungen[geltung_key].tree_component_name][0]')
                    console.log([self.geltungen[geltung_key].tree_component_name][0])
                    console.log('')
                    console.log('--------------------------')
                    console.log('')*/

                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].$on('node:checked', function (node) {
                        self.compute_nodes_checked(geltung_key, node)
                    });
                    self.$refs[self.geltungen[geltung_key].tree_component_name][0].$on('node:unchecked', function (node) {
                        self.compute_nodes_unchecked(geltung_key, node)
                    });


                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        get_dokument_geltungen() {

            let idx = 0;
            let id_hgb = this.dokument_in.id_hauptgeltungsbereich;

            // Setup Geltung - HGB
            let geltung = this.setup_geltung('Hauptgeltung');
            this.geltungen.push(geltung);
            this.get_org_element_qmb(id_hgb);

            // Befülle HGB
            this.geltungen[idx].org_element_options = this.get_org_elemente_by_geltung(idx, id_hgb);
            this.geltungen[idx].visible = 1;
            idx = idx + 1;

            for (let org_gruppe in this.geltungen_in || []) {
                for (let org_element in this.geltungen_in[org_gruppe]) {
                    let id_org_element = this.geltungen_in[org_gruppe][org_element].id;

                    if(id_org_element !== id_hgb) {
                        let geltung = this.setup_geltung('Weitere Geltung');
                        this.geltungen.push(geltung);

                        this.geltungen[idx].org_element_options = this.get_org_elemente_by_geltung(idx, id_org_element);
                        this.geltungen[idx].visible = 1;
                        idx = idx + 1;
                    }
                }
            }
        },

        setup_geltung(bezeichnung) {
            return {
                label: bezeichnung,
                id_org_gruppe: this.org_gruppen_in[0].id,
                id_org_element: 1,
                org_element_options: {},
                ids_org_element_selected: [],
                tree_component_name: this.tree_view_random_name(),
                visible: false,
                qmb: null,
                treeOptions: {
                    multiple: false,
                    propertyNames: {
                        'text': 'bezeichnung',
                        'children': 'children'
                    },
                    autoCheckChildren: false,
                    parentSelect: true,
                    checkbox: true,
                    dnd: false,
                }
            };
        },

        get_org_element_qmb(id_org_element) {
            self = this;

            axios.get('/dokman/mein_bereich/api/get_qmb/' + id_org_element)
                .then(function (res) {
                    self.geltungen[0].qmb = res.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        tree_view_random_name() {
            return 'tree_component_' + Math.random().toString(36).substring(7);
        },

        add_geltung() {

            let geltung = this.setup_geltung('Weitere Geltung');
            this.geltungen.push(geltung);

            let geltung_key = this.geltungen.length - 1;
            this.get_org_elemente_by_org_gruppe(geltung_key);
            this.geltungen[geltung_key].visible = 1;
        },

        remove_geltung(geltung_key) {
            this.geltungen.splice(geltung_key, 1);
        },

        compute_nodes_checked(geltung_key, node) {

            // Hauptgeltungsbereich -> Single Select
            if (geltung_key == 0) {
                this.geltungen[geltung_key].ids_org_element_selected = [];
                const nodes_all = this.$refs[this.geltungen[geltung_key].tree_component_name][0].findAll({});

                nodes_all.forEach(single_node => {
                    if (geltung_key == 0 && single_node.id != node.id && single_node.checked) {
                        single_node.uncheck();
                    } else {
                        this.geltungen[geltung_key].ids_org_element_selected.push(single_node.id);
                        this.get_org_element_qmb(single_node.id);
                    }
                });
            } else {
                this.geltungen[geltung_key].ids_org_element_selected = [];
                const nodes_all = this.$refs[this.geltungen[geltung_key].tree_component_name][0].findAll({});

                nodes_all.forEach(single_node => {
                    if (single_node.id != node.id && single_node.checked) {
                        single_node.uncheck();
                    } else {
                        this.geltungen[geltung_key].ids_org_element_selected.push(single_node.id);
                    }
                });
            }
        },

        compute_nodes_unchecked(geltung_key, node) {
            var index = this.geltungen[geltung_key].ids_org_element_selected.indexOf(node.id);
            if (index !== -1) {
                this.geltungen[geltung_key].ids_org_element_selected.splice(index, 1);
            }
        },
    }
}
</script>

<style>

.tree-style {
    display: inline-block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.tree-checkbox {
    flex-shrink: 0;
    position: relative;
    width: 20px !important;
    height: 20px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: .25rem !important;
    background: #fff;
    transition: border-color .25s, background-color .25s;
}

.tree-arrow {
    flex-shrink: 0;
    height: 20px;
    cursor: pointer;
    margin-left: 30px;
    width: 0;
}

.tree-checkbox.checked:after {
    transform: rotate(
        45deg
    ) scaleY(1);
}

.tree-checkbox.checked:after {
    box-sizing: content-box;;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 3.5px !important;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 3.5px !important;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-left: 0;
    border-top: 0;
    left: 5px !important;
    top: 1px !important;
    height: 8px !important;
    width: 4px !important;
    transform: rotate(
        45deg
    ) scaleY(0);
    transition: transform .25s;
    transform-origin: center;
}

.tree-arrow.has-child:after {
    border: 0;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    transform: rotate(0deg) translateY(-50%) translateX(-2px);
}

.tree-arrow.expanded.has-child:after {
    transform: rotate(90deg) translateY(0%) translateX(-8px);
}

.tree-node.selected > .tree-content {
    background: #fff !important;
}

.tree-node.selected > .tree-content > .tree-anchor {
    color: #495057 !important;
}

.tree-content {
    padding: 2px 0;
}

.tree-scope {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

</style>
