2<template lang="pug">
  .row
    .col-6
      div(v-if="org_treedata.length || new_treedata.length")
        Tree(:data="org_treedata" draggable cross-tree)
          div(slot-scope="{data, store}")
            template(v-if="!data.isDragPlaceHolder")
              div(@click="editElement(data)")
                b(v-if="data.children && data.children.length" @click="store.toggleOpen(data)") {{data.open ? '-' : '+'}}&nbsp;
                span {{data.bezeichnung ? data.bezeichnung : data.name }}
                br
                div(v-if="data.verantwortungen && data.verantwortungen.length > 0")
                  div(v-for="(element, idx) in data.verantwortungen")
                      span.badge.badge-primary.ml-10(v-if="element") {{element.rangfolge}}. {{element.mitarbeiterfunktion.bezeichnung}}
        .mt-4
          button.btn.btn-primary(@click="collapseAll") {{ lang.get('default.btn_alle_einklappen') }}
          button.btn.btn-primary.ml-1(@click="expandAll") {{ lang.get('default.btn_alle_ausklappen') }}
      div(v-else)
        | {{ lang.get('admin.organisationselement_keine_eintraege') }}
    .col-6
      div.ml-5.he-tree.sticky(v-if="edit_node")
        div.h5.mt-10.mb-25
          | {{lang.get('admin.nav_tab_verantwortungen')}} {{ lang.get('admin.card_fuer') }}
          br
          | {{edit_node.bezeichnung ? edit_node.bezeichnung : edit_node.name}}
        div(v-if="zugeordnete_funktionen.length > 0")
          ul(v-for="(element, idx) in zugeordnete_funktionen" :key="element.bezeichnung")
            li.mb-15
              label.typo__label
                b.text {{idx + 1}}. {{ element.mitarbeiterfunktion.bezeichnung }}
              multiselect(
                v-model="selected_user[element.mitarbeiterfunktion.id]",
                :options="user",
                placeholder="Bitte auswählen",
                label="name",
                :show-labels="false",
                track-by="id",
                :searchable="true",
                :close-on-select="true",
                :multiple="false",
                :preserve-search="false",
                :max-height="400",
                @input="saveAllElements()",
                openDirection="bottom",
              )
                span(slot="noResult")
                  | {{lang.get('admin.alert_keine_funktion_definiert')}}
        div(v-else)
          .alert.alert-warning(role="alert")
            | {{lang.get('admin.alert_bitte_verantwortung_auswaehlen')}}
      .row(v-if="!edit_node")
        .col-12
          .alert.alert-warning(role="alert")
            | {{lang.get('admin.alert_bitte_auswaehlen')}}
</template>

<script>
    //import Treeselect from '@riophae/vue-treeselect';

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import draggable from 'vuedraggable';
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['org_gruppe_id_in', 'org_treedata_in', 'user_in', 'selected_user_in','locale_in'],
        components: {Tree: vueDraggableNestedTree.DraggableTree, draggable, Multiselect},
        data() {
            return {
                org_treedata: this.org_treedata_in,
                selected_user:[],
                user: this.user_in,
                edit_node: null,
                zugeordnete_funktionen: [],
                lang: null,
            }
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);


            if (lang.getLocale() === 'undefined'){
                lang.setLocale('de');
            }

            lang.setMessages(translations);

            this.lang = lang;
        },
        mounted() {

            this.editElement(this.org_treedata[0]);
            this.org_treedata[0].draggable = false;
        },
        methods: {
            expandAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = true
                })
            },
            collapseAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = false
                })
            },
            editElement(data) {
                this.edit_node = data;
                this.count_idx = 0;
                this.zugeordnete_funktionen = [];
                this.getFunctions();
                this.getVerantwortungen();
            },
            getFunctions() {
                self = this;

                axios.get('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_get_funktionen/' + this.edit_node.id)
                    .then(function (res) {
                        self.zugeordnete_funktionen = res.data.verantwortungen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getVerantwortungen() {
                self = this;

                axios.get('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_get_verantwortungen/' + this.edit_node.id)
                    .then(function (res) {
                        self.selected_user = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
           /* saveElement(element) {
                let self = this;

                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_verantwortungen_element/' + this.edit_node.id, {
                    data_zugeordnet: JSON.stringify(this.zugeordnete_funktionen),
                })
                    .then(function (res) {
                        self.refreshTreeData();
                        //self.getFunctions();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },*/
            /*refreshTreeData() {
                self = this;
                axios.get('/admin/org_elemente/gruppen/' + this.org_gruppe_id_in + '/api_get_refresh_tree/')
                    .then(function (res) {
                        self.org_treedata = res.data;
                        //console.log(res);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },*/
            saveAllElements() {

                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_verantwortungen/' + this.edit_node.id, {
                    data: JSON.stringify(this.selected_user),
                })
                    .then(function () {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Ihre Änderungen wurden gespeichert",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">

    .he-tree {
        //border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;

        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;

        border-color: #eaecec;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 14px;
    }

    .tree-node {
    }

    .tree-node-inner {
        //padding: 5px;
        padding: .75rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: .25rem;
        cursor: pointer;
    }

    .draggable-placeholder {
    }

    .draggable-placeholder-inner {
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .edit_input_field {
        width: 80% !important;
        display: inline !important;
    }

    .list-group-item {
        border: 1px solid #ccc !important;
    }

    .button {
        margin-top: 35px;
    }

    .handle {
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .close {
        float: right;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .text {
        color: #5e7d8a;
    }

    // Vue Multiselect

    .multiselect__option--highlight, .multiselect__option--highlight:after{
        //background: #00acf0 !important;
        background: #00acf0;
        outline: none;
        color: #fff;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
    }
</style>

