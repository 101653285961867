<template>
    <div class="row">
        <transition name="fade">
            <div v-if="show" class="card emelder_einstellungen_card_zeile">
                <div class="card-header card-header-action">
                    <h6>{{ (parseInt(index) + 1) }}. Zeile</h6>
                    <div class="d-flex align-items-center card-action-wrap">
                        <a class="inline-block mr-15" data-toggle="collapse" :href="'#collapse_zeile_' + index"
                           aria-expanded="true">
                            <i class="zmdi zmdi-chevron-down"></i>
                        </a>
                        <div class="inline-block dropdown mr-15">
                            <a class="dropdown-toggle no-caret" data-toggle="dropdown" href="#"
                               aria-expanded="false" role="button"><i class="ion ion-md-more"></i></a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" @click="addZeile()"><i
                                    class="dropdown-icon zmdi zmdi-plus-square"></i><span>Zeile hinzufügen</span></a>
                                <a class="dropdown-item" href="#" @click="removeZeile()"><i
                                    class="dropdown-icon zmdi zmdi-minus-square"></i><span>Zeile entfernen</span></a>
                            </div>
                        </div>
                        <a class="inline-block" href="#" @click="removeZeile()">
                            <i class="ion ion-md-close"></i>
                        </a>
                    </div>
                </div>
                <div :id="'collapse_zeile_' + index" class="collapse show">
                    <div class="card-body">
                        <div class="col-sm">
                            <!--   <div class="row" v-if="zeile.spalten.length > 0">-->
                            <div class="row">
                                <draggable
                                    :list="zeile.spalten"
                                    :disabled="!enabled"
                                    class="list-group-drag"
                                    ghost-class="ghost"
                                    :move="draggElement"
                                    @start="dragging = true"
                                    @end="function(){dragStoreElement(); dragging = false}"
                                >
                                    <div :class="'col-lg-' + (12 / zeile.spalten.length) + ' col-sm-12'"
                                         v-for="(spalte,index_spalte) in zeile.spalten">
                                        <MeldungsvorlageElementRightLayoutSpalte
                                            :spalte="spalte"
                                            :index_zeile="index"
                                            :index_spalte="index_spalte"
                                        >
                                        </MeldungsvorlageElementRightLayoutSpalte>
                                    </div>
                                </draggable>
                                <!--</div>
                                <div class="row" v-else-if="typeof zeile.spalten.length === 'undefined' || zeile.spalten.length == 0">
                                    <div class="alert alert-info mb-15 mb-15 w-100">Keine Spalte vorhanden</div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <b-button variant="primary" @click="addZeile(0)">Neue Spalte hinzufügen
                                                </b-button>
                                            </div>
                                        </div>
                                    </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

import draggable from "vuedraggable";

import MeldungsvorlageElementRightLayoutSpalte
    from './EMelderComponentEinstellungenMeldungsvorlagenRightElementLayoutSpalte';

// ***********  Localization ***************

export default {
    props: ['zeile', 'index'],
    components: {draggable, MeldungsvorlageElementRightLayoutSpalte},
    data() {
        return {
            show: true,
            lang: null,
            enabled: true,
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
        addZeile() {
            this.$eventBus.$emit('emelder_meldungsvorlage:add_Zeile', this.index)
        },
        removeZeile() {
            //this.show = false;
            this.$eventBus.$emit('emelder_meldungsvorlage:remove_Zeile', this.index)
        },
        storeSpalten() {
            this.$eventBus.$emit('emelder_meldungsvorlage:store_Spalte', this.index)
        },
        draggElement: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
            //this.triggerStore();
        },
        dragStoreElement: function () {
            let self = this;
            setTimeout(function () {
                self.storeSpalten();
            }, 200)
        },
    }
};
</script>
<style scoped>
.emelder_einstellungen_card_zeile {
    width: 100% !important;
    border-left-color: #00acf0 !important;
    border-left-width: 15px !important;
}

.list-group-drag {
    display: flex !important;
    flex-direction: row !important;
    flex: 0 0 100%;
    max-width: 100%;
}

.close {
    font-size: 20px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
