<template>
    <div class="row">
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Sichtbarkeit bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_1" class="collapse show">
                                        <div class="card-body">
                                            <div class="col-md-12">
                                                <label>Sichtbarkeit auswählen</label><br>
                                                <div class="card mt-10">
                                                    <div class="card-body">
                                                        <div class="row mb-10">
                                                            <div class="col-md-8">
                                                                <div class="form-group ml-15 mt-15">
                                                                    <div class="custom-control custom-radio">
                                                                        <input type="radio" id="id_sichtbarkeit0"
                                                                               name="id_sichtbarkeit"
                                                                               class="custom-control-input" value="2"
                                                                               v-model="sichtbarkeit"
                                                                               @change="changeRadio()">
                                                                        <label class="custom-control-label"
                                                                               for="id_sichtbarkeit0">Privat - Nur für
                                                                            Verantwortliche einsehbar</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio">
                                                                        <input type="radio" id="id_sichtbarkeit1"
                                                                               name="id_sichtbarkeit"
                                                                               class="custom-control-input" value="0"
                                                                               v-model="sichtbarkeit"
                                                                               @change="changeRadio()">
                                                                        <label class="custom-control-label"
                                                                               for="id_sichtbarkeit1">Öffentlich - Für
                                                                            alle angemeldeten Mitarbeiter
                                                                            einsehbar</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio">
                                                                        <input type="radio" id="id_sichtbarkeit2"
                                                                               name="id_sichtbarkeit"
                                                                               class="custom-control-input" value="1"
                                                                               v-model="sichtbarkeit"
                                                                               @change="changeRadio()">
                                                                        <label class="custom-control-label"
                                                                               for="id_sichtbarkeit2">Geltungsbereich -
                                                                            Sichtbar nur für Mitarbeiter aus
                                                                            ausgewählter Geltung</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio">
                                                                        <input type="radio" id="id_sichtbarkeit3"
                                                                               name="id_sichtbarkeit"
                                                                               class="custom-control-input" value="4"
                                                                               v-model="sichtbarkeit"
                                                                               @change="changeRadio()">
                                                                        <label class="custom-control-label"
                                                                               for="id_sichtbarkeit3">Hinweisgebersystem - Für
                                                                            alle nicht angemeldeten Mitarbeiter
                                                                            einsehbar</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sichtbarkeit == 1" class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_2" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Geltungsbereiche bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_2" class="collapse show">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div>
                                                        <span
                                                            class="d-block font-14 font-weight-500 text-dark text-uppercase">Unternehmensstruktur</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12" style="min-height:400px; height:auto">
                                                    <treeselect_component
                                                        name_input="geltunsbereiche"
                                                        :options_input="options"
                                                        :value_input="geltunsbereiche"
                                                        include_ids_input='[]'
                                                        exclude_ids_input='[]'
                                                        exclude_own_switch=false
                                                        multi_switch='true'
                                                        value_consists_of_in='ALL'
                                                        defaultExpandLevel_in="Infinity"
                                                        placeholder_in='Bitte wählen Sie Ihre Geltungsbereiche aus'
                                                        node_label_in='bezeichnung'
                                                        :api_url='api_einstellungen_meldungsvorlagen_sichtbarkeit_get_options_in'
                                                        max_height_in='320'
                                                        limit_in='5'
                                                    />
                                                    <br><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

//Vue.use(VueSweetalert2);
//Vue.use(BootstrapVue);

export default {
    props: ['api_einstellungen_meldungsvorlagen_sichtbarkeit_store_in', 'api_einstellungen_meldungsvorlagen_sichtbarkeit_show_in', 'api_einstellungen_meldungsvorlagen_sichtbarkeit_get_options_in'],
    components: {},
    data() {
        return {
            lang: null,
            sichtbarkeit: 0,
            geltunsbereiche: [],
            options: [],
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;

        let self = this;
        this.$eventBus.$on('emelder_meldungsvorlage:change_treeselect_visibility',  (values, id) => {
            self.geltunsbereiche = values.values;
            self.saveInput();
        });

        this.getInput();
    },
    mounted() {

    },
    watch: {},
    computed: {},
    methods: {
        getInput() {
            let self = this;
            axios.get(this.api_einstellungen_meldungsvorlagen_sichtbarkeit_show_in, {})
                .then(function (res) {
                    self.sichtbarkeit = res.data.sichtbarkeit;
                    self.geltunsbereiche = res.data.geltunsbereiche;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        ,
        changeRadio() {
            this.saveInput();

            if (this.sichtbarkeit == 1) {
                self = this;
                setTimeout(self.getInput(), 1000);

            }
        }
        ,
        getValues(objs) {
            let value = new Array();
            if (objs)
                for (let obj of objs) {
                    value.push(obj.id)
                }
            return '[' + value.join(", ") + ']';
        }
        ,
        saveInput() {
            let self = this;
            axios.post(this.api_einstellungen_meldungsvorlagen_sichtbarkeit_store_in, {
                sichtbarkeit: parseInt(this.sichtbarkeit),
                geltunsbereiche: this.sichtbarkeit == 1 ? this.geltunsbereiche : null,
            })
                .then(function (res) {
                    self.$swal({
                        title: 'Erfolg',
                        text: "Sichtbarkeit erfolgreich gespeichert",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        ,
    }
}
;
</script>
<style scoped>

.emelder_einstellungen_card_zeile {
    width: 100% !important;
    border-left-color: #00acf0 !important;
    border-left-width: 15px !important;
}

</style>
