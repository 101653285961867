<template>
    <div>
        <MeldungsvorlageElementRight
            :vorlage_name_in="this.$parent.vorlage_name_in"
            vorlage_menu_title="Vorschau"
            :is_editable="true">
        </MeldungsvorlageElementRight>

        <div class="chat-body d-flex justify-content-center">
            <div class="col-md-11">
                <div class="mb-30">
                    <MeldungsvorlagenRightElementVorschau
                        :api_einstellungen_meldungsvorlagen_vorschau_show_in="this.$parent.api_einstellungen_meldungsvorlagen_vorschau_show_in"
                        :api_get_org_element_gruppen_in="this.$parent.api_get_org_element_gruppen_in"
                        :api_get_org_elemente_in="this.$parent.api_get_org_elemente_in"
                        :api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in="this.$parent.api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in"
                        :api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in="this.$parent.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in"
                        :api_einstellungen_meldungsvorlagen_kopfzeile_show_in="this.$parent.api_einstellungen_meldungsvorlagen_kopfzeile_show_in"
                        :api_einstellungen_meldungsvorlagen_fusszeile_show_in="this.$parent.api_einstellungen_meldungsvorlagen_fusszeile_show_in">
                    </MeldungsvorlagenRightElementVorschau>
                </div>
                <br><br><br>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    import MeldungsvorlageElementRight from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';

    import MeldungsvorlagenRightElementVorschau from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElementVorschau';


    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: [],
        components: {MeldungsvorlageElementRight,MeldungsvorlagenRightElementVorschau},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>
    .right_page {
        width: 100%;
    }

    .chat-body {
        height: calc(100vh - 458px) !important;
    }
</style>
