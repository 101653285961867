<template>
    <div>
        <b-button class="btn btn-primary" variant="primary" v-b-modal.modal-1>Dokument aktualisieren</b-button>
        <b-modal
                id="modal-1"
                title="Dokument aktualisieren"
                centered
                @ok="add_redaktueur()"
                @show="resetModalRedakteur()"
                modal-ok="Dokument aktualisieren"
        >
            <div class="row mb-15">
                <div class="col-md-12">
                    <label for="aenderung_durch" class="required">Wer soll die Änderung vornehmen?</label>
                    <select name="aenderung_durch" id="aenderung_durch" class="form-control"
                            v-model="aenderung_durch" @change="reloadAendererOptions()">
                        <option value="selbst">Selbst</option>
                        <option value="redakteur">Redakteur</option>
                        <option value="pruefer">Prüfer</option>
                        <option value="sonstiger">Sonstiger Mitarbeiter</option>
                    </select>
                </div>
            </div>

            <div class="row mb-30" v-if="aenderung_durch === 'redakteur'">
                <div class="col-md-12">
                    <label for="aenderung_durch_redakteur" class="required">Auswahl</label>
                    <select name="aenderung_durch_redakteur" id="aenderung_durch_redakteur" class="form-control"
                            v-model="aenderung_durch_redakteur" v-for="redakteur in aenderung_durch_redakteur_in">
                        <option :value="redakteur.id" selected>{{redakteur.user_data_limited.anrede.bezeichnung}} {{redakteur.user_data_limited.titel.bezeichnung}} {{redakteur.user_data_limited.vorname}} {{redakteur.user_data_limited.nachname}} </option>
                    </select>
                </div>
            </div>

            <div class="row mb-30" v-if="aenderung_durch === 'pruefer'">
                <div class="col-md-12">
                    <label for="aenderung_durch_pruefer" class="required">Auswahl</label>
                    <select name="aenderung_durch_pruefer" id="aenderung_durch_pruefer" class="form-control"
                            v-model="aenderung_durch_pruefer" v-for="pruefer in aenderung_durch_pruefer_in">
                        <option :value="pruefer.user.id" selected>{{pruefer.user.user_data_limited.anrede.bezeichnung}} {{pruefer.user.user_data_limited.titel.bezeichnung}} {{pruefer.user.user_data_limited.vorname}} {{pruefer.user.user_data_limited.nachname}} </option>
                    </select>
                </div>
            </div>

            <div class="row mb-30" v-if="aenderung_durch === 'sonstiger'">
                <div class="col-md-12">
                    <div class="form-group d-inline-block">
                        <label class="required">Auswahl</label>
                        <treeselect
                                name="aenderung_durch_sonstige"
                                :multiple="multiple"
                                :clearable="clearable"
                                :searchable="searchable"
                                :searchNested="searchNested"
                                :async="async"
                                :disabled="disabled"
                                :open-on-click="openOnClick"
                                :open-on-focus="openOnFocus"
                                :clear-on-select="clearOnSelect"
                                :close-on-select="closeOnSelect"
                                :always-open="alwaysOpen"
                                :append-to-body="appendToBody"
                                :options="options"
                                :value-consists-of="valueConsistsOf"
                                :loading="loading"
                                :auto-load-root-options="auto_load_root_options"
                                :load-options="loadOptions"
                                :show-count="showCount"
                                :showCountOf="showCountOf"
                                :loadingText="loadingText"
                                :noChildrenText="noChildrenText"
                                :noOptionsText="noOptionsText"
                                :noResultsText="noResultsText"
                                :openDirection="openDirection"
                                :placeholder="placeholder"
                                :required="required"
                                :limit="limit"
                                :max-height="maxHeight"
                                :disableFuzzyMatching="disableFuzzyMatching"
                                v-model="aenderung_durch_sonstige"
                                :value-format="valueFormat"
                                :normalizer="normalizer"
                                @select="onSelect()"
                                @deselect="onDeselect()"
                                @input="onInput()"
                                @open="onOpen()"
                        ></treeselect>
                    </div>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-md-12">
                    <label for="aenderung_aufgabe" class="required">Was soll verändert werden</label>
                    <b-form-textarea
                            id="aenderung_aufgabe"
                            v-model="aenderung_aufgabe"
                            placeholder="Beschreibung der Änderung"
                            rows="5"
                            max-rows="10"
                    ></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                    Abbrechen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Dokument aktualisieren
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
    import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'

    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        props: ['aenderung_durch_redakteur_in', 'aenderung_durch_pruefer_in' ,'signed_api_get_mitarbeiter_route_in','signed_api_post_pruefung_aenderung_route_in','signed_back_to_dokumente_in','eigene_in'],
        name: "PruefungAktualisieren",
        display: "PruefungAktualisieren",
        order: 0,
        components: {
            Treeselect,
        },
        data() {
            return {
                collapsed: true,
                neuer_mitarbeiter_typ: null,
                aenderung_durch: 'selbst',
                aenderung_durch_redakteur: this.aenderung_durch_redakteur_in[0].id,
                aenderung_durch_pruefer: this.aenderung_durch_pruefer_in[0].user.id,
                aenderung_durch_sonstige: null,
                aenderung_aufgabe: null,
                //id_mitarbeiter_aenderung: null,


                // Switch Parameter
                multiple: false,
                disableFuzzyMatching: true,
                include_ids: null,
                exclude_ids: null,
                clearable: true,
                searchable: true,
                searchNested: false,
                async: false,
                disabled: false,
                openOnClick: true,
                openOnFocus: false,
                clearOnSelect: true,
                closeOnSelect: false,
                alwaysOpen: false,
                appendToBody: false,
                valueConsistsOf: 'LEAF_PRIORITY',
                loadingText: 'Lade...',
                noChildrenText: 'Keine Mitarbeiter zugeordnet',
                noOptionsText: 'Keine Daten',
                noResultsText: 'Keine Daten',
                openDirection: 'below',
                placeholder: 'Bitte wählen Sie Ihre Mitarbeiter aus',
                auto_load_root_options: false,
                loading: true,
                required: false,
                maxHeight: 440,
                limit: Infinity,
                showCount: false,
                showCountOf: 'ALL_CHILDREN',
                value: null,
                valueFormat: 'object',
                options: null,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        //children: node.subOptions,
                    }
                },
            };
        },
        mounted() {
            this.$root.$on('ok', (bvEvent, modalId) => {

                alert('hier');
                this.modalOk();
            })
        },
        computed: {
            id_mitarbeiter_aenderung: function () {

                switch(this.aenderung_durch) {

                    case 'selbst':
                        return this.eigene_in;
                        break;

                    case 'redakteur':
                        return this.aenderung_durch_redakteur;
                        break;

                    case 'pruefer':
                        return this.aenderung_durch_pruefer;
                        break;

                    case 'sonstiger':
                        return this.aenderung_durch_sonstige ? this.aenderung_durch_sonstige.id : null;
                        break;
                }
            }
        },
        methods: {
            add_redaktueur: function () {

                let self = this;
                axios.post(this.signed_api_post_pruefung_aenderung_route_in, {
                    id_mitarbeiter_aenderung: this.id_mitarbeiter_aenderung,
                    aenderung_aufgabe: this.aenderung_aufgabe,
                })
                    .then(function (res) {
                        if(res.data == 'success') {
                            self.$swal({
                                title: 'Erfolg',
                                text: "Ihre Aufforderung zur Änderung wurde erstellt.",
                                type: 'success',
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000
                            });

                            window.location.href = self.signed_back_to_dokumente_in;
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            resetModalRedakteur: function () {
            },
            loadOptions({action, parentNode, callback}) {

                if (action === LOAD_ROOT_OPTIONS) {

                    let url = this.signed_api_get_mitarbeiter_route_in;

                    self = this;
                    axios.get(url)
                        .then(function (res) {
                            self.options = res.data;
                            callback();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            },

            cancel: function(){

            },
            reloadAendererOptions: function () {

            },
            onSelect: function(){

            },
            onDeselect: function(){

            },
            onInput: function(){

            },
            onOpen: function(){

            },
        }
    };
</script>
<style scoped>
</style>
