<template>
    <div>
        <a href="javascript:void(0);" class="media" @click="show_webcam = !show_webcam">
            <div class="media-img-wrap mr-5">
                <div class="avatar">
                    <img :src="avatar" alt="user" class="avatar-img rounded-circle">
                </div>

            </div>
            <div class="media-body">
                <div class="container">
                    <div class="row justify-content-start">
                        <div class="col-md-8 text-left pl-0" style="margin-left: 8px !important">
                            <div class="user-name">{{name}}</div>
                            <div v-if="melder" class="melder">[Melder]</div>
                            <div v-else class="melder">[Verantwortlicher]</div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
        <div class="chat-hr-wrap">
            <hr>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['id', 'name', 'avatar','badge_color','last_message','time_last_message','unread_messages_count','link_to_communication_in','melder'],
        components: {},
        data() {
            return {
                lang: null,
                show_webcam: false,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {},
            link_to_communication() {
                window.location.href = this.link_to_communication_in;
            },
        }
    };
</script>
<style scoped>

    .chatapp-wrap .chatapp-left .chatapp-users-list a.media .media-img-wrap .avatar {
        height: 40px;
        width: 40px;
    }

</style>
