<template>
    <div>
        <label for="tags-pills">Verschlagwortung</label>
        <b-form-tags
            :name="input_name"
            :id="input_id"
            v-model="input_value"
            tag-variant="primary"
            tag-pills
            size="lg"
            separator=" "
            placeholder="Bitte eingeben"
            remove-on-delete
            addButtonText="ENTER"
            invalidTagText="Ungültige Eingabe"
            duplicateTagText="Doppelte Eingabe"
        ></b-form-tags>
    </div>
</template>

<script>

export default {
    props: ['input_tags_value_in','input_id_in','input_name_in'],
    name: 'FormInputTagsComponent',
    components: {},
    data() {
        return {
            data: [],
            input_value: this.input_tags_value_in,
            input_id: this.input_id_in,
            input_name: this.input_name_in
        }
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    methods: {},
}
</script>

<style scoped>

div.d-flex {
    width: 100% !important;
}
</style>
