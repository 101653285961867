<template>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-action">
                <h6>Kommentare</h6>
                <div class="d-flex align-items-center card-action-wrap">
                    <a class="inline-block mr-15" data-toggle="collapse"
                       href="#collapse_kommentare_1"
                       aria-expanded="true">
                        <i class="zmdi zmdi-chevron-down"></i>
                    </a>
                </div>
            </div>
            <div id="collapse_kommentare_1" class="collapse show">
                <div class="card-body">
                    <div class="row" v-for="comment in comments">
                        <div class="col-md-12 mb-10">
                            <div class="d-inline-flex">
                                <div class="comment_div_icon">
                                    <div class="media-img-wrap">
                                        <div class="avatar avatar_comment">
                                            <img :src="comment.avatar" alt="user"
                                                 class="avatar-img rounded-circle">
                                        </div>
                                    </div>
                                </div>
                                <div class="comment_div_sender">
                                    <strong>{{comment.user_name}}</strong> <span
                                    class="ml-30">{{comment.created_at}}</span>
                                    <br>
                                    {{comment.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-15">
                        <div class="col-md-12">
                            <b-form-textarea
                                id="textarea-small"
                                size="sm"
                                placeholder="Kommentar eingeben..."
                                v-model="text"
                            ></b-form-textarea>
                            <b-button variant="primary" class="btn-xs mt-15 pull-right" @click="postComment">Kommentar
                                speichern
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    /*
    *    Funktion
    *    -Zeige alle bisher zu diesem Objekt vorhanden Kommentare
    *    -Biete Textarea um neues Kommentar anzulegen
    *
    *    Props
    *    - Aus welchem Modul kommen die Kommentare ( welche Kommentarkategorie )
    *    - Zu welchem Objekt sind die Kommentare
    *    - URL Get Kommentare API
    *    - URL Post Kommentare API
    *
    */

    export default {
        props: ['id_kommentarkategorie_in', 'id_user_in', 'id_object1_in', 'id_object2_in', 'id_object3_in', 'api_get_comments_in','api_post_comment_in'],
        name: 'CommentCreateComponent',
        components: {},
        data() {
            return {
                comments: [
                    {
                        user_name: 'Rene Broo',
                        user_name_short: 'RB',
                        avatar: '',
                        text: 'Das ist mein Kommentar',
                        created_at: '06.10.2020 15:30',
                    },
                ],
                text: null,
            }
        },
        computed: {},
        created() {
            this.getComments();
        },
        mounted() {
        },
        methods: {
            getComments() {

                let url = this.api_get_comments_in;
                self = this;

                axios.post(url, {
                    id_kommentarkategorie: this.id_kommentarkategorie_in,
                    id_object1: this.id_object1_in,
                    id_object2: this.id_object2_in,
                    id_object3: this.id_object3_in,
                    })
                    .then(function (res) {
                        self.comments = res.data.comments;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            postComment() {

                let url = this.api_post_comment_in;
                self = this;

                axios.post(url, {
                    id_kommentarkategorie: this.id_kommentarkategorie_in,
                    id_object1: this.id_object1_in,
                    id_object2: this.id_object2_in,
                    id_object3: this.id_object3_in,
                    text: this.text
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Das Kommentar wurde erfolgreich gespeichert!",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                        self.getComments();
                        self.text = null;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
        },
    }
</script>

<style lang="scss">

    .comment_div_icon {
        width: 60px;
    }

    .comment_div_sender {

    }

    .avatar_comment{
        width: 2.875rem !important;
        height: 2.875rem !important;
    }

</style>
