<template>
    <div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" name="count" :value="list.length">
                <div v-if="list.length > 0">
                    <div
                            class="list-group-item"
                            v-for="element,key in list"
                            :key="element.id"
                    >
                        <div class="card mt-30 mb-30">
                            <div class="card-header ">
                                <div class="d-inline-block float-left">{{key +1}} . Zuordnung</div>
                                <div class="d-flex align-items-center card-action-wrap float-right">
                                    <a class="inline-block" href="#" data-effect="fadeOut"
                                       @click="remove(key)">
                                        <i class="ion ion-md-close"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label :for="'org_gruppe_' + key">Unternehmensstruktur</label>
                                                <select :name="'org_gruppe_' + key" :id="'org_gruppe_' + key"
                                                        class="form-control" @change="change_org_gruppe($event,key)">
                                                    <option v-for="org_gruppe in org_gruppen"
                                                            :value="org_gruppe.id"
                                                            :selected="(org_gruppe.id == element.id_org_gruppe) ? true : false"
                                                    >{{org_gruppe.bezeichnung}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-20">
                                                <label :for="'org_element_' + key">Organisationselement</label>
                                                <select :name="'org_element_' + key" :id="'org_element_' + key"
                                                        class="form-control">
                                                    <option v-for="org_element in org_elemente[key]"
                                                            :value="org_element.id"
                                                            :selected="(org_element.id == element.id_org_element) ? true : false"
                                                    >{{org_element.bezeichnung}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else="list.length == 0">
                    <div class="alert alert-info mt-15 mb-15 w-100">Keine Zuordnung vorhanden</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group mt-15">
                    <b-button class="btn btn-secondary" @click="add()">Neue Zuordnung hinzufügen</b-button>
                    <!--<b-button class="btn btn-secondary" v-b-modal.modal-1>Neue Zuordnung hinzufügen</b-button>

                    <b-modal id="modal-1" title="Neuen Zuordnung hinzufügen" @ok="add()" @show="resetModal()">
                        <div class="row mb-30">
                            <div class="col-md-8">
                                &lt;!&ndash; <label for="aufgabeschritt_vorlage" class="required">{{vorlage_typ}}</label>
                                 <select name="aufgabeschritt_vorlage" id="aufgabeschritt_vorlage" class="form-control"
                                         v-model="new_aufgabeschritt_vorlage">
                                     <option v-for="freigabeschritte_typ in freigabeschritte_typen"
                                             :value="[freigabeschritte_typ.id, freigabeschritte_typ.bezeichnung]">
                                         {{freigabeschritte_typ.bezeichnung}}
                                     </option>
                                 </select>&ndash;&gt;
                            </div>
                        </div>
                    </b-modal>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['zuordnungen_in', 'org_gruppen_in','user_in'],
        name: "MitarbeiterOrganisation",
        display: "MitarbeiterOrganisation",
        components: {},
        data() {
            return {
                list: this.zuordnungen_in,
                org_gruppen: this.org_gruppen_in,
                org_elemente: [],
            };
        },
        created() {
            for (let element in this.list) {
                this.get_org_elemente_by_group(this.list[element].id_org_gruppe, element);
            };

            this.test = 1;
        },
        computed: {},
        methods: {
            resetModal: function () {
            },
            add: function () {
                this.list.push({
                    id_org_gruppe: 1,
                    id_org_element: 0,
                    org_gruppe_bezeichnung: '',
                    org_element_bezeichnung: '',
                 });
                this.get_org_elemente_by_group(1, this.list.length-1);
            },
            remove: function (key) {
                this.list.splice(key, 1);
            },
            change_org_gruppe: function(event,key){
                this.get_org_elemente_by_group(event.target.value, key);
            },
            get_org_elemente_by_group: function (id_org_gruppe, index) {
                self = this;
                axios.get('/admin/mitarbeiter/api_get_org_elemente_by_gruppe/' + id_org_gruppe)
                    .then(function (res) {
                        console.log(res);
                        self.org_elemente[index] = [];
                        for (let i in res.data) {
                            self.org_elemente[index].push(
                                {
                                    id: res.data[i].id,
                                    bezeichnung: res.data[i].bezeichnung,
                                    id_org_gruppe: res.data[i].id_org_element_gruppe,
                                }
                            );
                        }
                        self.$forceUpdate();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            checkMove: function (e) {
            }
        }
    };
</script>
<style scoped>

    .list-group-item {
        border: 2px solid #eaecec !important;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        padding: 0rem 1.25rem !important;
    }
</style>
