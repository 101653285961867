<template>
    <div>
        <div class="chatapp-wrap">
            <communicationElementLeft
                :link_to_communication_in="link_to_communication_in">
            </communicationElementLeft>

            <communicationElementRight
                :icons_path_in="icons_path_in">
            </communicationElementRight>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    import communicationElementLeft from './components/EMelderComponentMeldungenCommunicationLeftElement';
    import communicationElementRight from './components/EMelderComponentMeldungenCommunicationRightElement';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['link_to_communication_in', 'icons_path_in'],
        components: {communicationElementLeft, communicationElementRight},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>
</style>
