<template>
    <div class="row">
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <label id="url_label" class="required d-none">URL<i class="pl-5 zmdi zmdi-copy"
                                                                                    @click="copyLink()"></i></label>
                                <p id="show_url" class="ms-5"> Um die URL und den QR-Code anzuzeigen, bitte die
                                    Sichtbarkeit auf
                                    "Internet" stellen.</p>
                            </div>
                        </div>
                        <div class="row mt-20">
                            <div class="col-lg-12">
                                <label id="qr_label" class="required d-none">QR-Code<i class="pl-5 zmdi zmdi-download"
                                                                                       @click="downloadQr()"></i>
                                </label>
                                <canvas id="canvas"></canvas>
                                <canvas class="d-none" id="download-canvas"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

import VueSweetalert2 from 'vue-sweetalert2';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {calculateSHA256} from "../../../../../../../public/vendor/pdfjs/build/pdf.worker";
import QRCode from "qrcode";

//Vue.use(VueSweetalert2);
//Vue.use(BootstrapVue);

export default {
    props: ['api_einstellungen_meldungsvorlagen_link_store_in', 'api_einstellungen_meldungsvorlagen_link_show_in'],
    components: {},
    data() {
        return {
            lang: null,
            options: [],
            url: null,
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;

        this.getInput();
    },
    mounted() {

    },
    watch: {},
    computed: {},
    methods: {
        getInput() {
            let self = this;
            axios.get(this.api_einstellungen_meldungsvorlagen_link_show_in, {})
                .then(function (res) {
                    self.url = res.data.url;
                    if (self.url != null) {

                        document.getElementById("url_label").classList.remove("d-none")
                        document.getElementById("qr_label").classList.remove("d-none")
                        document.getElementById("url_label").classList.add("d-block")
                        document.getElementById("qr_label").classList.add("d-block")

                        document.getElementById("show_url").innerHTML = self.url;

                        QRCode.toCanvas(document.getElementById('canvas'), self.url, {
                            margin: '0',
                            width: '200',
                            height: '200'
                        }, function (error) {
                            if (error) console.error(error)
                        })

                        QRCode.toCanvas(document.getElementById('download-canvas'), self.url, {
                            margin: '0',
                            width: '300',
                            height: '300'
                        }, function (error) {
                            if (error) console.error(error)
                        })

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        saveInput() {
            let self = this;
            axios.post(this.api_einstellungen_meldungsvorlagen_link_store_in, {})
                .then(function (res) {
                    self.$swal({
                        title: 'Erfolg',
                        text: "Aufgaben & Verantwortungen erfolgreich gespeichert",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        downloadQr() {
            let link = document.createElement('a');
            link.download = 'qr.png';
            link.href = document.getElementById('download-canvas').toDataURL()
            link.click();
        },
        copyLink() {
            navigator.clipboard.writeText(document.getElementById("show_url").innerHTML);
            this.$swal({
                text: "URL kopiert",
                type: 'success',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000
            });
        }
    }
};
</script>
<style scoped>

.emelder_einstellungen_card_zeile {
    width: 100% !important;
    border-left-color: #00acf0 !important;
    border-left-width: 15px !important;
}

</style>
