module.exports = {
    "de.admin": {
        "alert_bitte_auswaehlen": "Bitte w\u00e4hlen Sie ein Org-Element aus",
        "alert_bitte_verantwortung_auswaehlen": "Bitte w\u00e4hlen Sie erst eine Verantwortung f\u00fcr dieses Org-Element aus",
        "alert_keine_funktion_definiert": "Keine Funktionen f\u00fcr dieses Org-Element definiert!",
        "card_fuer": "f\u00fcr",
        "nav_tab_funktionen": "Funktionen",
        "nav_tab_funktionszuordnung": "Funktionszuordnung",
        "nav_tab_personal": "Personal",
        "nav_tab_struktur": "Struktur",
        "nav_tab_verantwortungen": "Verantwortungen",
        "organisationselement_keine_eintraege": "Keine Elemente vorhanden",
        "organisationselement_sinular": "Organisationselement",
        "organisationselemente": "Organisationselemente",
        "organisationsgruppe_singular": "Organisationsgruppe",
        "organisationsgruppen": "Organisationsgruppen"
    },
    "de.auth": {
        "btn_about_us": "\u00dcber uns",
        "btn_help": "Hilfe",
        "btn_login": "Login",
        "btn_reset_password": "Passwort zur\u00fccksetzen",
        "btn_send_pass_reset_link": "Sende E-Mail zum Passwort zur\u00fccksetzen",
        "failed": "Kein Eintrag mit diesen Zugangsdaten vorhanden",
        "label_email": "E-Mail Adresse",
        "label_email_benutzername": "E-Mail Adresse \/ Benutzername",
        "label_password": "Passwort",
        "label_password_confirm": "Passwort Best\u00e4tigung",
        "label_remember_me": "Eingeloggt bleiben",
        "link_reset_password": "Passwort vergessen?",
        "throttle": "Zu viele Anmeldeversuche. Bitte versuchen Sie es in :seconds Sekunden wieder.",
        "txt_headline": "Wir verstehen QM",
        "txt_left_paragraph": "Mehr Flexibilit\u00e4t bei moderner, intuitiver Bedienung!",
        "txt_reset_password": "Setzen Sie Ihr Passwort zur\u00fcck",
        "txt_sign_in": "Melden Sie sich hier an"
    },
    "de.default": {
        "btn_abbrechen": "Abbrechen",
        "btn_aktivieren": "Aktivieren",
        "btn_alle_ausklappen": "Alle ausklappen",
        "btn_alle_einklappen": "Alle einklappen",
        "btn_anlegen": "Anlegen",
        "btn_bearbeiten": "Bearbeiten",
        "btn_deaktivieren": "Deaktivieren",
        "btn_loeschen": "L\u00f6schen",
        "btn_speichern": "Speichern"
    },
    "de.layout.vertical_menu": {
        "administration": "Administration",
        "applikationen": "Applikationen",
        "aufgaben": "Aufgaben",
        "autoqm": "AutoQM",
        "autoqm_einstellungen": "Einstellungen",
        "autoqm_prozesse": "Prozesse",
        "benachrichtigungen": "Benachrichtigungen",
        "changelog": "Changelog",
        "dokman": "Dokman",
        "dokman_dokumente": "Dokumente",
        "dokman_einstellungen": "Einstellungen",
        "dokman_mein_bereich": "Mein Bereich",
        "dokman_upload": "Upload",
        "emelder": "EMelder",
        "emelder_einstellungen": "Einstellungen",
        "emelder_melden": "Melden",
        "emelder_meldungen": "Meldungen",
        "emelder_statistik": "Statistik",
        "hilfe": "Hilfe",
        "mitarbeiter": "Mitarbeiter",
        "mitarbeiter_telefonliste": "Telefonliste",
        "organisation": "Organisation",
        "stammdaten": "Stammdaten",
        "stammdaten_mitarbeiter": "Mitarbeiter",
        "stammdaten_org_gruppen": "Unternehmensstrukturen",
        "support": "Support"
    },
    "de.pagination": {"next": "Next &raquo;", "previous": "&laquo; Previous"},
    "de.passwords": {
        "password": "Das Passwort muss mindestens acht Zeichen lang sein und mit der Best\u00e4tigung \u00fcbereinstimmen",
        "reset": "Ihr Passwort wurde zur\u00fcckgesetzt!",
        "sent": "Wir haben Ihnen einen eine E-Mail mit einem Link zum Zur\u00fccksetzen des Passworts zugesandt!",
        "token": "Ihr Passwort Token ist falsch oder abgelaufen.",
        "user": "Wir k\u00f6nnen keinen Benutzer zu Ihren Eingaben finden."
    },
    "de.strings": {
        "A fresh verification link has been sent to your email address.": "Ein neuer Verifikationslink wurde verschickt.",
        "All rights reserved.": "Alle Rechte vorbehalten.",
        "Before proceeding, please check your email for a verification link.": "Best\u00e4tigen Sie bitte zuerst Ihre E-Mail.",
        "Confirm Password": "Passwort best\u00e4tigen",
        "E-Mail Address": "E-Mail Adresse",
        "Error": "Fehler",
        "Forbidden": "Unzul\u00e4ssig",
        "Forgot Your Password?": "Passwort vergessen?",
        "Go Home": "Nach Hause",
        "Hello!": "Hallo!",
        "If you did not create an account, no further action is required.": "Wenn Sie keinen Account beantragt haben ist keine weitere Handlung n\u00f6tig.",
        "If you did not receive the email": "Wenn Sie keine E-Mail erhalten haben",
        "If you did not request a password reset, no further action is required.": "Wenn Sie keine Zur\u00fccksetzung des Passworts beantragt haben ist keine weitere Handlung n\u00f6tig.",
        "If you\u2019re having trouble clicking the \":actionText\" button, copy and paste the URL below\ninto your web browser: [:actionURL](:actionURL)": "Wenn Sie Schwierigkeiten haben, den \":actionText\" Button zu dr\u00fccken, f\u00fcgen sie bitte folgende Adresse in Ihren Browser ein: [:actionURL](:actionURL)",
        "Login": "Anmelden",
        "Logout": "Abmelden",
        "Name": "Name",
        "Oh no": "Oh nein",
        "Page Expired": "Seite abgelaufen",
        "Page Not Found": "Seite konnte nicht gefunden werden",
        "Password": "Passwort",
        "Please click the button below to verify your email address.": "Bitte klicken Sie den Button, um Ihre E-Mail Adresse zu best\u00e4tigen.",
        "Regards": "Mit freundlichen Gr\u00fc\u00dfen",
        "Register": "Registrieren",
        "Remember Me": "Angemeldet bleiben",
        "Reset Password": "Passwort zur\u00fccksetzen",
        "Reset Password Notification": "Passwort zur\u00fccksetzen Benachrichtung",
        "Send Password Reset Link": "Link zum Zur\u00fccksetzen des Passworts senden",
        "Service Unavailable": "Service nicht verf\u00fcgbar",
        "Sorry, the page you are looking for could not be found.": "Tut uns leid, die Seite konnte nicht gefunden werden.",
        "Sorry, we are doing some maintenance. Please check back soon.": "Tut uns leid, wir arbeiten gerade an unserer Webseite. Bitte versuchen Sie es sp\u00e4ter erneut.",
        "Sorry, you are forbidden from accessing this page.": "Tut uns leid, Sie haben keine Rechte, diese Seite zu besuchen.",
        "Sorry, you are making too many requests to our servers.": "Tut uns leid, Sie machen derzeit zu viele Anfragen an unseren Server.",
        "Sorry, you are not authorized to access this page.": "Tut uns leid, Sie haben keine Rechte, diese Seite zu besuchen.",
        "Sorry, your session has expired. Please refresh and try again.": "Tut uns leid, Ihre Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und probieren Sie es erneut.",
        "This password reset link will expire in :count minutes.": "Dieser Link zum Zur\u00fccksetzen des Passworts l\u00e4uft in :count Minuten ab.",
        "Toggle navigation": "Navigation umschalten",
        "Too Many Requests": "Zu viele Anfragen",
        "Unauthorized": "Nicht autorisiert",
        "Verify Email Address": "E-Mail Adresse best\u00e4tigen",
        "Verify Your Email Address": "Best\u00e4tigen Sie Ihre E-Mail Adresse",
        "Whoops!": "Ups!",
        "Whoops, something went wrong on our servers.": "Ups, etwas ist schief gelaufen.",
        "You are receiving this email because we received a password reset request for your account.": "Sie erhalten diese E-Mail, weil wir einen Antrag auf eine Zur\u00fccksetzung Ihres Passworts bekommen haben.",
        "click here to request another": "klicken Sie hier, um eine neue anzufordern.",
        "hi": "hallo"
    },
    "de.validation": {
        "accepted": ":attribute muss akzeptiert werden.",
        "active_url": ":attribute ist keine korrekte URL.",
        "after": ":attribute muss ein Datum nach dem :date sein.",
        "after_or_equal": ":attribute muss ein Datum nach dem oder am :date sein.",
        "alpha": ":attribute darf nur Buchstaben enthalten.",
        "alpha_dash": ":attribute darf nur Buchstaben, Zahlen und Bindestriche enthalten.",
        "alpha_num": ":attribute darf nur Buchstaben und Zahlen enthalten.",
        "array": ":attribute muss eine Liste sein.",
        "attributes": [],
        "before": ":attribute muss ein Datum vor dem :date sein.",
        "before_or_equal": ":attribute muss ein Datum vor dem oder am :date sein.",
        "between": {
            "array": ":attribute muss zwischen :min und :max Eintr\u00e4ge haben.",
            "file": ":attribute muss zwischen :min und :max Kilobytes sein.",
            "numeric": ":attribute muss zwischen :min und :max sein.",
            "string": ":attribute muss zwischen :min und :max Zeichen sein."
        },
        "boolean": ":attribute muss wahr oder falsch sein.",
        "confirmed": "Die :attribute-Best\u00e4tigung stimmt nicht \u00fcberein.",
        "custom": {"attribute-name": {"rule-name": "custom-message"}},
        "date": ":attribute ist kein g\u00fcltiges Datum.",
        "date_equals": ":attribute muss ein Datum gleich dem :date sein.",
        "date_format": ":attribute entspricht nicht dem Format: :format.",
        "different": ":attribute und :other m\u00fcssen verschieden sein.",
        "digits": ":attribute muss :digits Ziffern lang sein.",
        "digits_between": ":attribute muss zwischen :min und :max Ziffern lang sein.",
        "dimensions": ":attribute hat inkorrekte Bild-Dimensionen.",
        "distinct": ":attribute hat einen doppelten Wert.",
        "email": ":attribute muss eine korrekte E-Mail-Adresse sein.",
        "exists": "Ausgew\u00e4hlte(s) :attribute ist inkorrekt.",
        "file": ":attribute muss eine Datei sein.",
        "filled": ":attribute muss ausgef\u00fcllt werden.",
        "gt": {
            "array": "The :attribute must have more than :value items.",
            "file": "The :attribute must be greater than :value kilobytes.",
            "numeric": "The :attribute must be greater than :value.",
            "string": "The :attribute must be greater than :value characters."
        },
        "gte": {
            "array": "The :attribute must have :value items or more.",
            "file": "The :attribute must be greater than or equal :value kilobytes.",
            "numeric": "The :attribute must be greater than or equal :value.",
            "string": "The :attribute must be greater than or equal :value characters."
        },
        "image": ":attribute muss ein Bild sein.",
        "in": "Ausgew\u00e4hlte(s) :attribute ist inkorrekt.",
        "in_array": ":attribute existiert nicht in :other.",
        "integer": ":attribute muss eine Ganzzahl sein.",
        "ip": ":attribute muss eine korrekte IP-Adresse sein.",
        "ipv4": ":attribute muss eine korrekte IPv4-Adresse sein.",
        "ipv6": ":attribute muss eine korrekte IPv6-Adresse sein.",
        "json": ":attribute muss ein korrekter JSON-String sein.",
        "lt": {
            "array": "The :attribute must have less than :value items.",
            "file": "The :attribute must be less than :value kilobytes.",
            "numeric": "The :attribute must be less than :value.",
            "string": "The :attribute must be less than :value characters."
        },
        "lte": {
            "array": "The :attribute must not have more than :value items.",
            "file": "The :attribute must be less than or equal :value kilobytes.",
            "numeric": "The :attribute must be less than or equal :value.",
            "string": "The :attribute must be less than or equal :value characters."
        },
        "max": {
            "array": ":attribute darf nicht mehr als :max Eintr\u00e4ge enthalten.",
            "file": ":attribute darf nicht gr\u00f6\u00dfer als :max Kilobytes sein.",
            "numeric": ":attribute darf nicht gr\u00f6\u00dfer als :max sein.",
            "string": ":attribute darf nicht l\u00e4nger als :max Zeichen sein."
        },
        "mimes": ":attribute muss eine Datei in folgendem Format sein: :values.",
        "mimetypes": ":attribute muss eine Datei in folgendem Format sein: :values.",
        "min": {
            "array": ":attribute muss mindestens :min Eintr\u00e4ge haben..",
            "file": ":attribute muss mindestens :min Kilobytes gro\u00df sein.",
            "numeric": ":attribute muss mindestens :min sein.",
            "string": ":attribute muss mindestens :min Zeichen lang sein."
        },
        "not_in": "Ausgew\u00e4hlte(s) :attribute ist inkorrekt.",
        "not_regex": "The :attribute format is invalid.",
        "numeric": ":attribute muss eine Zahl sein.",
        "present": ":attribute muss vorhanden sein.",
        "regex": "Das :attribute-Format ist inkorrekt.",
        "required": ":attribute field wird ben\u00f6tigt.",
        "required_if": ":attribute field wird ben\u00f6tigt wenn :other einen Wert von :value hat.",
        "required_unless": ":attribute field wird ben\u00f6tigt au\u00dfer :other ist in den Werten :values enthalten.",
        "required_with": ":attribute field wird ben\u00f6tigt wenn :values vorhanden ist.",
        "required_with_all": ":attribute field wird ben\u00f6tigt wenn :values vorhanden ist.",
        "required_without": ":attribute field wird ben\u00f6tigt wenn :values nicht vorhanden ist.",
        "required_without_all": ":attribute field wird ben\u00f6tigt wenn keine der Werte :values vorhanden ist.",
        "same": ":attribute und :other m\u00fcssen gleich sein.",
        "size": {
            "array": ":attribute muss :size Eintr\u00e4ge enthalten.",
            "file": ":attribute muss :size Kilobytes gro\u00df sein.",
            "numeric": ":attribute muss :size gro\u00df sein.",
            "string": ":attribute muss :size Zeichen lang sein."
        },
        "starts_with": "The :attribute must start with one of the following: :values",
        "string": ":attribute muss Text sein.",
        "timezone": ":attribute muss eine korrekte Zeitzone sein.",
        "unique": ":attribute wurde bereits verwendet.",
        "uploaded": "Der Upload von :attribute schlug fehl.",
        "url": "Das :attribute-Format ist inkorrekt.",
        "uuid": "The :attribute must be a valid UUID."
    },
    "de.vertical_menu": {
        "administration": "Administration",
        "applikationen": "Applikationen",
        "aufgaben": "Aufgaben",
        "aufgaben_ausgang": "Ausgang",
        "aufgaben_eingang": "Eingang",
        "autoqm": "AutoQM",
        "autoqm_einstellungen": "Einstellungen",
        "autoqm_prozesse": "Prozesse",
        "benachrichtigungen": "Benachrichtigungen",
        "changelog": "Changelog",
        "dokman": "Dokman",
        "dokman_dokumente": "Dokumente",
        "dokman_einstellungen": "Einstellungen",
        "dokman_mein_bereich": "Mein Bereich",
        "dokman_upload": "Upload",
        "emelder": "EMelder",
        "emelder_einstellungen": "Einstellungen",
        "emelder_meine_meldungen": "Meine Meldungen",
        "emelder_melden": "Melden",
        "emelder_meldungen": "Meldungen",
        "emelder_risiko": "Risiko",
        "emelder_statistik": "Statistik",
        "hilfe": "Hilfe",
        "hzmodul": "Handzeichenmodul",
        "hzmodul_uebersicht": "\u00dcbersicht",
        "mitarbeiter": "Mitarbeiter",
        "mitarbeiter_telefonliste": "Telefonliste",
        "organisation": "Organisation",
        "stammdaten": "Stammdaten",
        "stammdaten_berechtigungsprofile": "Berechtigungsprofile",
        "stammdaten_berechtigungsrollen": "Berechtigungsrollen",
        "stammdaten_dokumentenarten": "Dokumentenarten",
        "stammdaten_inhaltstypen": "Inhaltstypen",
        "stammdaten_mails": "Mails",
        "stammdaten_managementsysteme": "Managementsysteme",
        "stammdaten_mitarbeiter": "Mitarbeiter",
        "stammdaten_org_elemente": "Org-Elemente",
        "stammdaten_org_gruppen": "Unternehmensstrukturen",
        "support": "Support"
    },
    "en.admin": {
        "alert_bitte_auswaehlen": "Bitte w\u00e4hlen Sie ein Org-Element aus",
        "alert_bitte_verantwortung_auswaehlen": "Bitte w\u00e4hlen Sie erst eine Verantwortung f\u00fcr dieses Org-Element aus",
        "alert_keine_funktion_definiert": "Keine Funktionen f\u00fcr dieses Org-Element definiert!",
        "card_fuer": "f\u00fcr",
        "nav_tab_funktionen": "Funktionen",
        "nav_tab_funktionszuordnung": "Funktionszuordnung",
        "nav_tab_personal": "Personal",
        "nav_tab_struktur": "Struktur",
        "nav_tab_verantwortungen": "Verantwortungen",
        "organisationselement_keine_eintraege": "Keine Elemente vorhanden",
        "organisationselement_sinular": "Organisationselement",
        "organisationselemente": "Organisationselemente",
        "organisationsgruppe_singular": "Organisationsgruppe",
        "organisationsgruppen": "Organisationsgruppen"
    },
    "en.auth": {
        "btn_about_us": "About us",
        "btn_help": "Help",
        "btn_login": "Login",
        "btn_reset_password": "Reset Password",
        "btn_send_pass_reset_link": "Send Password Reset Link",
        "failed": "These credentials do not match our records.",
        "label_email": "Email Address",
        "label_email_benutzername": "Email Address \/ Username",
        "label_password": "Password",
        "label_password_confirm": "Confirm Password",
        "label_remember_me": "Remember Me",
        "link_reset_password": "Forgot Your Password?",
        "throttle": "Too many login attempts. Please try again in :seconds seconds.",
        "txt_headline": "intralean medical 2020",
        "txt_left_paragraph": "Mehr Flexibilit\u00e4t bei moderner, intuitiver Bedienung!",
        "txt_reset_password": "Reset your Password",
        "txt_sign_in": "Sign in to your account."
    },
    "en.default": {
        "btn_abbrechen": "Abbrechen",
        "btn_aktivieren": "Aktivieren",
        "btn_alle_ausklappen": "Alle ausklappen",
        "btn_alle_einklappen": "Alle einklappen",
        "btn_anlegen": "Anlegen",
        "btn_bearbeiten": "Bearbeiten",
        "btn_deaktivieren": "Deaktivieren",
        "btn_loeschen": "L\u00f6schen",
        "btn_speichern": "Save"
    },
    "en.layout.vertical_menu": {
        "administration": "Administration",
        "applikationen": "Applikationen",
        "aufgaben": "Aufgaben",
        "autoqm": "AutoQM",
        "autoqm_einstellungen": "Einstellungen",
        "autoqm_prozesse": "Prozesse",
        "benachrichtigungen": "Benachrichtigungen",
        "changelog": "Changelog",
        "dokman": "Dokman",
        "dokman_dokumente": "Dokumente",
        "dokman_einstellungen": "Einstellungen",
        "dokman_mein_bereich": "Mein Bereich",
        "dokman_upload": "Upload",
        "emelder": "EMelder",
        "emelder_einstellungen": "Einstellungen",
        "emelder_melden": "Melden",
        "emelder_meldungen": "Meldungen",
        "emelder_statistik": "Statistik",
        "hilfe": "Hilfe",
        "mitarbeiter": "Mitarbeiter",
        "mitarbeiter_telefonliste": "Telefonliste",
        "organisation": "Organisation",
        "stammdaten": "Stammdaten",
        "stammdaten_mitarbeiter": "Mitarbeiter",
        "stammdaten_org_gruppen": "Unternehmensstrukturen",
        "support": "Support"
    },
    "en.pagination": {"next": "Next &raquo;", "previous": "&laquo; Previous"},
    "en.passwords": {
        "password": "Passwords must be at least eight characters and match the confirmation.",
        "reset": "Your password has been reset!",
        "sent": "We have e-mailed your password reset link!",
        "token": "This password reset token is invalid.",
        "user": "We can't find a user with that e-mail address."
    },
    "en.validation": {
        "accepted": "The :attribute must be accepted.",
        "active_url": "The :attribute is not a valid URL.",
        "after": "The :attribute must be a date after :date.",
        "after_or_equal": "The :attribute must be a date after or equal to :date.",
        "alpha": "The :attribute may only contain letters.",
        "alpha_dash": "The :attribute may only contain letters, numbers, dashes and underscores.",
        "alpha_num": "The :attribute may only contain letters and numbers.",
        "array": "The :attribute must be an array.",
        "attributes": [],
        "before": "The :attribute must be a date before :date.",
        "before_or_equal": "The :attribute must be a date before or equal to :date.",
        "between": {
            "array": "The :attribute must have between :min and :max items.",
            "file": "The :attribute must be between :min and :max kilobytes.",
            "numeric": "The :attribute must be between :min and :max.",
            "string": "The :attribute must be between :min and :max characters."
        },
        "boolean": "The :attribute field must be true or false.",
        "confirmed": "The :attribute confirmation does not match.",
        "custom": {"attribute-name": {"rule-name": "custom-message"}},
        "date": "The :attribute is not a valid date.",
        "date_equals": "The :attribute must be a date equal to :date.",
        "date_format": "The :attribute does not match the format :format.",
        "different": "The :attribute and :other must be different.",
        "digits": "The :attribute must be :digits digits.",
        "digits_between": "The :attribute must be between :min and :max digits.",
        "dimensions": "The :attribute has invalid image dimensions.",
        "distinct": "The :attribute field has a duplicate value.",
        "email": "The :attribute must be a valid email address.",
        "exists": "The selected :attribute is invalid.",
        "file": "The :attribute must be a file.",
        "filled": "The :attribute field must have a value.",
        "gt": {
            "array": "The :attribute must have more than :value items.",
            "file": "The :attribute must be greater than :value kilobytes.",
            "numeric": "The :attribute must be greater than :value.",
            "string": "The :attribute must be greater than :value characters."
        },
        "gte": {
            "array": "The :attribute must have :value items or more.",
            "file": "The :attribute must be greater than or equal :value kilobytes.",
            "numeric": "The :attribute must be greater than or equal :value.",
            "string": "The :attribute must be greater than or equal :value characters."
        },
        "image": "The :attribute must be an image.",
        "in": "The selected :attribute is invalid.",
        "in_array": "The :attribute field does not exist in :other.",
        "integer": "The :attribute must be an integer.",
        "ip": "The :attribute must be a valid IP address.",
        "ipv4": "The :attribute must be a valid IPv4 address.",
        "ipv6": "The :attribute must be a valid IPv6 address.",
        "json": "The :attribute must be a valid JSON string.",
        "lt": {
            "array": "The :attribute must have less than :value items.",
            "file": "The :attribute must be less than :value kilobytes.",
            "numeric": "The :attribute must be less than :value.",
            "string": "The :attribute must be less than :value characters."
        },
        "lte": {
            "array": "The :attribute must not have more than :value items.",
            "file": "The :attribute must be less than or equal :value kilobytes.",
            "numeric": "The :attribute must be less than or equal :value.",
            "string": "The :attribute must be less than or equal :value characters."
        },
        "max": {
            "array": "The :attribute may not have more than :max items.",
            "file": "The :attribute may not be greater than :max kilobytes.",
            "numeric": "The :attribute may not be greater than :max.",
            "string": "The :attribute may not be greater than :max characters."
        },
        "mimes": "The :attribute must be a file of type: :values.",
        "mimetypes": "The :attribute must be a file of type: :values.",
        "min": {
            "array": "The :attribute must have at least :min items.",
            "file": "The :attribute must be at least :min kilobytes.",
            "numeric": "The :attribute must be at least :min.",
            "string": "The :attribute must be at least :min characters."
        },
        "not_in": "The selected :attribute is invalid.",
        "not_regex": "The :attribute format is invalid.",
        "numeric": "The :attribute must be a number.",
        "present": "The :attribute field must be present.",
        "regex": "The :attribute format is invalid.",
        "required": "The :attribute field is required.",
        "required_if": "The :attribute field is required when :other is :value.",
        "required_unless": "The :attribute field is required unless :other is in :values.",
        "required_with": "The :attribute field is required when :values is present.",
        "required_with_all": "The :attribute field is required when :values are present.",
        "required_without": "The :attribute field is required when :values is not present.",
        "required_without_all": "The :attribute field is required when none of :values are present.",
        "same": "The :attribute and :other must match.",
        "size": {
            "array": "The :attribute must contain :size items.",
            "file": "The :attribute must be :size kilobytes.",
            "numeric": "The :attribute must be :size.",
            "string": "The :attribute must be :size characters."
        },
        "starts_with": "The :attribute must start with one of the following: :values",
        "string": "The :attribute must be a string.",
        "timezone": "The :attribute must be a valid zone.",
        "unique": "The :attribute has already been taken.",
        "uploaded": "The :attribute failed to upload.",
        "url": "The :attribute format is invalid.",
        "uuid": "The :attribute must be a valid UUID."
    },
    "en.vertical_menu": {
        "administration": "Administration",
        "applikationen": "Applikationen",
        "aufgaben": "Aufgaben",
        "aufgaben_ausgang": "Ausgang",
        "aufgaben_eingang": "Eingang",
        "autoqm": "AutoQM",
        "autoqm_einstellungen": "Einstellungen",
        "autoqm_prozesse": "Prozesse",
        "benachrichtigungen": "Benachrichtigungen",
        "changelog": "Changelog",
        "dokman": "Dokman",
        "dokman_dokumente": "Dokumente",
        "dokman_einstellungen": "Einstellungen",
        "dokman_mein_bereich": "Mein Bereich",
        "dokman_upload": "Upload",
        "emelder": "EMelder",
        "emelder_einstellungen": "Einstellungen",
        "emelder_melden": "Melden",
        "emelder_meldungen": "Meldungen",
        "emelder_risiko": "Risiko",
        "emelder_statistik": "Statistik",
        "hilfe": "Hilfe",
        "mitarbeiter": "Mitarbeiter",
        "mitarbeiter_telefonliste": "Telefonliste",
        "organisation": "Organisation",
        "stammdaten": "Stammdaten",
        "stammdaten_berechtigungsprofile": "Berechtigungsprofile",
        "stammdaten_berechtigungsrollen": "Berechtigungsrollen",
        "stammdaten_dokumentenarten": "Dokumentenarten",
        "stammdaten_inhaltstypen": "Inhaltstypen",
        "stammdaten_mails": "Mails",
        "stammdaten_managementsysteme": "Managementsysteme",
        "stammdaten_mitarbeiter": "Mitarbeiter",
        "stammdaten_org_elemente": "Org-Elemente",
        "stammdaten_org_gruppen": "Unternehmensstrukturen",
        "support": "Support"
    }
};
