<template>
    <div>
        <div class="row">
            <div class="col-md-12 mt-15">
                <label>Optionen</label>
            </div>
        </div>
        <div v-if="options.length !== 0">
            <draggable
                :list="options"
                :disabled="!enabled"
                class="list-group-drag"
                ghost-class="ghost"
                :move="draggElement"
                @start="dragging = true"
                @end="function(){dragStoreElement(); dragging = false}"
            >
             <div class="row mt-10" v-for="(option,index) in options">
            <div class="col-md-8 d-inline-flex">
                <i class="zmdi zmdi-dehaze font-30 mt-5 mr-25 text-light-40"></i>
                <b-form-input v-model="options[index].bezeichnung" placeholder="Bitte eingeben"
                              :id="'bezeichnung' + index"></b-form-input>
                <input type="hidden" :name="'option_'+index" :value="options[index].bezeichnung">
            </div>
            <div class="col-md-2 d-inline-flex text-center">
                <b-button @click="removeOption(index)" variant="danger" class="mr-10">X</b-button>
            </div>
        </div>
            </draggable>
        </div>
        <div v-else class="row">
            <div class="col-md-12">
                <b-alert show variant="info">Aktuell noch keine Optionen vorhanden</b-alert>
            </div>
        </div>
        <b-button @click="newOption()" variant="primary" class="mt-15 mb-15">
            Neue Option hinzufügen
        </b-button>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import draggable from "vuedraggable";

    export default {
        props: ['options_in'],
        components: {draggable},
        data() {
            return {
                lang: null,
                enabled: true,
                options: []
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.options = JSON.parse(this.options_in);
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
            newOption() {
                this.options.push([{
                    bezeichnung: 'test',
                    wert: 'Ja'
                }])
            },
            removeOption(index) {
                if (index > -1) {
                    this.options.splice(index, 1);
                }
            },
            draggElement: function (e) {
                //window.console.log("Future index: " + e.draggedContext.futureIndex);
                //this.triggerStore();
            },
            dragStoreElement: function () {
                //
            },
        }
    };
</script>
<style scoped>
</style>
