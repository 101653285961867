<template>
    <div>
        <div class="riskMatrix_filter_bar mb-15">
            <div class="row">
                <div class="col-md-12 ml-30">
                    <b-input-group size="sm">
                        <template #prepend>
                            <b-input-group-text style="height: 40px;">Zeitraum: </b-input-group-text>
                        </template>
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="localeData"
                        :minDate="minDate" :maxDate="maxDate"
                        :singleDatePicker="singleDatePicker"
                        :timePicker="timePicker"
                        :timePicker24Hour="timePicker24Hour"
                        :showWeekNumbers="showWeekNumbers"
                        :showDropdowns="showDropdowns"
                        :autoApply="autoApply"
                        :ranges="ranges"
                        v-model="dateRange"
                        @update="updateValues"
                        @toggle="logEvent('event: open', $event)"
                        @start-selection="logEvent('event: startSelection', $event)"
                        @finish-selection="logEvent('event: finishSelection', $event)"
                        :linkedCalendars="linkedCalendars"
                    >
                    </date-range-picker>
                    </b-input-group>
                </div>
            </div>
        </div>
        <div class="riskMatrix_x_label">Schadensausmaß</div>
        <div class="riskMatrix_y_label">Auftrittswahrscheinlichkeit</div>
        <div class="riskMatrix border rounded pa-30 ml-30">
            <span v-for="risiko in risikomeldungen">
                <span class="risk_point" :style="{ top: calc_y(risiko.avg_auftrittswahrscheinlichkeit) + 'px', left: calc_x(risiko.avg_auftrittswahrscheinlichkeit) + 'px' }">
                    <i class="ion ion-md-flash risk_point_cursor" v-b-popover.hover.top="'Klicken Sie hier'"
                       title="Risko 134232"></i>
                </span>
            </span>
            <table>
                <tr v-for="(auftritt,auftritt_index) in auftrittswahrscheinlichkeit">
                    <td v-for="(schaden,schaden_index) in schadensausmass">
                        <span v-if="auftritt_index == 0">{{ schaden_index }}</span>
                        <span v-else-if="schaden_index == 0">{{ auftritt_index }}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>


// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    props: ['api_risikomeldungen_url_in'],
    name: 'RiskMatrixComponent',
    components: { DateRangePicker },
    data() {
        let startDate = new Date();
        let endDate = new Date();
        startDate.setMonth(startDate.getMonth() - 12)

        let today = new Date()
        today.setHours(0, 0, 0, 0)

        let yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0);

        let thisMonthStart = startDate.getMonth();
        let thisMonthEnd = endDate.getMonth();

        return {
            auftrittswahrscheinlichkeit: [
                {
                    'label': '',
                    'color': 'bg-white',
                    'value': 0
                },
                {
                    'label': 'unwahrscheinlich',
                    'color': 'bg-success',
                    'value': 1
                },
                {
                    'label': 'sehr selten',
                    'color': 'bg-success',
                    'value': 2
                },
                {
                    'label': 'sehr selten',
                    'color': 'bg-success',
                    'value': 3
                },
                {
                    'label': 'mäßig wahrscheinlich',
                    'color': 'bg-success',
                    'value': 4
                },
                {
                    'label': 'mäßig wahrscheinlich',
                    'value': 5,
                    'color': 'bg-success'
                },
                {
                    'label': 'mäßig wahrscheinlich',
                    'value': 6,
                    'color': 'bg-success'
                },
                {
                    'label': 'sehr wahrscheinlich',
                    'value': 7,
                    'color': 'bg-success'
                },
                {
                    'label': 'sehr wahrscheinlich',
                    'value': 8,
                    'color': 'bg-success'
                },
                {
                    'label': 'sicher',
                    'value': 9,
                    'color': 'bg-success'
                },
                {
                    'label': 'sicher',
                    'value': 10,
                    'color': 'bg-success'
                },
            ],
            schadensausmass: [
                {
                    'label': '',
                    'color': 'bg-white',
                    'value': 0
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                },
                {
                    'label': 'Nicht/kaum wahrnehmbar',
                    'color': 'bg-success',
                    value: 1
                }
            ],
            lang: null,
            dateRange: {startDate, endDate},
            opens: "right",
            minDate: null,
            maxDate: null,
            singleDatePicker: false,
            timePicker: false,
            timePicker24Hour: false,
            showWeekNumbers: true,
            showDropdowns: true,
            autoApply: true,
            linkedCalendars:false,
            dateFormat:null,
            localeData: {
                direction: 'ltr',
                format: 'dd.mm.yyyy',
                separator: ' - ',
                applyLabel: 'Anwenden',
                cancelLabel: 'Abbrechen',
                weekLabel: 'W',
                customRangeLabel: 'Benutzerdefiniert',
                daysOfWeek: ['So','Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                monthNames: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                firstDay: 1
            },
            ranges: false,
            risikomeldungen:[],
        }
    },
    filters: {
        dateCell (value) {
            let dt = new Date(value)
            return dt.getDate()
        },
        date (val) {
            return val ? val.toLocaleString() : ''
        },
    },
    computed: {},
    created() {
        var lang = new Lang();
        lang.setLocale(this.locale_in);
        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }

        lang.setMessages(translations);
        this.lang = lang;

        this.get_risiko_meldungen();
    },
    mounted() {

    },
    methods: {
        get_risiko_meldungen(){
            let url = this.api_risikomeldungen_url_in;
            self = this;

            axios.post(url,{
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
            })
                .then(function (res) {
                    console.log(res.data);
                    self.risikomeldungen = res.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        updateValues(){
            this.get_risiko_meldungen();
        },
        logEvent(){},
        calc_x(value){
            let min = 136;
            let max = 654;
            let diff=max-min;
            let step=diff/10;

            return min+(step*value);
        },
        calc_y(value){
            let min = 156;
            let max = 545;
            let diff=max-min;
            let step=diff/10;

            return min+(step*value);
        },
        /*randn_bm(min, max, skew) {
            var u = 0, v = 0;
            while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
            while (v === 0) v = Math.random();
            let num = Math.sqrt(-4.0 * Math.log(u)) * Math.cos(4.0 * Math.PI * v);

            num = num / 10.0 + 0.5; // Translate to 0 -> 1
            if (num > 1 || num < 0) num = this.randn_bm(min, max, skew); // resample between 0 and 1 if out of range
            num = Math.pow(num, skew); // Skew
            num *= max - min; // Stretch to fill range
            num += min; // offset to min
            return num;
        },
        random_x_point() {
            let min = 136;
            let max = 654;
            return this.randn_bm(min, max, 0.75)
        },
        random_y_point() {
            let min = 156;
            let max = 545;
            return this.randn_bm(min, max, 0.75)
        },
        random_point_count() {
            let min = 40;
            let max = 60;
            return Math.floor(Math.random() * (max - min + 1) + min);
        }*/
    },
}
</script>

<style lang="scss">

div.riskMatrix_x_label {
    font-weight: 600;
    text-align: center;
}

div.riskMatrix_y_label {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    position: absolute;
    font-weight: 600;
    left: -74px;
    bottom: 256px;
}

.risk_point {
    position: absolute;

    //box-shadow: 0 0 15px #ffee10;
    color: #ffee10;
    //box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
}

.risk_point_cursor {
    cursor: pointer;
}

div.riskMatrix {

    table {
        height: 100%;
        width: 100%;
        background: rgb(34, 175, 71);
        background: linear-gradient(-255deg, rgba(34, 175, 71, 1) 10%, rgba(255, 191, 54, 1) 40%, rgba(255, 191, 54, 1) 60%, rgba(248, 63, 55, 1) 90%);

        tr {

            border: 1px solid rgba(0, 0, 0, .2);

            &:first-of-type {
                td {
                    text-align: center;
                    padding-top: 10px;
                    color: #324148;

                    font-size: 1.2em;
                    font-weight: bold;
                }
            }

            td {
                height: 40px;
                width: 40px;

                border: 1px solid rgba(0, 0, 0, .2);

                &:first-of-type {
                    width: 30px;
                    height: 30px;

                    margin-top: 10px;

                    font-size: 1.2em;
                    font-weight: bold;
                    color: #324148;

                    text-align: right;
                    padding-right: 20px;
                }

                &.green {
                    background-color: #22af47;
                }

                &.yellow {
                    background-color: #ffbf36;
                }

                &.red {
                    background-color: #f83f37;
                }
            }
        }
    }
}

</style>
