import Vue from "vue";
import store from './store/store';
import VueRouter from "vue-router";
import Default from "./defaultPages/Default";
import emelderMeldungsvorlagenRouting from "./components/emelder/einstellungen/meldungsvorlagen/meldungsvorlagen-routing";
import LiquorTree from 'liquor-tree';
import VueSweetalert2 from 'vue-sweetalert2';
import print from 'print-js';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ToggleButton from 'vue-js-toggle-button';

require('./bootstrap');
require('bootstrap-treeview/dist/bootstrap-treeview.min.js');

window.onload = function () {

    var check_vue = document.getElementById("vue-app");
    if (check_vue) {
        window.Vue = require('vue');

        Vue.prototype.$eventBus = new Vue(); // Global event bus

        // Globaler Store
        Vue.prototype.$store = new Vue({
            store: store,
        });

        //window.VueRouter = require('vue-router');

        Vue.use(VueRouter)

        const defaultRoutes = [
            {
                path: '*',
                name: 'Default',
                component: Default
            }
        ]

        let routes = [];

        routes = routes.concat(defaultRoutes, emelderMeldungsvorlagenRouting)
        const router = new VueRouter({
            mode: 'history',
            base: process.env.BASE_URL,
            routes
        })

        Vue.use(LiquorTree)
        Vue.use(VueSweetalert2);
        Vue.use(BootstrapVue);
        Vue.use(ToggleButton);

        // ***********  Localization ***************

        window.Lang = require('lang.js');

        // ***********  Bootstrap Treeview ***************
        //window.bootstrap-treeview = require('bootstrap-treeview/dist/bootstrap-treeview.min.js');
        // *****************************************

        // const files = require.context('./', true, /\.vue$/i);
        // files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

        /*Vue.component('example-component', require('./components/ExampleComponent.vue').default);*/
        Vue.component('treeselect_component', require('./components/TreeselectComponent.vue').default);
        Vue.component('copy-to-clipboard-component', require('./components/CopyToClipboardComponent.vue').default);
        Vue.component('form-input-tags-component', require('./components/FormInputTagsComponent.vue').default);
        Vue.component('comment-create-component', require('./components/CommentCreateComponent.vue').default);

        Vue.component('aufgaben-component-create-aufgabe', require('./components/aufgaben/AufgabenComponentCreateAufgabe.vue').default);
        Vue.component('aufgaben-component-edit-aufgabe', require('./components/aufgaben/AufgabenComponentEditAufgabe.vue').default);
        Vue.component('aufgaben-component-aufgabe-rueckmeldung', require('./components/aufgaben/AufgabenComponentAufgabeRueckmeldung.vue').default);
        Vue.component('aufgaben-component-aufgabe-delegieren', require('./components/aufgaben/AufgabenComponentAufgabeDelegieren.vue').default);
        Vue.component('aufgaben-component-aufgabe-delegieren-rueckmeldung', require('./components/aufgaben/AufgabenComponentAufgabeDelegierenRueckmeldung.vue').default);
        Vue.component('aufgaben-component-index-search', require('./components/aufgaben/AufgabenComponentIndexSearch.vue').default);
        Vue.component('aufgaben-component-index-main-table', require('./components/aufgaben/AufgabenComponentIndexMainTable.vue').default);

        //Vue.component('draggable_nested_tree-component-struktur', require('./components/DraggableNestedTreeComponentStruktur.vue').default);
        Vue.component('org-element-component-struktur', require('./components/org_element/OrgElementComponentStruktur.vue').default);
        Vue.component('org-element-component-funktionen', require('./components/org_element/OrgElementComponentFunktionen.vue').default);
        Vue.component('org-element-component-verantwortungen', require('./components/org_element/OrgElementComponentVerantwortungen.vue').default);
        Vue.component('org-element-component-mitglieder', require('./components/org_element/OrgElementComponentMitglieder.vue').default);

        Vue.component('mitarbeiter-component-organisation', require('./components/org_element/MitarbeiterComponentOrganisation.vue').default);

        Vue.component('dokman-component-freigabeschritt', require('./components/dokman/einstellungen/DokmanComponentFreigabeSchritte.vue').default);
        Vue.component('dokman-component-pruefunsschritt', require('./components/dokman/einstellungen/DokmanComponentPruefungSchritte.vue').default);
        Vue.component('dokman-component-optionen', require('./components/dokman/einstellungen/DokmanComponentOptionenMitarbeiter.vue').default);
        Vue.component('dokman-lenkung-component-inhalt', require('./components/dokman/lenkung/DokmanComponentLenkungInhalt.vue').default);
        Vue.component('dokman-lenkung-component-freigabe', require('./components/dokman/lenkung/DokmanComponentLenkungFreigabe.vue').default);
        Vue.component('dokman-lenkung-component-pruefung', require('./components/dokman/lenkung/DokmanComponentLenkungPruefung.vue').default);
        Vue.component('dokman-lenkung-component-durchdringung', require('./components/dokman/lenkung/DokmanComponentLenkungDurchdringung.vue').default);
        Vue.component('dokman-freigabe-component-freigabe', require('./components/dokman/freigabe/DokmanComponentFreigabeFreigabe.vue').default);
        Vue.component('dokman-pruefung-component-pruefung', require('./components/dokman/pruefung/DokmanComponentPruefungPruefung.vue').default);
        Vue.component('dokman-pruefung-component-aktualisieren', require('./components/dokman/pruefung/DokmanComponentPruefungAktualisieren.vue').default);
        Vue.component('dokman-durchdringung-component-durchdringung', require('./components/dokman/durchdringung/DokmanComponentDurchdringungDurchdringung.vue').default);
        Vue.component('dokman-meinbereich-component-search', require('./components/dokman/mein_bereich/DokmanComponentMeinBereichSearch.vue').default);
        Vue.component('dokman-meinbereich-component-maintable', require('./components/dokman/mein_bereich/DokmanComponentMeinBereichMainTable.vue').default);
        Vue.component('dokman-component-treeview-org-elemente', require('./components/dokman/DokmanComponentTreeviewOrgElemente.vue').default);

        Vue.component('emelder-melden-bs1-anzeige-text', require('./components/emelder/melden/components/EMelderComponentMeldenBs1AnzeigeText.vue').default);
        Vue.component('emelder-melden-bs2-eingabe-text-kurz', require('./components/emelder/melden/components/EMelderComponentMeldenBs2EingabeTextKurz.vue').default);
        Vue.component('emelder-melden-bs3-eingabe-text-lang', require('./components/emelder/melden/components/EMelderComponentMeldenBs3EingabeTextLang.vue').default);
        Vue.component('emelder-melden-bs4-eingabe-text-nummerisch', require('./components/emelder/melden/components/EMelderComponentMeldenBs4EingabeTextNummerisch.vue').default);
        Vue.component('emelder-melden-bs5-eingabe-datum', require('./components/emelder/melden/components/EMelderComponentMeldenBs5EingabeDatum.vue').default);
        Vue.component('emelder-melden-bs6-eingabe-zeit', require('./components/emelder/melden/components/EMelderComponentMeldenBs6EingabeZeit.vue').default);
        Vue.component('emelder-melden-bs7-org-elemente', require('./components/emelder/melden/components/EMelderComponentMeldenBs7OrgElemente.vue').default);
        Vue.component('emelder-melden-bs8-datei-upload', require('./components/emelder/melden/components/EMelderComponentMeldenBs8DateiUpload.vue').default);
        Vue.component('emelder-melden-bs9-globale-auswahlliste', require('./components/emelder/melden/components/EMelderComponentMeldenBs9GlobaleAuswahlListe.vue').default);
        Vue.component('emelder-melden-bs10-globale-auswahlskala', require('./components/emelder/melden/components/EMelderComponentMeldenBs10GlobaleAuswahlSkala.vue').default);
        Vue.component('emelder-melden-bs11-kontroll-kaestchen', require('./components/emelder/melden/components/EMelderComponentMeldenBs11Kontrollkästchen.vue').default);
        Vue.component('emelder-melden-bs12-optische-trennlinie', require('./components/emelder/melden/components/EMelderComponentMeldenBs12OptischeTrennlinie.vue').default);
        Vue.component('emelder-melden-bs13-betreff', require('./components/emelder/melden/components/EMelderComponentMeldenBs13Betreff.vue').default);

        Vue.component('emelder-meldungen-component-search', require('./components/emelder/meldungen/EMelderComponentMeldungenSearch.vue').default);
        Vue.component('emelder-meldungen-component-maintable', require('./components/emelder/meldungen/EMelderComponentMeldungenMainTable.vue').default);
        Vue.component('emelder-meldungen-component-meldung-delegieren', require('./components/emelder/meldungen/components/EMelderComponentMeldungenDelegieren.vue').default);

        Vue.component('emelder-risiko-component-search', require('./components/emelder/risiko/EMelderComponentRisikoSearch.vue').default);
        Vue.component('emelder-risiko-component-maintable', require('./components/emelder/risiko/EMelderComponentRisikoMainTable.vue').default);

        Vue.component('emelder-risiko-component-dashboard-risk-matrix', require('./components/emelder/risiko/dashboard/EMelderComponentRisikoDashboardRiskMatrix.vue').default);

        Vue.component('emelder-meldungen-component-chat', require('./components/emelder/chat/EMelderComponentMeldungenChat.vue').default);
        Vue.component('emelder-meldungen-component-communication', require('./components/emelder/communication/EMelderComponentMeldungenCommunication.vue').default);

        Vue.component('emelder-einstellungen-component-meldungsvorlagen', require('./components/emelder/einstellungen/meldungsvorlagen/EMelderComponentEinstellungenMeldungsvorlagen.vue').default);
        Vue.component('emelder-einstellungen-component_auswahllisten', require('./components/emelder/einstellungen/auswahllisten/EMelderComponentEinstellungenAuswahllisten.vue').default);

        Vue.component('emelder-statistik-component-show', require('./components/emelder/statistik/EMelderComponentStatistikShow.vue').default);

        var app = new Vue({
            router,
            el: '#vue-app',
        });

        if (document.getElementById("vue-app2")) {
            var app2 = new Vue({
                router,
                el: '#vue-app2',
            });
        }

        if (document.getElementById("vue-app3")) {
            var app3 = new Vue({
                router,
                el: '#vue-app3',
            });
        }
    }
};
