<template>
  <div>
      <b-form-group
          :id="grid_col.baustein_key + '-group'"
          :description="baustein.description"
          :label="baustein.label"
          :label-for="grid_col.baustein_key"
          :invalid-feedback="invalidFeedback"
          :valid-feedback="validFeedback"
          :state="state"
      >
          <b-form-radio v-for="(option,index) in kontrollkaestchen.optionen" :key="option.id" :name="grid_col.baustein_key" :value="option.bezeichnung" v-model="input" :disabled="disabled">
              {{option.bezeichnung}}</b-form-radio>
      </b-form-group>
  </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['grid_col', 'baustein', 'input_in', 'options_in', 'disabled_in', 'api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in'],
        name: 'EMelderComponentMeldenBs11Kontrollkästchen',
        components: {},
        data() {
            return {
                data: [],
                lang: null,
                input: null,
                initial: null,
                disabled: null,
                kontrollkaestchen: {
                    text: null,
                    value: null,
                    typ: false,
                    optionen: {},
                },
                options: this.options_in
            }
        },
        computed: {
            state() {
                if(this.baustein.required === 0)
                    return null;

                if(this.initial === null)
                    return null;

                if(this.initial === 1 && this.input.length >= 1 && (this.initial === 1 && this.input.length < 3))
                    return false;

                return (this.initial === 1 && this.input.length >= 3) ? true : false
            },
            invalidFeedback() {
                if (this.initial && this.input.length > 3) {
                    return ''
                } else if (this.initial && this.input.length > 0) {
                    return 'Bitte mindestens 3 Zeichen eingeben'
                } else {
                    return 'Eingabe benötigt'
                }
            },
            validFeedback() {
                return this.state === true ? '' : ''
            }
        },
        created() {
            var lang = new Lang();
            lang.setLocale(this.locale_in);
            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }

            lang.setMessages(translations);
            lang.setMessages(translations);
            this.lang = lang;

            this.input = this.input_in;

            this.disabled = JSON.parse(this.disabled_in);

            this.getKontrollkaestchen(this.baustein.option2);
        },
        mounted() {
        },
        methods: {
            init() {
                this.initial = 1;
            },
            blur(){
                this.initial = null;
            },
            getKontrollkaestchen(idKontrollkaestchen) {
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in, {
                    id_kontrollkaestchen: idKontrollkaestchen,
                })
                    .then(function (res) {
                        self.kontrollkaestchen = res.data.kontrollkaestchen;
                        self.options = res.data.kontrollkaestchen.optionen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
    }
</script>

<style lang="scss"></style>
