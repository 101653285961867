<template>
    <div>
        <b-alert variant="info" show>
            <span v-if="enable_raw_html == 'true'" v-html="baustein.label"></span>
            <span v-else>
                {{ baustein.label }}
                </span>
        </b-alert>
    </div>
</template>
<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

export default {
    props:
        {
            grid_col: Object,
            baustein: Object,
            enable_raw_html: {
                type: String,
                required: false
            },
        },
    name: 'EMelderComponentMeldenBs1AnzeigeText',
    components: {},
    data() {
        return {
            data: [],
            lang: null,
        }
    },
    computed: {},
    created() {
        var lang = new Lang();
        lang.setLocale(this.locale_in);
        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }

        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
    },
    methods: {
        methode1() {

        },
    },
}
</script>

<style lang="scss"></style>
