<template lang="pug">
  .row
    .col-6
      div(v-if="org_treedata.length || new_treedata.length")
        Tree(:data="org_treedata" draggable cross-tree)
          div(slot-scope="{data, store}")
            template(v-if="!data.isDragPlaceHolder")
              div(@click="editElement(data)")
                b(v-if="data.children && data.children.length" @click="store.toggleOpen(data)") {{data.open ? '-' : '+'}}&nbsp;
                span {{data.bezeichnung ? data.bezeichnung : data.name }}
                span.badge.badge-secondary.ml-10(v-if="data.users.length > 0")
                  | {{data.users.length}} Mitarbeiter
        .mt-4
          button.btn.btn-primary(@click="collapseAll") {{ lang.get('default.btn_alle_einklappen') }}
          button.btn.btn-primary.ml-1(@click="expandAll") {{ lang.get('default.btn_alle_ausklappen') }}
      div(v-else)
        | {{ lang.get('admin.organisationselement_keine_eintraege') }}
    .col-6
      div.he-tree.sticky.max-height.ml-5(v-if="edit_node")
        div.h5.mt-10.mb-25
          | {{lang.get('admin.nav_tab_personal')}} {{ lang.get('admin.card_fuer') }} {{edit_node.bezeichnung ? edit_node.bezeichnung : edit_node.name}}
        div.mb-25
          treeselect(
            name="gruppe"
            :multiple="multiple"
            :clearable="clearable"
            :searchable="searchable"
            :searchNested="searchNested"
            :async="async"
            :disabled="disabled"
            :open-on-click="openOnClick"
            :open-on-focus="openOnFocus"
            :clear-on-select="clearOnSelect"
            :close-on-select="closeOnSelect"
            :always-open="false"
            :append-to-body="appendToBody"
            :options="groupsOptions"
            :value-consists-of="valueConsistsOf"
            :loading="loading"
            :auto-load-root-options="auto_load_root_options"
            :load-options="loadOptions"
            :show-count="showCount"
            :showCountOf="showCountOf"
            :loadingText="loadingText"
            :noChildrenText="noChildrenText"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            :openDirection="openDirection"
            :placeholder="groupsPlaceholder"
            :required="required"
            :limit="limit"
            :max-height="maxHeight"
            :disableFuzzyMatching="disableFuzzyMatching"
            v-model="groupsValue"
            :normalizer="normalizer"
            @select="onSelectGruppe"
            )
          treeselect.pt-25(
            name="mitglieder"
            :multiple="multiple"
            :clearable="clearable"
            :searchable="searchable"
            :searchNested="searchNested"
            :async="async"
            :disabled="disabled"
            :open-on-click="openOnClick"
            :open-on-focus="openOnFocus"
            :clear-on-select="clearOnSelect"
            :close-on-select="closeOnSelect"
            :always-open="alwaysOpen"
            :append-to-body="appendToBody"
            :options="options"
            :value-consists-of="valueConsistsOf"
            :loading="loading"
            :auto-load-root-options="auto_load_root_options"
            :load-options="loadOptions"
            :show-count="showCount"
            :showCountOf="showCountOf"
            :loadingText="loadingText"
            :noChildrenText="noChildrenText"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            :openDirection="openDirection"
            :placeholder="placeholder"
            :required="required"
            :limit="limit"
            :max-height="maxHeight"
            :disableFuzzyMatching="disableFuzzyMatching"
            v-model="value"
            :normalizer="normalizer"
            @select="onSelect"
            @deselect="onDeselect"
            @input="onInput"
            )
      .row(v-if="!edit_node")
        .col-12
          .alert.alert-warning(role="alert")
            | {{lang.get('admin.alert_bitte_auswaehlen')}}

</template>
<script>
    //import Treeselect from '@riophae/vue-treeselect';

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
    import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    export default {
        props: ['org_gruppe_id_in', 'org_treedata_in','locale_in'],
        components: {Tree: vueDraggableNestedTree.DraggableTree, Treeselect},
        data() {
            return {
                org_treedata: this.org_treedata_in,
                new_input: '',
                edit_node: null,
                lang: null,

                // Switch Parameter
                multiple: true,
                disableFuzzyMatching: true,
                include_ids: null,
                exclude_ids: null,
                clearable: true,
                searchable: true,
                searchNested: false,
                async: false,
                disabled: false,
                openOnClick: true,
                openOnFocus: false,
                clearOnSelect: true,
                closeOnSelect: false,
                alwaysOpen: true,
                appendToBody: false,
                valueConsistsOf: 'LEAF_PRIORITY',
                loadingText: 'Lade...',
                noChildrenText: 'Keine Mitarbeiter zugeordnet',
                noOptionsText: 'Keine Daten',
                noResultsText: 'Keine Daten',
                openDirection: 'below',
                placeholder: 'Bitte wählen Sie Ihre Mitarbeiter aus',
                groupsPlaceholder: 'Bitte wählen Sie ein Mitarbeitergruppe aus',
                auto_load_root_options: false,
                loading: true,
                required: false,
                maxHeight: 440,
                limit: Infinity,
                showCount: false,
                showCountOf: 'ALL_CHILDREN',
                value: null,
                groupsValue: null,
                options: null,
                groupsOptions: null,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        //children: node.subOptions,
                    }
                },
            }
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);


            if (lang.getLocale() === 'undefined'){
                lang.setLocale('de');
            }

            lang.setMessages(translations);

            this.lang = lang;
        },
        mounted() {
            this.editElement(this.org_treedata[0]);
            this.org_treedata.draggable = false;
        },
        methods: {
            expandAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = true
                })
            },
            collapseAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = false
                })
            },
            editElement(data) {
                this.edit_node = data;
                this.count_idx = 0;
                // this.value = [0];
                this.groupsValue = null;
                this.getSelected();
            },
            getSelected(){
                axios.get('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_get_selected_mitglieder/' + this.edit_node.id)
                    .then(function (res) {
                        //console.log(res);
                        self.value = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            onSelect(node, id) {
                console.log(node);
                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_add_mitglieder/' + this.edit_node.id, {
                    id_mitarbeiter: node.id,
                }).then(function (res) {
                        //console.log(res);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            onDeselect(node, id) {
                console.log(node);
                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_remove_mitglieder/' + this.edit_node.id, {
                    id_mitarbeiter: node.id,
                }).then(function (res) {
                        //console.log(res);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            onSelectGruppe(node, id) {
                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_add_gruppe/' + this.edit_node.id, {
                    id_gruppe: node.id,
                }).then(async function (res) {
                    location.reload();
                  })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            onInput(value,id){
                this.refreshTreeData();
            },
            loadOptions({action, parentNode, callback}) {
            if (action === LOAD_ROOT_OPTIONS) {
                self = this;
                axios.get('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_get_mitglieder/' + this.edit_node.id)
                    .then(function (res) {
                        self.options = res.data;
                        console.log(res.data)
                        callback();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                axios.get('/admin/org_elemente/gruppen/show/api_get_gruppen/')
                    .then(function (res) {
                        //console.log(res);
                        self.groupsOptions = res.data;
                        callback();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            },
            refreshTreeData() {
                self = this;
                if(self.value.length < 1 ) {
                    axios.post('/admin/org_elemente/gruppen/' + this.org_gruppe_id_in + '/api_post_remove_all/' + this.edit_node.id, )
                        .then(function (res) {
                            //console.log(res);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
                axios.get('/admin/org_elemente/gruppen/' + this.org_gruppe_id_in + '/api_get_refresh_tree/')
                    .then(function (res) {
                        self.org_treedata = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
    }
</script>

<style lang="scss">


    .he-tree {
        //border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;

        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;

        border-color: #eaecec;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 14px;

    }

    .max-height{
        height:480px;
        max-height: 100%;
    }

    .tree-node {
    }

    .tree-node-inner {
        //padding: 5px;
        padding: .75rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: .25rem;
        cursor: pointer;
    }

    .draggable-placeholder {
    }

    .draggable-placeholder-inner {
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .edit_input_field {
        width: 80% !important;
        display: inline !important;
    }

    .list-group-item {
        border: 1px solid #ccc !important;
    }


    /*.tree3{

        .tree-node-inner{
            border: none;
            padding: 0px;
        }
        .tree-node-inner-back:hover{
            background: #ddd;
        }
    }*/

    /* .tree4{
         .tree-node-inner{
             border: none;
             border-bottom: 1px solid #ccc;
             padding: 5px 10px;
             backgroud: #ccc;
     }
         .draggable-placeholder-inner{
             background-color: orangered;
         }
     }*/

    .button {
        margin-top: 35px;
    }

    .handle {
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .close {
        float: right;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .text, .vue-treeselect__label {
        color: #5e7d8a;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
    }



</style>
