<template>
    <div class="d-inline ml-5">
        <a href="#" @click.prevent="copyToClipboard">
            <strong
                class="text-primary">{{copy_value}}</strong>
            <i class="zmdi zmdi-assignment font-30 mr-10 text-light-40"></i>
        </a>
        <input id="id_copy_value" type="hidden" :value="copy_value">
    </div>
</template>

<script>
    export default {
        props: ['copy_value_in'],
        name: 'CopyToClipboardComponent',
        components: {},
        data() {
            return {
                data: [],
                copy_value: this.copy_value_in
            }
        },
        computed: {},
        created() {
        },
        mounted() {

        },
        methods: {
            copyToClipboard() {

                var copyText = document.getElementById("id_copy_value");
                copyText.type = 'text';
                copyText.select();
                document.execCommand("copy");
                copyText.type = 'hidden';

                this.$swal({
                    title: 'Erfolg!',
                    text: "Ticket ID in Zwischenspeicher kopiert!",
                    type: 'success',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
        },
    }
</script>

<style lang="scss"></style>
