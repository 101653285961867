<template>
    <div>
        <router-link :to="link.toLowerCase()">
            <a href="javascript:void(0);" class="media" :class="{ 'bg-active' : active }">
                <div class="media-img-wrap">
                    <i class="font-30 mr-10 text-light-40 zmdi" :class="icon"></i>
                </div>
                <div class="media-body">
                    <div>
                        <div class="user-name mt-5">
                            <h6 :class="{'text-primary' : highlight}">{{bezeichnung}}</h6>
                        </div>
                    </div>
                </div>
            </a>
        </router-link>
        <div class="chat-hr-wrap">
            <hr>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['id', 'bezeichnung','link', 'icon', 'highlight','active'],
        components: {},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>
    .bg-active {
        background-color: #edf7fe !important;
    }
</style>
