<template>
    <div>
        <a id="close_fmapp_sidebar" href="javascript:void(0)" class="close-fmapp-sidebar">
            <span class="feather-icon"><i data-feather="chevron-left"></i></span>
        </a>
        <input type="text" class="form-control form-control-sm" name="volltextsuche"
               placeholder="Ticketnummer, Volltext ..." @keyup="InputVolltextsuche()" v-model="volltextsuche">
        <hr>
        <select v-if="org_gruppe_data.length" name="org_gruppe" id="org_gruppe"
                class="form-control form-control-sm mb-10" @change="getOrgElemente()" v-model="org_gruppe">
            <option value="" selected hidden> - Organisationsgruppe -</option>
            <option v-for="single_org_gruppe in org_gruppe_data" :value="single_org_gruppe.id">
                {{single_org_gruppe.bezeichnung}}
            </option>
        </select>
        <select name="org_element" id="org_element"
                class="form-control form-control-sm mb-10" v-model="org_element" @change="triggerSearchEvent()" size="12">
            <option value="" selected hidden> - Geltung -</option>
            <option v-for="org_element in org_element_data_tree" :value="org_element.id"
                    @click="select_deselect(org_element.id)">{{org_element.bezeichnung}}
            </option>
        </select>
        <select v-if="meldungskategorien_data.length" name="meldungskategorie" id="meldungskategorie"
                class="form-control form-control-sm mb-10 mt-25" v-model="meldungskategorie" @change="triggerSearchEvent()">
            <option value="" selected> - Meldungskategorie -</option>
            <option v-for="meldungskategorie in meldungskategorien_data" :value="meldungskategorie.id">
                {{meldungskategorie.bezeichnung}}
            </option>
        </select>
        <select v-if="meldungsvorlagen_data.length" name="meldungsvorlage" id="meldungsvorlage"
                class="form-control form-control-sm mb-10" v-model="meldungsvorlage" @change="triggerSearchEvent()">
            <option value="" selected> - Meldungsvorlage -</option>
            <option v-for="meldungsvorlage in meldungsvorlagen_data" :value="meldungsvorlage.id">
                {{meldungsvorlage.bezeichnung}}
            </option>
        </select>
        <select name="status" id="status" class="form-control form-control-sm mb-10" v-model="status"
                @change="triggerSearchEvent()">
            <option value="" selected> - Status -</option>
            <option value="1">Neu</option>
            <option value="2">Anonymisieren</option>
            <option value="3">Hauptverantwortung</option>
            <option value="4">In Bearbeitung</option>
            <option value="10">Wirksamkeitsüberprüfung</option>
            <option value="11">Erfolgreich</option>
        </select>
        <select v-if="verantwortlicher_data.length" name="verantwortlicher" id="verantwortlicher"
                class="form-control form-control-sm mb-10 mt-25" @change="triggerSearchEvent()" v-model="verantwortlicher">
            <option value="" selected> - Verantwortliche -</option>
            <option v-for="single_verantwortlicher in verantwortlicher_data" :value="single_verantwortlicher.id">
                {{single_verantwortlicher.name}}
            </option>
        </select>
        <div class="row mt-25">
            <div class="col-3">
                <toggle-button @change="toggleSearch('switch_eigene')" color="#00acf0" :value="false"/>
            </div>
            <div class="col-9">
                Nur eigene Meldungen
            </div>
        </div>
        <hr>
        <div class="row mt-10">
            <button class="btn btn-sm btn-primary ml-auto mr-auto" @click="resetStoreData()">
                Reset Filter
            </button>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['org_gruppen_in', 'meldungskategorien_in','meldungsvorlagen_in', 'page_in','org_gruppen_selected_in',],
        components: {},
        data() {
            return {
                volltextsuche: null,
                org_gruppe_data: this.org_gruppen_in,
                org_gruppe: "",
                org_element_data: "",
                org_element_data_tree: [],
                org_element: "",
                last_select: "",
                meldungskategorien_data: this.meldungskategorien_in,
                meldungskategorie: "",
                meldungsvorlagen_data: this.meldungsvorlagen_in,
                meldungsvorlage: "",
                status: "",
                verantwortlicher: "",
                verantwortlicher_data: "",
                switch_eigene: false,
                page: this.page_in,
                lang: null,
                debug:false,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
            this.getStoreData();

            this.org_gruppe = this.org_gruppen_selected_in;
            this.getOrgElemente();
        },
        beforeCreate() {
            this.$store.$store.commit('restoreQueryDataEMelder');
        },
        watch: {},
        computed: {},
        methods: {
            pushOrgElement(orgElemente, prefix = '-') {
                for (let singleOrgElement in orgElemente) {
                    this.org_element_data_tree.push(
                        {   id: orgElemente[singleOrgElement].id,
                            bezeichnung: prefix + ' ' + orgElemente[singleOrgElement].bezeichnung,
                        });
                    this.pushOrgElement(orgElemente[singleOrgElement].children, prefix + '-');
                }
            },
            InputVolltextsuche() {
                if (this.volltextsuche.length > 1 || this.volltextsuche == '') {
                    this.triggerSearchEvent();
                }
            },
            getOrgElemente() {
                let self = this;
                axios.get('/dokman/mein_bereich/api/' + self.org_gruppe + '/get_org_elemente')
                    .then(function (res) {
                        self.org_element_data = res.data;
                        self.getVerantwortlicher();
                        self.org_element_data_tree = [];
                        self.pushOrgElement(self.org_element_data, '');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getVerantwortlicher() {
                let self = this;
                axios.get('/emelder/meldungen/api/get_verantwortlicher/')
                    .then(function (res) {
                        self.verantwortlicher_data = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            triggerSearchEvent() {
                this.$eventBus.$emit('EMeldersearchQuery', {
                    volltextsuche: this.volltextsuche,
                    org_gruppe: this.org_gruppe,
                    org_element: this.org_element,
                    meldungskategorie: this.meldungskategorie,
                    meldungsvorlage: this.meldungsvorlage,
                    status: this.status,
                    verantwortlicher: this.verantwortlicher,
                    switch_eigene: this.switch_eigene,
                    page: this.page,
                });
            },
            toggleSearch(value) {
                switch (value) {
                    case 'switch_eigene':
                        this.switch_eigene = !this.switch_eigene;
                        break;
                }
                this.triggerSearchEvent();
            },
            select_deselect(id) {
                if (this.last_select === id) {
                    this.org_element = "";
                    this.triggerSearchEvent()
                } else {
                    this.last_select = id;
                }
            },
            getStoreData(){
                this.meldungskategorie = this.$store.$store.state.search_emelder.meldungskategorie;
                this.meldungsvorlage = this.$store.$store.state.search_emelder.meldungsvorlage;
                this.org_element = this.$store.$store.state.search_emelder.org_element;
                this.org_element_objekt = this.$store.$store.state.search_emelder.org_element_objekt;

                if(this.$store.$store.state.search_emelder.org_gruppe) {
                    this.org_gruppe = this.$store.$store.state.search_emelder.org_gruppe;
                }

                this.page = this.$store.$store.state.search_emelder.page;
                this.verantwortlicher = this.$store.$store.state.search_emelder.verantwortlicher;
                this.status = this.$store.$store.state.search_emelder.status;
                this.switch_eigene = this.$store.$store.state.search_emelder.switch_eigene;
                this.volltextsuche = this.$store.$store.state.search_emelder.volltextsuche;

                if(this.debug) {
                    console.log('getStoreData - Start');
                    console.log(this.$store.$store.state);
                    console.log('getStoreData - End');
                }

            },
            resetStoreData(){
                this.org_element = "";
                if(this.$store.$store.state.search_emelder.org_gruppe) {
                    this.org_gruppe = this.$store.$store.state.search_emelder.org_gruppe;
                }

                this.meldungskategorie = "",
                this.meldungsvorlage = "",
                this.page = null;
                this.verantwortlicher = "";
                this.status = "";
                this.switch_eigene = false;
                this.volltextsuche = null;

                this.$store.$store.commit('resetQueryDataEMelder');

                this.$swal({
                    title: 'Erfolg',
                    text: "Ihre Filterung wurden zurückgesetzt",
                    type: 'success',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.triggerSearchEvent();
            },
        }
    };
</script>
<style scoped>
</style>
