<template>
    <div class="row">
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Kopfzeile bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_1" class="collapse show">
                                        <div class="card-body">
                                            <b-form-group
                                                id="text_input_group"
                                                description=""
                                                :invalid-feedback="invalidFeedback"
                                                :valid-feedback="validFeedback"
                                                :state="state"
                                            >
                                                <b-form-textarea
                                                    id="text_input"
                                                    v-model="input"
                                                    :state="state"
                                                    placeholder="Bitte eingeben"
                                                    rows="10"
                                                    @input="init"
                                                    @blur="blur"
                                                    autocomplete="off"
                                                    :disabled="disabled"
                                                ></b-form-textarea>
                                                <input type="hidden" name="text_input" v-model="input">
                                            </b-form-group>
                                            <div class="text-right">
                                                <b-button variant="primary" @click="saveInput()">Speichern</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['api_einstellungen_meldungsvorlagen_kopfzeile_store_in', 'api_einstellungen_meldungsvorlagen_kopfzeile_show_in', 'input_in', 'disabled_in'],
        components: {},
        data() {
            return {
                data: [],
                lang: null,
                input: null,
                initial: null,
                disabled: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.input = this.getInput();

            this.disabled = JSON.parse(this.disabled_in);
        },
        mounted() {
        },
        watch: {},
        computed: {
            state() {
                if (this.initial === null)
                    return null;

                if (this.initial === 1 && this.input.length >= 1 && (this.initial === 1 && this.input.length < 3))
                    return false;

                return (this.initial === 1 && this.input.length >= 3) ? true : false
            },
            invalidFeedback() {
                if (this.initial && this.input.length > 3) {
                    return ''
                } else if (this.initial && this.input.length > 0) {
                    return 'Bitte mindestens 3 Zeichen eingeben'
                } else {
                    return 'Eingabe benötigt'
                }
            },
            validFeedback() {
                return this.state === true ? '' : ''
            }
        },
        methods: {
            init() {
                this.initial = 1;
            },
            blur() {
                this.initial = null;
            },
            getInput() {
                let self = this;
                axios.get(this.api_einstellungen_meldungsvorlagen_kopfzeile_show_in, {})
                    .then(function (res) {
                        self.input = res.data.kopfzeile;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveInput() {
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_kopfzeile_store_in, {
                    text_input: this.input
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Kopfzeile wurde erfolgreich gespeichert",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        self.$swal({
                            title: 'Fehler',
                            text: "Kopfzeile wurde nicht gespeichert. Maximal 1000 Zeichen erlaubt",
                            type: 'error',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    });
            },
        }
    };
</script>
<style scoped>

    .emelder_einstellungen_card_zeile {
        width: 100% !important;
        border-left-color: #00acf0 !important;
        border-left-width: 15px !important;
    }

</style>
