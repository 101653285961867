<template>
    <div>
        <h5>Durchdringungsassistent</h5><br>
        <div v-for="durchdringung,key_durchdringung in durchdringungen">
            <div v-if="objectCount(durchdringung.multiplikatoren) > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header card-header-action">
                                <h6>{{durchdringung.aufgabe_typ.titel}}</h6>
                                <div class="d-flex align-items-center card-action-wrap">
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!--<span v-if="get_freigabeschritt(key_freigabeschritt).status == 1"
                                                      class="text-danger">Warte auf Auslösung</span>
                                            <span v-else-if="!get_freigabeschritt(key_freigabeschritt).erledigt_am">Frist bis: {{get_freigabeschritt(key_freigabeschritt).frist}}</span>
                                            <span v-else>Erledigt am: {{get_freigabeschritt(key_freigabeschritt).erledigt_am}}</span>
                                            <br>
                                            Min. positive Freigaben:
                                            {{get_freigabeschritt(key_freigabeschritt).min_anzahl_freigaben}}-->
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Geltung</span>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Status</span>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Frist / Erledigung</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <div class="col-md-12">
                                                        <div v-for="multiplikator,key_multiplikator in durchdringung.multiplikatoren">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <span class="text-info">{{multiplikator.user_limited.user_data_limited.anrede?.bezeichnung}} {{multiplikator.user_limited.user_data_limited.titel?.bezeichnung}} {{multiplikator.user_limited.user_data_limited.nachname}},  {{multiplikator.user_limited.user_data_limited.vorname}}</span>
                                                                </div>
                                                                <div class="col-md-4">{{multiplikator.geltung.bezeichnung}}</div>
                                                                <div class="col-md-2"><span v-html="get_multiplikator_status(multiplikator).badge"></span></div>
                                                                <div class="col-md-2">
                                                                     <span v-if="multiplikator.status == 10">
                                                                         {{formatDate(multiplikator.updated_at)}}
                                                                     </span>
                                                                    <span v-else> {{ parseDate(multiplikator.aufgabe) }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from "moment";
    import {formatDate} from "../../../../../public/vendors/fullcalendar/dist/fullcalendar";

    export default {
        props: ['dokument_in', 'durchdringungen_in'],
        name: "DurchdringungDurchdringung",
        display: "DurchdringungDurchdringung",
        order: 0,
        components: {},
        data() {
            return {
                collapsed: true,
                dokument: this.dokument_in,
                durchdringungen: this.durchdringungen_in,
            };
        },
        mounted() {
        },
        computed: {},
        methods: {
            objectCount: function (obj) {
                return Object.keys(obj).length;
            },
            objectFirstElement: function (obj) {
                return Object.keys(obj)[0];
            },
             get_multiplikator_status: function (multiplikator) {

                 // Status 0  -> Aufgabe gelöscht / storniert
                 // Status 1  -> Durchdringung gestartet - Noch keine Aufgabe erstellt
                 // Status 2  -> Durchdringung gestartet - Aufgabe erstellt
                 // Status 3  -> Durchdringung gestartet - Aufgabe erstellt - Alarmierung vor Fristende
                 // Status 4  -> Durchdringung gestartet - Aufgabe erstellt - Frist abgelaufen
                 // Status 10  -> Durchdringung gestartet - Aufgabe erstellt - Bekanntmachung erfolgt

                 let status;

                 switch (multiplikator.status) {

                     case 0:
                         status = {
                             'badge': '<span class="badge badge-danger">gelöscht</span>',
                             'text': 'gelöscht',
                             'css': 'secondary',
                             'id': 0
                         };
                         break;

                     case 1:
                         status = {
                             'badge': '<span class="badge badge-light text-dark">Warte auf Auslösung</span>',
                             'text': 'Warte auf Auslösung',
                             'css': 'light text-dark',
                             'id': 1
                         };
                         break;

                     case 2:
                         status = {
                             'badge': '<span class="badge badge-info">erstellt</span>',
                             'text': 'Aufgabe erstellt',
                             'css': 'info',
                             'id': 2
                         };
                         break;

                     case 3:

                         status = {
                             'badge': '<span class="badge badge-text-light">läuft bald ab</span>',
                             'text': 'Frist läuft bald ab',
                             'css': 'warning text-light',
                             'id': 3
                         };
                         break;

                     case 4:

                         status = {
                             'badge': '<span class="badge badge-danger">abgelaufen</span>',
                             'text': 'Frist abgelaufen',
                             'css': 'danger',
                             'id': 4
                         };
                         break;

                     case 10:

                         status = {
                             'badge': '<span class="badge badge-success">bekannt gemacht</span>',
                             'text': 'Bekanntmachung erfolgt',
                             'css': 'success',
                             'id': 10
                         };
                         break;
                 }

                 return status
             },
            formatDate: function (dateString) {
                const date = moment(dateString);
                return date.format('DD.MM.YYYY HH:mm');
            },
            parseDate: function (date) {
                //let parsed_date = Date.parse(date);
                if (date == null || typeof (date) === 'undefined') {
                    date = "-";
                } else {
                    const dateString = date.frist;
                    date = this.formatDate(dateString);
                }
                return date;
            },
            /*  post_sende_erinnerung: function (id_freigabeschritt_freigeber) {

                  let self = this;
                  axios.post(this.signed_api_post_sende_erinnerung_in, {
                      id_freigabeschritt_freigeber: id_freigabeschritt_freigeber
                  })
                      .then(function (res) {
                          self.$swal({
                              title: 'Erfolg',
                              text: "Ihre Erinnerung wurde erfolgreich versandt",
                              type: 'success',
                              toast: true,
                              position: 'top-right',
                              showConfirmButton: false,
                              timer: 3000
                          });
                      })
                      .catch(function (error) {
                          console.log(error);
                      });

              },*/
            onDeselect: function () {
            },
            onInput: function () {
            },
        }
    };
</script>
<style scoped>

    .cursor-pointer {
        cursor: pointer;
    }

    .buttons {
        margin-top: 35px;
    }

    .list-group {
        border: unset !important;
        border-color: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .list-group-item {
        border: unset !important;
        border-color: unset !important;
        border: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }
</style>
