<template>
    <div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" name="count" :value="objectCount(pruefer)">
                <div v-if="objectCount(pruefer) > 0">
                    <div class="card">
                        <div class="card-header card-header-action">
                            <h6>Prüfungsprozess</h6>
                            <div class="d-flex align-items-center card-action-wrap">
                            </div>
                        </div>
                        <div class="list-group-item" v-for="pruefungsschritt,key_pruefungsschritt in pruefer">
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-inline-block float-left">
                                        {{get_pruefungsschritt(key_pruefungsschritt).rangfolge + 1}}. Schritt:
                                        {{get_pruefungsschritt(key_pruefungsschritt).name}}
                                    </div>
                                    <div class="d-inline-block float-right">
                                        <span v-html="get_pruefungsschritt_status(get_pruefungsschritt(key_pruefungsschritt)).badge"></span>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">

                                            <span v-if="get_pruefungsschritt(key_pruefungsschritt).status == 1"
                                                  class="text-danger">Warte auf Auslösung</span>
                                            <span v-else-if="!get_pruefungsschritt(key_pruefungsschritt).erledigt_am">Frist bis: {{get_pruefungsschritt(key_pruefungsschritt).frist}}</span>
                                            <span v-else>Erledigt am: {{get_pruefungsschritt(key_pruefungsschritt).erledigt_am}}</span>
                                            <br>
                                            Min. positive Prüfungen:
                                            {{get_pruefungsschritt(key_pruefungsschritt).min_anzahl_pruefung}}
                                        </div>
                                    </div>
                                    <div class="row" v-for="geltung,key_geltung in pruefungsschritt">
                                        <div class="col-md-12">
                                            <div v-if="get_geltung( key_pruefungsschritt, key_geltung).bezeichnung"
                                                 class="mt-15 mb-10 pt-5 pl-5 pr-5 pb-5 bg-secondary text-white">
                                                <strong>Geltungsbereich : {{get_geltung( key_pruefungsschritt,
                                                    key_geltung).bezeichnung != null? get_geltung( key_pruefungsschritt,
                                                    key_geltung).bezeichnung : 'Verantwortliche Personen'}}</strong>
                                            </div>
                                            <div class="row"
                                                 v-for="pruefer_beziehung,key_pruefer_beziehung in geltung">
                                                <div class="col-md-12">
                                                    <div>
                                                        <span class="d-block font-14 font-weight-500 text-dark text-uppercase">{{get_pruefer_beziehung( key_pruefungsschritt, key_geltung, key_pruefer_beziehung).bezeichnung}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Pflicht</span>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Status</span>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Frist / Erledigung</span>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Optionen</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div v-for="single_pruefer,key_pruefer in pruefer_beziehung">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <span class="text-info">
                                                                     <span v-if="single_pruefer.user.user_data_limited.anrede">{{single_pruefer.user.user_data_limited.anrede.bezeichnung}}</span>
                                                                    <span v-if="single_pruefer.user.user_data_limited.titel">{{single_pruefer.user.user_data_limited.titel.bezeichnung}}</span>
                                                                     {{single_pruefer.user.user_data_limited.nachname}},  {{single_pruefer.user.user_data_limited.vorname}}</span>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <span class=""><input type="checkbox"
                                                                                      name="pflichtteilnahme"
                                                                                      :checked="single_pruefer.pflichtteilnahme ? true : false"
                                                                                      disabled="disabled"></span>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <span v-html="get_pruefer_status(single_pruefer).badge"></span>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <span v-if="single_pruefer.status == 10">
                                                                    {{single_pruefer.updated_at}}
                                                                </span>
                                                                <span v-else> {{parseDate(single_pruefer.aufgabe)}}</span>

                                                            </div>
                                                            <div class="col-md-2">
                                                                <span v-if="single_pruefer.aufgabe && ( get_pruefer_status(single_pruefer).id == 2 || get_pruefer_status(single_pruefer).id == 3 || get_pruefer_status(single_pruefer).id == 4)">
                                                                    <a href="javascript:void(0);" v-b-tooltip.hover
                                                                       title="Erinnerung senden"
                                                                       @click="post_sende_erinnerung(single_pruefer.id)">
                                                                    <strong><i
                                                                            class="zmdi zmdi-alarm font-20 mr-10 text-light-40"></i></strong></a></span>
                                                                <span v-else-if="single_pruefer.aufgabe && get_pruefer_status(single_pruefer).id == 9">
                                                                    <a href="javascript:void(0);" v-b-tooltip.hover
                                                                       title="Trotzdem für aktuell befinden"
                                                                       @click="post_ueberschreibe_pruefung(single_pruefer)">
                                                                    <strong><i
                                                                            class="zmdi zmdi-replay font-20 mr-10 text-light-40"></i></strong></a></span>
                                                                <span v-else>-</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mt-10 mb-10">
                                                <div class="mt-10 mb-10">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else="objectCount(pruefer) == 0">
                    <div class="alert alert-info mb-15 mb-15 w-100">Keine Schritte vorhanden</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['dokument_in', 'pruefer_in', 'signed_api_post_sende_erinnerung_in','signed_api_post_ueberschreibe_pruefung_in'],
        name: "PruefungPruefung",
        display: "PruefungPruefung",
        order: 0,
        components: {},
        data() {
            return {
                collapsed: true,

                dokument: this.dokument_in,
                pruefer: this.pruefer_in,
            };
        },
        mounted() {
        },
        computed: {},
        methods: {
            objectCount: function (obj) {
                return Object.keys(obj).length;
            },
            objectFirstElement: function (obj) {
                return Object.keys(obj)[0];
            },
            get_pruefungsschritt: function (key_pruefung_schritt) {
                let id_pruefungsschritt = key_pruefung_schritt;
                let id_geltung = this.objectFirstElement(this.pruefer[id_pruefungsschritt]);
                let id_pruefer_beziehung = this.objectFirstElement(this.pruefer[id_pruefungsschritt][id_geltung]);
                let pruefungsschritt = this.pruefer[id_pruefungsschritt][id_geltung][id_pruefer_beziehung][0].pruefungsschritt;
                return pruefungsschritt;
            },
            get_geltung: function (key_pruefung_schritt, key_geltung) {
                let id_pruefungsschritt = key_pruefung_schritt;
                let id_geltung = key_geltung;
                let id_pruefer_beziehung = this.objectFirstElement(this.pruefer[id_pruefungsschritt][id_geltung]);
                let geltung = this.pruefer[id_pruefungsschritt][id_geltung][id_pruefer_beziehung][0].geltung;
                return geltung;
            },
            get_pruefer_beziehung: function (key_pruefung_schritt, key_geltung, key_pruefer_beziehung) {
                let id_pruefungsschritt = key_pruefung_schritt;
                let id_geltung = key_geltung;
                let id_pruefer_beziehung = key_pruefer_beziehung;
                let pruefer_beziehung = this.pruefer[id_pruefungsschritt][id_geltung][id_pruefer_beziehung][0].pruefungsschritt_pruefer_beziehungstyp;
                return pruefer_beziehung;
            },
            get_pruefer_aufgabe: function (key_pruefung_schritt, key_geltung, key_pruefer_beziehung) {
                let id_pruefungsschritt = key_pruefung_schritt;
                let id_geltung = key_geltung;
                let id_pruefer_beziehung = key_pruefer_beziehung;
                let pruefer_aufgabe = this.pruefer[id_pruefungsschritt][id_geltung][id_pruefer_beziehung][0].aufgabe;
                return pruefer_aufgabe;
            },
            get_pruefungsschritt_status: function (pruefungsschritt) {

                //  0 : Gelöscht / Archiviert
                //  1 : Erstellt
                //  2 : Ausgelöst
                // 10 : Erledigt

                let status;

                switch (pruefungsschritt.status) {
                   case 0:
                     status = {
                                'badge' : '<span class="badge badge-danger">gelöscht</span>',
                                'text':'gelöscht',
                                'css' : 'danger'
                            };
                        break;

                    case 1:
                        status = {
                            'badge' : '<span class="badge badge-info">wartet auf Auslösung</span>',
                            'text':'wartet auf Auslösung',
                            'css' : 'info'
                        };
                        break;

                    case 2:
                        status = {
                            'badge' : '<span class="badge badge-warning">ausgelöst</span>',
                            'text':'ausgelöst',
                            'css' : 'warning'
                        };

                        break;


                    case 10:
                        status = {
                                'badge' : '<span class="badge badge-success">erledigt</span>',
                                'text':'erledigt',
                                'css' : 'success'
                            };
                        break;
                }

                return status
            },
            get_pruefer_status: function (pruefer) {

                // Status 0  -> Aufgabe gelöscht / storniert
                // Status 1  -> Pruefung gestartet - Noch keine Aufgabe erstellt
                // Status 2  -> Pruefung gestartet - Aufgabe erstellt
                // Status 3  -> Pruefung gestartet - Aufgabe erstellt - Alarmierung vor Fristende
                // Status 4  -> Pruefung gestartet - Aufgabe erstellt - Frist abgelaufen
                // Status 9  -> Pruefung gestartet - Aufgabe erstellt - Dokument negativ geprüft
                // Status 10  -> Pruefung gestartet - Aufgabe erstellt - Dokument positiv geprüft

                let status;

                switch (pruefer.status) {

                    case 0:
                        status = {
                            'badge' : '<span class="badge badge-danger">gelöscht</span>',
                            'text':'gelöscht',
                            'css' : 'secondary',
                            'id' : 0
                        };
                        break;

                    case 1:
                        status = {
                            'badge' : '<span class="badge badge-light text-dark">Warte auf Auslösung</span>',
                            'text':'Warte auf Auslösung',
                            'css' : 'light text-dark',
                            'id' : 1
                        };
                        break;

                    case 2:
                        status = {
                            'badge' : '<span class="badge badge-info">erstellt</span>',
                            'text':'Aufgabe erstellt',
                            'css' : 'info',
                            'id' : 2
                        };
                        break;

                    case 3:

                        status = {
                            'badge' : '<span class="badge badge-text-light">läuft bald ab</span>',
                            'text':'Frist läuft bald ab',
                            'css' : 'warning text-light',
                            'id' : 3
                        };
                        break;

                    case 4:

                        status = {
                            'badge' : '<span class="badge badge-danger">abgelaufen</span>',
                            'text':'Frist abgelaufen',
                            'css' : 'danger',
                            'id' : 4
                        };
                        break;

                    case 9:

                        status = {
                            'badge' : '<span class="badge badge-danger">negativ geprüft</span>',
                            'text':'Dokument negativ geprüft',
                            'css' : 'danger',
                            'id' : 9
                        };

                        break;

                    case 10:

                        status = {
                            'badge' : '<span class="badge badge-success">positiv geprüft</span>',
                            'text':'Dokument positiv geprüft',
                            'css' : 'success',
                            'id' : 10
                        };
                        break;
                }

                return status
            },

            parseDate: function (date) {
                //let parsed_date = Date.parse(date);
                if (date == null || typeof (date) === 'undefined') {
                    date = "-";
                } else {
                    date = date.frist;
                }
                return date;
            },
            post_sende_erinnerung: function (id_pruefungsschritt_pruefer) {

                let self = this;
                axios.post(this.signed_api_post_sende_erinnerung_in, {
                    id_pruefungsschritt_pruefer: id_pruefungsschritt_pruefer
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Ihre Erinnerung wurde erfolgreich versandt",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            post_ueberschreibe_pruefung: function (pruefungsschritt_pruefer) {

                let self = this;
                axios.post(this.signed_api_post_ueberschreibe_pruefung_in, {
                    id_pruefungsschritt_pruefer: pruefungsschritt_pruefer.id
                })
                    .then(function (res) {
                        pruefungsschritt_pruefer.status = 10;
                        self.$swal({
                            title: 'Erfolg',
                            text: "Dokument trotzdem für aktuell befunden",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                        window.location.reload();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            onDeselect: function () {
            },
            onInput: function () {
            },
        }
    };
</script>
<style scoped>

    .cursor-pointer {
        cursor: pointer;
    }

    .buttons {
        margin-top: 35px;
    }

    .list-group {
        border: unset !important;
        border-color: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .list-group-item {
        border: unset !important;
        border-color: unset !important;
        border: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }
</style>
