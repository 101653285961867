<template>
    <div>
        <div class="row">
            <div class="col-md-10">
                <label>Durchdringungsaufgaben</label><br>
                <b-form-select v-model="aufgabetyp_selected" :options="aufgabetyp_options" value-field="id"
                               text-field="titel" class="mt-3" @change="switchAufgabetyp()"></b-form-select>
            </div>
            <div class="col-md-2 text-right">
                <b-button class="btn btn-sm btn-primary mt-55 w-100" @click="resetToAufgabetyp()">Zurücksetzen
                </b-button>
            </div>
        </div>
        <div class="row mt-20">
            <div class="col-12">
                <div class="card">
                    <div class="card-header card-header-action">
                        <h6>Vorschau Aufgabe</h6>
                        <div class="d-flex align-items-center card-action-wrap">
                            <a class="btn btn-primary btn-xs inline-block text-white" style="cursor: pointer;"
                               v-b-toggle.collapse-1>
                                <i class="ion ion-md-add-circle-outline"></i> Details {{collapsed ? 'ausblenden' :
                                'anzeigen'}}
                            </a>
                        </div>
                    </div>
                    <b-collapse id="collapse-1" v-model="collapsed">
                        <div v-if="aufgabe" class="card-body">
                            <div class="card mt-15 mb-15 ml-15 mr-15">
                                <div class="card-header">
                                    <div class="d-inline-block float-left">{{aufgabe.titel}}
                                    </div>
                                    <div class="d-flex align-items-center card-action-wrap float-right">
                                        <!--<a class="inline-block card-close" href="#" data-effect="fadeOut"
                                           @click="deleteGeltungsbereich(key)">
                                            <i class="ion ion-md-close"></i>
                                        </a>-->
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mt-10 mb-10">
                                                <h6 class="mt-15">Kategorie</h6>
                                                {{aufgabe.kategorie.bezeichnung}}
                                            </div>
                                            <div class="mt-10 mb-10">
                                                <h6 class="mt-15">Aufgabendetails</h6>
                                                {{aufgabe.kommentar}}
                                            </div>
                                            <!--  <div v-if="aufgabe.hat_teilaufgaben" class="mt-10 mb-10">
                                                  <h6 class="mt-15">Teilaufgaben</h6>
                                                 <div v-for="teilaufgabe, key_teilaufgabe in aufgabe.teilaufgaben">
                                                      {{teilaufgabe.}}
                                                 </div>
                                              </div>-->
                                            <div v-if="aufgabe.frist_wochen || aufgabe.frist_tage || aufgabe.frist_stunden"
                                                 class="mt-10 mb-10">
                                                <h6 class="mt-15">Frist (nach Aktivierung)</h6>
                                                <div class="row mt-5">
                                                    <div class="col-4">
                                                        <div class="input-group input-group-sm mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="inputGroupMonth">+ Wochen</span>
                                                            </div>
                                                            <input type="text" class="form-control" id="frist_wochen"
                                                                   name="frist_wochen" placeholder="" required
                                                                   autocomplete="off" aria-label="+ Monate"
                                                                   aria-describedby="inputGroupMonth"
                                                                   v-model="aufgabe.frist_wochen" disabled
                                                                   style="cursor: not-allowed;">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group input-group-sm mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="inputGroupWeek">+ Tage</span>
                                                            </div>
                                                            <input type="text" class="form-control" id="frist_tage"
                                                                   name="frist_tage" placeholder="" required
                                                                   autocomplete="off" aria-label="+ Monate"
                                                                   aria-describedby="inputGroupWeek"
                                                                   v-model="aufgabe.frist_tage" disabled
                                                                   style="cursor: not-allowed;">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group input-group-sm mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text" id="inputGroupDay">+ Stunden</span>
                                                            </div>
                                                            <input type="text" class="form-control" id="frist_stunden"
                                                                   name="frist_stunden" placeholder="" required
                                                                   autocomplete="off" aria-label="+ Monate"
                                                                   aria-describedby="inputGroupDay"
                                                                   v-model="aufgabe.frist_stunden" disabled
                                                                   style="cursor: not-allowed;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header card-header-action">
                        <h6>Multiplikatoren</h6>
                        <div class="d-flex align-items-center card-action-wrap">
                            <a class="btn btn-primary btn-xs inline-block text-white" style="cursor: pointer;"
                               v-b-toggle.collapse-2>
                                <i class="ion ion-md-add-circle-outline"></i> Details {{collapsed ? 'ausblenden' :
                                'anzeigen'}}
                            </a>
                        </div>
                    </div>
                    <b-collapse id="collapse-2" v-model="collapsed2">
                        <div v-if="geltungen.length > 0" class="card-body">
                            <div v-for="geltung,key in geltungen">
                                <div class="card mt-15 mb-15 ml-15 mr-15">
                                    <div class="card-header ">
                                        <div class="d-inline-block float-left">{{ key + 1 }}. Geltungsbereich:
                                            {{geltung.bezeichnung}}
                                        </div>
                                        <div class="d-flex align-items-center card-action-wrap float-right">
                                            <a class="inline-block" href="#" data-effect="fadeOut"
                                               @click="deleteGeltungsbereich(key)">
                                                <i class="ion ion-md-close"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mt-10 mb-10">
                                                    <h6 class="mt-15">Verantwortliche Personen:</h6>
                                                    <div class="mt-10 mb-10">
                                                        <div v-if="geltung.multiplikatoren.direkte_multiplikator">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <span class="d-block font-14 font-weight-500 text-dark text-uppercase">Direkte Multiplikatoren</span>
                                                                </div>
                                                            </div>
                                                            <div v-if="geltung.multiplikatoren.direkte_multiplikator.length > 0">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Option</span>
                                                                    </div>
                                                                </div>
                                                                <div v-for="multiplikator,key_multiplikator in geltung.multiplikatoren.direkte_multiplikator">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <!-- <i v-if="verantwortlicher.doppelte"
                                                                                class="ion ion-md-warning text-danger"
                                                                                v-b-tooltip.hover
                                                                                title="Person mehrfach vorhanden"></i>-->
                                                                            <span class="text-info">{{multiplikator.anrede}} {{multiplikator.titel}} {{multiplikator.nachname}}, {{multiplikator.vorname}}</span>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            <a href="javascript:void(0)"
                                                                               @click='deleteMitarbeiter(key, key_multiplikator,"direkte_multiplikator", multiplikator)'>
                                                                                <i class="zmdi zmdi-delete font-20 mr-10 text-light-40"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                            </div>
                                                            <div v-else>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span class="text-danger">Keine Verantwortlichen festgelegt</span>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-10 mb-10">
                                                        <div v-if="geltung.multiplikatoren.uebergeordnete_multiplikator">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <span class="d-block font-14 font-weight-500 text-dark text-uppercase">Übergeordnete Multiplikatoren</span>
                                                                </div>
                                                            </div>
                                                            <div v-if="geltung.multiplikatoren.uebergeordnete_multiplikator.length > 0">
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                                    </div>
                                                                    <div class="col-md-2">
                                                                        <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Option</span>
                                                                    </div>
                                                                </div>
                                                                <div v-for="multiplikator,key_multiplikator in geltung.multiplikatoren.uebergeordnete_multiplikator">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <i v-if="multiplikator.doppelte"
                                                                               class="ion ion-md-warning text-danger"
                                                                               v-b-tooltip.hover
                                                                               title="Person mehrfach vorhanden"></i>
                                                                            <span class="text-info">{{multiplikator.anrede}} {{multiplikator.titel}} {{multiplikator.nachname}}, {{multiplikator.vorname}}</span>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            <a href="javascript:void(0)"
                                                                               @click='deleteMitarbeiter(key, key_multiplikator,"uebergeordnete_multiplikator", multiplikator)'>
                                                                                <i class="zmdi zmdi-delete font-20 mr-10 text-light-40"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                            </div>
                                                            <div v-else>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <span class="text-danger">Keine Verantwortlichen festgelegt</span>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="mt-15 ml-15 mr-15 alert alert-info">Keine Multiplikatoren hinterlegt
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    let id = 1;
    export default {
        props: ['geltungen_in', 'durchdringungsaufgabe_typen_in', 'signed_api_store_in', 'signed_api_get_aufgabe_typ_in'],
        name: "Durchdringung",
        display: "Durchdringung",
        order: 0,
        components: {},
        data() {
            return {
                enabled: true,
                collapsed: false,
                collapsed2: false,

                geltungen: this.geltungen_in,

                aufgabe: null,
                aufgabetyp_selected: null,
                aufgabetyp_options: this.durchdringungsaufgabe_typen_in,

            };
        },
        mounted() {
            this.aufgabetyp_selected = 5;
            this.aufgabe = this.aufgabetyp_options[0];
            this.storeAll();
        },
        computed: {},
        methods: {
            deleteGeltungsbereich: function (key) {
                this.geltungen.splice(key, 1);
                this.storeAll();
            },
            deleteMitarbeiter: function (key, key_multiplikator, multiplikator_beziehung, multiplikator) {
                this.geltungen[key].multiplikatoren[multiplikator_beziehung].splice(key_multiplikator, 1);
                this.storeAll();
            },
            switchAufgabetyp: function () {
                for (let aufgabe_typ in this.aufgabetyp_options) {
                    if (this.aufgabetyp_options[aufgabe_typ].id == this.aufgabetyp_selected) {
                        this.aufgabe = this.aufgabetyp_options[aufgabe_typ];
                    }
                }
            },
            resetToAufgabetyp: function () {
                let self = this;
                axios.post(this.signed_api_get_aufgabe_typ_in, {
                    durchdringung_data: this.geltungen
                }).then(function (res) {
                    self.geltungen = res.data;
                    self.storeAll();
                    self.$swal({
                        title: 'Erfolg',
                        text: "Ihre Änderungen wurden zurückgesetzt",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            storeAll: function () {
                let self = this;
                axios.post(this.signed_api_store_in, {
                    durchdringung_data: this.geltungen
                }).then(function (res) {
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
        }
    };
</script>
<style scoped>
</style>
