<template>
    <div class="row">
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Aufgabe bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_1" class="collapse show">
                                        <div class="card-body">
                                            <label class="required">Frist</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="input-group input-group-sm mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                  id="inputGroupWeek">+ Wochen</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="frist_wochen"
                                                               name="frist_wochen" placeholder="" required
                                                               autocomplete="off" aria-label="+ Wochen"
                                                               aria-describedby="inputGroupWeek"
                                                               v-model="aufgabe.frist_wochen" @change="saveInput">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group input-group-sm mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                  id="inputGroupDay">+ Tage</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="frist_tage"
                                                               name="frist_tage" placeholder="" required
                                                               autocomplete="off" aria-label="+ Tage"
                                                               aria-describedby="inputGroupDay"
                                                               v-model="aufgabe.frist_tage" @change="saveInput">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group input-group-sm mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"
                                                                  id="inputGroupHour">+ Stunden</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="frist_stunden"
                                                               name="frist_stunden" placeholder="" required
                                                               autocomplete="off" aria-label="+ Stunden"
                                                               aria-describedby="inputGroupHour"
                                                               v-model="aufgabe.frist_stunden" @change="saveInput">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_2" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Verantworliche bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_2" class="collapse show">
                                        <div class="card-body">
                                            <div class="row"
                                                 v-for="verantwortung,key_verantwortung in verantwortungen">
                                                <div class="col-md-12">
                                                    <div>
                                                        <span
                                                            class="d-block font-14 font-weight-500 text-dark text-uppercase">{{key_verantwortung}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12" style="min-height:400px; height:auto">
                                                    <treeselect_component
                                                        :name_input="key_verantwortung"
                                                        :options_input="options"
                                                        :value_input="getValues(verantwortungen[key_verantwortung])"
                                                        include_ids_input='[]'
                                                        exclude_ids_input='[]'
                                                        exclude_own_switch=false
                                                        multi_switch='true'
                                                        :api_url='api_einstellungen_meldungsvorlagen_aufgaben_get_verantwortliche_in'
                                                        max_height_in='320'
                                                        limit_in='5'
                                                    />
                                                    <br><br>
                                                </div>
                                            </div>
                                            <div class="text-right">
                                                <b-button variant="primary" @click="saveInput()">Speichern</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['api_einstellungen_meldungsvorlagen_aufgaben_store_in', 'api_einstellungen_meldungsvorlagen_aufgaben_show_in', 'api_einstellungen_meldungsvorlagen_aufgaben_get_verantwortliche_in'],
        components: {},
        data() {
            return {
                lang: null,
                options: [],
                aufgabe: [{
                    frist_wochen: 0,
                    frist_tage: 0,
                    frist_stunden: 0
                }],
                verantwortungen: {
                    anonymisieren: [],
                    hauptverantwortung: [],
                },
                value_arr: [],

            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            let self = this;

            this.$eventBus.$on('emelder_meldungsvorlage:change_treeselect', (values,id) => {
                //self.change_verantwortung(values);
               /* console.log(values);
                console.log(self.verantwortungen[values.name]);*/
                self.verantwortungen[values.name] = values.values;
            });

            this.getInput();
        },
        mounted() {

        },
        watch: {},
        computed: {},
        methods: {
            getInput() {
                let self = this;
                axios.get(this.api_einstellungen_meldungsvorlagen_aufgaben_show_in, {})
                    .then(function (res) {
                        self.aufgabe = res.data.aufgabe;
                        self.verantwortungen = res.data.verantwortungen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getValues(objs) {
                let value = new Array();
                if (objs)
                    for (let obj of objs) {
                        value.push(obj.id)
                    }
                return '[' + value.join(", ") + ']';
            },
            saveInput() {
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_aufgaben_store_in, {
                    aufgabe: this.aufgabe,
                    verantwortungen: this.verantwortungen,
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Aufgaben & Verantwortungen erfolgreich gespeichert",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    };
</script>
<style scoped>

    .emelder_einstellungen_card_zeile {
        width: 100% !important;
        border-left-color: #00acf0 !important;
        border-left-width: 15px !important;
    }

</style>
