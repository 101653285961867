<template>
  <div class="row">
    <div class="col-6">
          <treeselect
            name="system_qmb"
            :multiple="multiple"
            :clearable="clearable"
            :searchable="searchable"
            :searchNested="searchNested"
            :async="async"
            :disabled="disabled"
            :open-on-click="openOnClick"
            :open-on-focus="openOnFocus"
            :clear-on-select="clearOnSelect"
            :close-on-select="closeOnSelect"
            :always-open="alwaysOpen"
            :append-to-body="appendToBody"
            :options="options"
            :value-consists-of="valueConsistsOf"
            :loading="loading"
            :auto-load-root-options="auto_load_root_options"
            :load-options="loadOptions"
            :show-count="showCount"
            :showCountOf="showCountOf"
            :loadingText="loadingText"
            :noChildrenText="noChildrenText"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            :openDirection="openDirection"
            :placeholder="placeholder"
            :required="required"
            :limit="limit"
            :max-height="maxHeight"
            :disableFuzzyMatching="disableFuzzyMatching"
            v-model="value"
            :normalizer="normalizer"
            @select="onSelect"
            @deselect="onDeselect"
            @input="onInput"
            ></treeselect>
    </div>
  </div>
</template>
<script>
    //import Treeselect from '@riophae/vue-treeselect';

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
    import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'


    export default {
        props: ['org_gruppe_id_in','mitarbeiter_selected_in','locale_in'],
        components: {Treeselect},
        data() {
            return {
                new_input: '',
                lang: null,

                // Switch Parameter
                multiple: false,
                disableFuzzyMatching: true,
                include_ids: null,
                exclude_ids: null,
                clearable: true,
                searchable: true,
                searchNested: false,
                async: false,
                disabled: false,
                openOnClick: true,
                openOnFocus: false,
                clearOnSelect: true,
                closeOnSelect: false,
                alwaysOpen: true,
                appendToBody: false,
                valueConsistsOf: 'LEAF_PRIORITY',
                loadingText: 'Lade...',
                noChildrenText: 'Keine Mitarbeiter zugeordnet',
                noOptionsText: 'Keine Daten',
                noResultsText: 'Keine Daten',
                openDirection: 'below',
                placeholder: 'Bitte wählen Sie Ihre Mitarbeiter aus',
                auto_load_root_options: false,
                loading: true,
                required: false,
                maxHeight: 240,
                limit: Infinity,
                showCount: false,
                showCountOf: 'ALL_CHILDREN',
                value: null,
                options: null,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        //children: node.subOptions,
                    }
                },
            }
        },
       /* created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined'){
                lang.setLocale('de');
            }

            lang.setMessages(translations);

            this.lang = lang;
        },*/
        mounted() {
          this.value = this.mitarbeiter_selected_in;
        },
        methods: {
            getSelected(){
              this.value = this.mitarbeiter_selected_in;
            },
            onSelect(node, id) {
              /*  console.log('selected');*/
            },
            onInput(node, id) {
             /* console.log('Input');*/
            },
            onDeselect(node, id) {
             /* console.log('deselected');*/
            },
            loadOptions({action, parentNode, callback}) {
            if (action === LOAD_ROOT_OPTIONS) {
                self = this;
                axios.get('/dokman/einstellungen/optionen/api/api_get_mitglieder')
                    .then(function (res) {
                        self.options = res.data;
                        callback();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
            },
        },
    }
</script>

<style lang="scss">


    .he-tree {
        //border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;

        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;

        border-color: #eaecec;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 14px;

    }

    .max-height{
        height:480px;
        max-height: 100%;
    }

    .tree-node {
    }

    .tree-node-inner {
        //padding: 5px;
        padding: .75rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: .25rem;
        cursor: pointer;
    }

    .draggable-placeholder {
    }

    .draggable-placeholder-inner {
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .edit_input_field {
        width: 80% !important;
        display: inline !important;
    }

    .list-group-item {
        border: 1px solid #ccc !important;
    }


    /*.tree3{

        .tree-node-inner{
            border: none;
            padding: 0px;
        }
        .tree-node-inner-back:hover{
            background: #ddd;
        }
    }*/

    /* .tree4{
         .tree-node-inner{
             border: none;
             border-bottom: 1px solid #ccc;
             padding: 5px 10px;
             backgroud: #ccc;
     }
         .draggable-placeholder-inner{
             background-color: orangered;
         }
     }*/

    .button {
        margin-top: 35px;
    }

    .handle {
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .close {
        float: right;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .text, .vue-treeselect__label {
        color: #5e7d8a;
    }



</style>
