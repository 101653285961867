<template>
    <div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" name="count" :value="list.length">
                <div v-if="list.length > 0">
                    <draggable
                        :list="list"
                        :disabled="!enabled"
                        class="list-group"
                        ghost-class="ghost"
                        :move="checkMove"
                        @start="dragging = true"
                        @end="dragging = false"
                    >
                        <div
                            class="list-group-item"
                            v-for="element,key in list"
                            :key="element.id"
                        >
                            <div class="card mt-30 mb-30">
                                <div class="card-header ">
                                    <div class="d-inline-block float-left mr-20"><i
                                        class="fa-2x d-inline cursor-pointer ion ion-md-menu"></i>
                                    </div>
                                    <div class="d-inline-block float-left">{{ key + 1 }}. Schritt: {{ vorlage_typ }} -
                                        {{
                                            element.name
                                        }}
                                    </div>
                                    <div class="d-flex align-items-center card-action-wrap float-right">
                                        <a class="inline-block card-close" href="#" data-effect="fadeOut"
                                           @click="remove(key)">
                                            <i class="ion ion-md-close"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12"><strong><h4>{{ element.aufgabe_text }}</h4>
                                                </strong>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mt-10">
                                                    <strong>Frist:</strong>
                                                    (beginnt zum Zeitpunkt der Aktivierung ) <br>

                                                    <span
                                                        v-if="element.frist_monate || element.frist_wochen || element.frist_tage">
                                                        <span v-if="element.frist_monate"
                                                              class="font-weight-600 text-success mr-30">+ {{
                                                                element.frist_monate
                                                            }} Monate</span>
                                                        <span v-if="element.frist_wochen"
                                                              class="font-weight-600 text-success mr-30">+ {{
                                                                element.frist_wochen
                                                            }} Wochen </span>
                                                        <span v-if="element.frist_tage"
                                                              class="font-weight-600 text-success mr-30">+ {{
                                                                element.frist_tage
                                                            }} Tage </span>
                                                    </span>
                                                    <span v-else>
                                                         <span
                                                             class="font-weight-600 text-info mr-30">Ohne Fristvorgabe</span>
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mt-10 mb-10">
                                                <div class="row">
                                                    <div class="col-md-12 d-inline">
                                                           <span class="font-16">Ab wieviel positiven Entscheidungen,
wird dieser Schritt erfolgreich bewertet (min.): </span> <span
                                                        class="badge badge-primary ml-15">{{
                                                            element.min_positive
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" :name="'id_' + key" :value="element.id">
                                    <input type="hidden" :name="'vorlage_typ_' + key" :value="vorlage_typ">
                                    <input type="hidden" :name="'vorlage_name_' + key" :value="vorlage_typ">
                                    <input type="hidden" :name="'name_' + key" :value="element.name">
                                    <input type="hidden" :name="'id_pruefungsschritte_typen_' + key"
                                           :value="element.id_pruefungsschritte_typen">
                                    <input type="hidden" :name="'aufgabe_text_' + key" :value="element.aufgabe_text">
                                    <input type="hidden" :name="'frist_monate_' + key" :value="element.frist_monate">
                                    <input type="hidden" :name="'frist_wochen_' + key" :value="element.frist_wochen">
                                    <input type="hidden" :name="'frist_tage_' + key" :value="element.frist_tage">
                                    <input type="hidden" :name="'min_positive_' + key" :value="element.min_positive">
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
                <div v-else="list.length > 0">
                    <div class="alert alert-info mt-15 mb-15 w-100">Keine Schritte vorhanden</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group mt-15">
                    <b-button class="btn btn-secondary" v-b-modal.modal-1>Neuen Schritt hinzufügen</b-button>

                    <b-modal id="modal-1" title="Neuen Schritt hinzufügen" @ok="add()" @show="resetModal()">
                        <div class="row mb-15">
                            <div class="col-md-8">
                                <input type="hidden" name="id_aufgabeart" id="id_aufgabeart" value="1">
                            </div>
                        </div>
                        <div class="row mb-30">
                            <div class="col-md-8">
                                <label for="aufgabeschritt_vorlage" class="required">{{ vorlage_typ }}</label>
                                <select name="aufgabeschritt_vorlage" id="aufgabeschritt_vorlage" class="form-control"
                                        v-model="new_aufgabeschritt_vorlage">
                                    <option v-for="pruefungsschritte_typ in pruefungsschritte_typen"
                                            :value="[pruefungsschritte_typ.id, pruefungsschritte_typ.bezeichnung]">
                                        {{ pruefungsschritte_typ.bezeichnung }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="aufgabeschritt_aufgabe_text" class="required">Aufgabetext</label>
                            <input type="text" class="form-control" id="aufgabeschritt_aufgabe_text"
                                   name="aufgabeschritt_aufgabe_text" placeholder="Bitte eingeben" required
                                   autocomplete="off" v-model="new_aufgabeschritt_aufgabe_text">
                        </div>
                        <label class="required">Frist</label>
                        <div class="row">
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupMonth">+ Monate</span>
                                    </div>
                                    <input type="text" class="form-control" id="aufgabeschritt_frist_monate"
                                           name="aufgabeschritt_frist_monate" placeholder="" required
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupMonth"
                                           v-model="new_aufgabeschritt_frist_monate">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupWeek">+ Wochen</span>
                                    </div>
                                    <input type="text" class="form-control" id="aufgabeschritt_frist_wochen"
                                           name="aufgabeschritt_frist_wochen" placeholder="" required
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupWeek"
                                           v-model="new_aufgabeschritt_frist_wochen">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupDay">+ Tage</span>
                                    </div>
                                    <input type="text" class="form-control" id="aufgabeschritt_frist_tage"
                                           name="aufgabeschritt_frist_tage" placeholder="" required
                                           autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupDay"
                                           v-model="new_aufgabeschritt_frist_tage">
                                </div>
                            </div>
                        </div>
                        <label class="required mt-5">Positive Entscheidungen nötig</label>
                        <div class="row">
                            <div class="col-4">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupMin">min.</span>
                                    </div>
                                    <input type="text" class="form-control" id="aufgabeschritt_min_positive"
                                           name="aufgabeschritt_min_positive" placeholder="" required
                                           autocomplete="off" aria-label="min." aria-describedby="inputGroupMin"
                                           value="" v-model="new_aufgabeschritt_min_positive">
                                </div>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

let id = 1;
export default {
    props: ['vorlage_typ_in', 'pruefungsschritte_typen_in', 'pruefungsschritte_in'],
    name: "PruefungsVorlage",
    display: "PruefungsVorlage",
    order: 0,
    components: {
        draggable
    },
    data() {
        return {
            enabled: true,
            vorlage_typ: this.vorlage_typ_in,

            list: this.pruefungsschritte_in,
            pruefungsschritte_typen: this.pruefungsschritte_typen_in,

            new_aufgabeschritt_vorlage: '',
            new_aufgabeschritt_aufgabe_text: '',
            new_aufgabeschritt_frist_monate: '',
            new_aufgabeschritt_frist_wochen: '',
            new_aufgabeschritt_frist_tage: '',
            new_aufgabeschritt_min_positive: '',

            dragging: false
        };
    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        }
    },
    methods: {
        resetModal: function () {
            this.new_aufgabeschritt_vorlage = [1, "Nach Unternehmensstruktur"];
            this.new_aufgabeschritt_aufgabe_text = "Bitte prüfen Sie dieses Dokument";
            this.new_aufgabeschritt_frist_monate = "";
            this.new_aufgabeschritt_frist_wochen = "";
            this.new_aufgabeschritt_frist_tage = "";
            this.new_aufgabeschritt_min_positive = 2;
        },
        add: function () {
            this.list.push({
                id: id++,
                name: this.new_aufgabeschritt_vorlage[1],
                id_pruefungsschritte_typen: this.new_aufgabeschritt_vorlage[0],
                aufgabe_text: this.new_aufgabeschritt_aufgabe_text,
                frist_monate: this.new_aufgabeschritt_frist_monate,
                frist_wochen: this.new_aufgabeschritt_frist_wochen,
                frist_tage: this.new_aufgabeschritt_frist_tage,
                min_positive: this.new_aufgabeschritt_min_positive
            });
        },
        remove: function (key) {
            this.list.splice(key, 1);
        },
        checkMove: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        }
    }
};
</script>
<style scoped>

.cursor-pointer {
    cursor: pointer;
}

.buttons {
    margin-top: 35px;
}

.list-group-item {
    border: 2px solid #eaecec !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0rem 1.25rem !important;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
