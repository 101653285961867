<template>
    <div class="row">
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card card-refresh">
                                    <div class="card-header card-header-action">
                                        <h6>Anonymisieren bearbeiten</h6>
                                        <div class="d-flex align-items-center card-action-wrap">
                                            <a class="inline-block mr-15" data-toggle="collapse"
                                               href="#collapse_1" aria-expanded="true">
                                                <i class="zmdi zmdi-chevron-down"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div id="collapse_1" class="collapse show">
                                        <div class="card-body">
                                            <br>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    Soll für diese Meldungsvorlage eine Anonymisierung durchgeführt werden?
                                                </div>
                                            </div>
                                            <div class="row mt-15">
                                                <div class="col-md-6">
                                                    <div class="custom-control custom-radio radio-primary">
                                                        <b-form-radio v-model="anonymisieren" name="id_wirksam" value="1">Ja</b-form-radio>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="custom-control custom-radio radio-primary">
                                                        <b-form-radio v-model="anonymisieren" name="id_wirksam" value="0">Nein</b-form-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="text-right">
                                                <b-button variant="primary" @click="saveInput()">Speichern</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['api_einstellungen_meldungsvorlagen_anonymisieren_store_in', 'api_einstellungen_meldungsvorlagen_anonymisieren_show_in'],
        components: {},
        data() {
            return {
                data: [],
                lang: null,
                input: null,
                anonymisieren: false,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.getInput();
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getInput() {
                let self = this;
                axios.get(this.api_einstellungen_meldungsvorlagen_anonymisieren_show_in, {})
                    .then(function (res) {
                        self.anonymisieren = res.data.anonymisieren;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveInput() {
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_anonymisieren_store_in, {
                    anonymisieren: this.anonymisieren
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Anonymisierung wurde erfolgreich gespeichert",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        self.$swal({
                            title: 'Fehler',
                            text: "Anonymisierung wurde nicht gespeichert",
                            type: 'error',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    });
            },
        }
    };
</script>
<style scoped>

    .emelder_einstellungen_card_zeile {
        width: 100% !important;
        border-left-color: #00acf0 !important;
        border-left-width: 15px !important;
    }

</style>
