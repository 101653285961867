<template>
    <div>
        <nav class="pagination-wrap d-inline-block mt-15 float-right" aria-label="">
            <ul class="pagination" role="navigation">
                <li class="page-item" :class="{ disabled : current_page == 1}">
                    <a href="#" @click.prevent="decrementPage" class="d-block">
                        <span class="page-link" aria-hidden="true">&lsaquo; Vorherige</span>
                    </a>
                </li>
                <li v-for="link in page_count" class="page-item" :class="{active : current_page == link}">
                    <a href="#" @click.prevent="jumpToPage(link)" class="d-block">
                        <span class="page-link">{{link}}</span>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled : current_page == page_count}" aria-label="Nächste">
                    <a href="#" @click.prevent="incrementPage" class="d-block"><span class="page-link">Nächste &rsaquo;</span></a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['signed_api_get_table_route','page_in'],
        components: {},
        data() {
            return {
                queryValue: {
                    search_str: null,
                    id_org_gruppe: null,
                    id_org_element: null,
                    id_dokumentenart: null,
                    id_inhaltstyp: null,
                    id_status: null,
                    id_redakteur:null,
                    eigene: null,
                    hgb: null,
                    fuehrende: null,
                    page: this.page_in
                },
                item_count: null,
                current_page: null,
                per_page: null,
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            let self = this;
            this.$eventBus.$on('searchQuery', function (queryValue) {
                self.queryValue = queryValue;
                self.getData()
            });

        },
        mounted() {
            console.log('mounted MainTable');
            this.getData();
        },
        watch: {},
        computed: {
            page_count(){
                return (this.item_count && this.per_page)? Math.ceil( this.item_count / this.per_page) : 0;
            }
        },
        methods: {
            getData() {
                //console.log(this.queryValue);
                let self = this;
                axios.post(this.signed_api_get_table_route, {
                    search_str: this.queryValue['volltextsuche'],
                    id_org_gruppe: this.queryValue['org_gruppe'],
                    id_org_element: this.queryValue['org_element'],
                    id_meldungskategorie: this.queryValue['meldungskategorie'],
                    id_verantwortlicher: this.queryValue['verantwortlicher'],
                    id_status: this.queryValue['status'],
                    eigene: this.queryValue['switch_eigene'],
                    page: this.queryValue['page'] ? this.queryValue['page'] : this.page,
                })
                    .then(function (res) {
                        $("#mainTable").html(res.data);

                        setTimeout( function(){
                            self.item_count = parseInt($("#count").val());
                            self.current_page = parseInt($("#current_page").val());
                            self.per_page = parseInt($("#per_page").val());
                            $("#cover-spin").hide();

                        }, 100);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            incrementPage(){
                console.log('in');
                this.current_page = (this.current_page < this.page_count) ? parseInt(this.current_page) + 1 : this.page_count;
                this.triggerSearchEvent();
            },
            decrementPage(){
                console.log('de');
                this.current_page = (this.current_page > 1) ? parseInt(this.current_page) - 1 : 1;
                this.triggerSearchEvent();
            },
            jumpToPage(page){
                this.current_page = page;
                this.triggerSearchEvent();
            }
        }
    };
</script>
<style scoped>
</style>
