<template>
    <div>
        <a id="close_fmapp_sidebar" href="javascript:void(0)" class="close-fmapp-sidebar">
            <span class="feather-icon"><i data-feather="chevron-left"></i></span>
        </a>
        <input type="text" class="form-control form-control-sm" name="volltextsuche"
               placeholder="Volltextsuche" @keyup="InputVolltextsuche()" v-model="volltextsuche">
        <hr>
        <select name="aufgabe_typ" id="aufgabe_typ"
                class="form-control form-control-sm mb-10" v-model="aufgabe_typ" @change="triggerSearchEvent()">
            <option value="" selected> - Aufgabe Typ -</option>
            <option value="aufgaben">Allgemeine Aufgaben</option>
            <option value="dokman">Dokman Aufgaben</option>
            <option value="emelder">EMelder Aufgaben</option>
        </select>
        <select name="postfach" id="postfach"
                class="form-control form-control-sm mb-10" v-model="postfach" @change="triggerSearchEvent()">
            <option value="" selected hidden> - Postfach -</option>
            <option value="posteingang">Posteingang</option>
            <option value="postausgang">Postausgang</option>
            <option value="wiederkehrende">wiederkehrende</option>
        <!--<option value="alle">Alle</option>-->
        </select>

        <b-form-datepicker id="zeitraum_von" v-model="zeitraum_von" name="zeitraum_von" class="mb-10" placeholder="Zeitraum von" @input="triggerSearchEvent()"  locale="de-DE" label-help="" label-no-date-selected="Kein Zeitraum ausgewählt" :reset-button="button_reset"></b-form-datepicker>

        <b-form-datepicker id="zeitraum_bis" v-model="zeitraum_bis" name="zeitraum_bis" class="mb-10" placeholder="Zeitraum bis" @input="triggerSearchEvent()"  locale="de-DE" label-help="" label-no-date-selected="Kein Zeitraum ausgewählt" :reset-button="button_reset"></b-form-datepicker>

        <select name="status" id="status" class="form-control form-control-sm mb-10" v-model="id_status"
                @change="triggerSearchEvent()">
            <option value="" selected> - Status -</option>
            <option value="0">Gelöscht</option>
            <option value="1">Ungelesen</option>
            <option value="2">Gelesen</option>
            <option value="3">Ausgelaufen</option>
            <option value="4">Delegiert</option>
            <option value="10">Erledigt</option>
        </select>
       <!-- <select v-if="redakteur_data != ''" name="redakteur" id="redakteur"
                class="form-control form-control-sm mb-10 mt-25" @change="triggerSearchEvent()" v-model="redakteur">
            <option value="" selected> - Redakteur -</option>
            <option v-for="single_redakteur in redakteur_data" :value="single_redakteur.id">
                {{single_redakteur.user_data.anrede ? single_redakteur.user_data.anrede.bezeichnung : '' }} {{single_redakteur.user_data.titel ? single_redakteur.user_data.titel.bezeichnung : ''}} {{single_redakteur.user_data.nachname}}, {{single_redakteur.user_data.vorname}}
            </option>
        </select>-->
        <div class="row mt-25">
            <div class="col-3">
                <toggle-button v-model="switch_wiederkehrend" :sync="true" @change="toggleSearch('switch_wiederkehrend')" color="#00acf0" :value="switch_wiederkehrend"/>
            </div>
            <div class="col-9">
                Wiederkehrende
            </div>
        </div>
        <div class="row mt-10">
            <div class="col-3">
                <toggle-button v-model="switch_delegiert" :sync="true" @change="toggleSearch('switch_delegiert')" color="#00acf0" :value="switch_delegiert"/>
            </div>
            <div class="col-9">
               Delegierte
            </div>
        </div>
        <div class="row mt-10">
            <div class="col-3">
                <toggle-button v-model="switch_ausgeblendet" :sync="true" @change="toggleSearch('switch_ausgeblendet')" color="#00acf0" :value="switch_ausgeblendet"/>
            </div>
            <div class="col-9">
                Ausgeblendete
            </div>
        </div>
        <hr>
        <div class="row mt-10">
            <button class="btn btn-sm btn-primary ml-auto mr-auto" @click="resetStoreData()">
                Reset Filter
            </button>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['postfach_in', 'user_von_in', 'page_in'],
        components: {},
        data() {
            return {
                volltextsuche: null,
                aufgabe_typ: "",
                postfach: this.postfach_in,
                zeitraum_von: null,
                zeitraum_bis: null,
                id_status: "",
                id_user_von: [],
                switch_wiederkehrend: false,
                switch_delegiert: false,
                switch_ausgeblendet: false,
                page: this.page_in,
                button_reset: true,
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
            this.getStoreData();
            self = this;
            setTimeout(function(){
                self.triggerSearchEvent()
            }, 300);

        },
        beforeCreate() {
            this.$store.$store.commit('restoreQueryAufgaben');
        },
        watch: {},
        computed: {},
        methods: {
            InputVolltextsuche() {
                if (this.volltextsuche.length > 1 || this.volltextsuche == '') {
                    this.triggerSearchEvent();
                }
            },
            triggerSearchEvent() {
               this.$eventBus.$emit('aufgabenSearchQuery', {
                    volltextsuche: this.volltextsuche,
                    aufgabe_typ: this.aufgabe_typ,
                    postfach: this.postfach,
                    zeitraum_von: this.zeitraum_von,
                    zeitraum_bis: this.zeitraum_bis,
                    id_status: this.id_status,
                    id_user_von: this.id_user_von,
                    switch_wiederkehrend: this.switch_wiederkehrend,
                    switch_delegiert: this.switch_delegiert,
                    switch_ausgeblendet: this.switch_ausgeblendet,
                    page: this.page,
                });
            },
            toggleSearch(value) {
                this.triggerSearchEvent();
            },
            getStoreData(){
                this.volltextsuche = this.$store.$store.state.search_aufgaben.volltextsuche;
                this.aufgabe_typ = this.$store.$store.state.search_aufgaben.aufgabe_typ;
                this.postfach = this.$store.$store.state.search_aufgaben.postfach;
                this.zeitraum_von = this.$store.$store.state.search_aufgaben.zeitraum_von;
                this.zeitraum_bis = this.$store.$store.state.search_aufgaben.zeitraum_bis;
                this.id_status = this.$store.$store.state.search_aufgaben.id_status;
                this.id_user_von = this.$store.$store.state.search_aufgaben.id_user_von;
                this.switch_wiederkehrend = this.$store.$store.state.search_aufgaben.switch_wiederkehrend;
                this.switch_delegiert = this.$store.$store.state.search_aufgaben.switch_delegiert;
                this.switch_ausgeblendet = this.$store.$store.state.search_aufgaben.switch_ausgeblendet;
                this.page = this.$store.$store.state.search_aufgaben.page;
            },

            resetStoreData(){
                this.volltextsuche = null;
                this.aufgabe_typ = "";
                this.postfach = this.postfach_in;
                this.zeitraum_von = null;
                this.zeitraum_bis = null;
                this.id_status = "";
                this.id_user_von = [];
                this.switch_wiederkehrend = false;
                this.switch_delegiert = false;
                this.switch_ausgeblendet = false;
                this.page = null;

                this.$store.$store.commit('resetQueryDataAufgaben');

                this.$swal({
                    title: 'Erfolg',
                    text: "Ihre Filterung wurden zurückgesetzt",
                    type: 'success',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.triggerSearchEvent();
            }
        }
    };
</script>
<style scoped>
</style>
