<template>
    <div>
        <b-form-group
            :id="grid_col.baustein_key + '-group'"
            :description="baustein.description"
            :label="baustein.label"
            :label-for="grid_col.baustein_key"
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="state"

        >
            <template v-slot:label>
                {{baustein.label}} <span v-if="baustein.required" class="text-danger">*</span>
            </template>
            <b-form-select
                v-model="input"
                :options="options"
                :id="grid_col.baustein_key"
                :state="state"
                autocomplete="off"
                @input="init"
                @blur="blur"
                value-field="bezeichnung"
                text-field="bezeichnung"
                :disabled="disabled"
                :multiple="auswahlliste.typ && auswahlliste.typ === 'multi'"
            ></b-form-select>

            <input type="hidden" :name="grid_col.baustein_key" v-model="input">
        </b-form-group>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['grid_col', 'baustein', 'input_in', 'options_in', 'disabled_in', 'api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in'],
        name: 'EMelderComponentMeldenBs9GlobaleAuswahlListe',
        components: {},
        data() {
            return {
                data: [],
                lang: null,
                input: null,
                initial: null,
                disabled: null,
                auswahlliste: {
                    text: null,
                    value: null,
                    typ: false,
                    optionen: {},
                },
                options: this.options_in
            }
        },
        computed: {
            state() {
                if (this.baustein.required === 0)
                    return null;

                if (this.initial === null)
                    return null;

                if (this.initial === 1 && this.input.length >= 1 && (this.initial === 1 && this.input.length < 3))
                    return false;

                return (this.initial === 1 && this.input.length >= 3) ? true : false
            },
            invalidFeedback() {
                if (this.initial && this.input.length > 3) {
                    return ''
                } else if (this.initial && this.input.length > 0) {
                    return 'Bitte mindestens 3 Zeichen eingeben'
                } else {
                    return 'Eingabe benötigt'
                }
            },
            validFeedback() {
                return this.state === true ? '' : ''
            }
        },
        created() {
            var lang = new Lang();
            lang.setLocale(this.locale_in);
            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }

            lang.setMessages(translations);
            this.lang = lang;

            this.input = this.input_in;

            this.disabled = JSON.parse(this.disabled_in);

            this.getAuswahlliste(this.baustein.option1);
        },
        mounted() {
        },
        methods: {
            init() {
                this.initial = 1;
            },
            blur() {
                this.initial = null;
            },
            getAuswahlliste(idAuswahlliste) {
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in, {
                    id_auswahlliste: idAuswahlliste,
                })
                    .then(function (res) {
                        if(res.data.auswahlliste.typ == 'multi')
                            self.input = [];
                        self.auswahlliste = res.data.auswahlliste;
                        self.options = res.data.auswahlliste.optionen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
    }
</script>

<style lang="scss"></style>
