<template>
    <div>
        <b-button class="btn btn-primary" variant="primary" v-b-modal.modal-aufgabe-rueckmeldung>Rückmeldung geben</b-button>
        <b-modal
            id="modal-aufgabe-rueckmeldung"
            title="Rückmeldung zu Aufgabe"
            centered
            @ok="bearbeitung_rueckmeldung()"
            @show="resetModal()"
            modal-ok="Rückmeldung geben"
        >
            <div class="row mb-30">
                <div class="col-md-12">
                    <div class="form-group d-inline-block">
                        <label class="required">Auswahl</label>
                        <b-form-select
                            v-model="rueckmeldungsergebnis"
                            :options="options_rueckmeldungsergebnis"
                            id="rueckmeldungsergebnis"
                            name="rueckmeldungsergebnis"
                            autocomplete="off"
                            @change="change_options_rueckmeldungsergebnis"
                        ></b-form-select>
                    </div>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-md-12">
                    <label for="aufgabe_text" class="required">Kommentar</label>
                    <b-form-textarea
                        id="aufgabe_text"
                        v-model="aufgabe_text"
                        placeholder="Anmerkungen zur Bearbeitung"
                        rows="5"
                        max-rows="10"
                    ></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                    Abbrechen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Rückmeldung geben
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

    export default {
        props: ['signed_api_post_route_in', 'signed_back_to_aufgaben_in','id_aufgabe_in','eigene_in'],
        name: "AufgabenComponentAufgabeRueckmeldung",
        display: "AufgabenComponentAufgabeRueckmeldung",
        order: 0,
        components: {},
        data() {
            return {
                collapsed: true,
                neuer_mitarbeiter_typ: null,
                aufgabe_text: null,
                rueckmeldungsergebnis: 1,
                options_rueckmeldungsergebnis: [
                    { value: '1', text: 'Aufgabe erfolgreich bearbeitet' },
                    { value: '2', text: 'Bearbeitung der Aufgabe nicht möglich' },
                ],
            };
        },
        mounted() {
            this.$root.$on('ok', (bvEvent, modalId) => {
                alert('hier');
                this.modalOk();
            })
        },
        methods: {
            bearbeitung_rueckmeldung: function () {

                let url = this.signed_api_post_route_in;
                self = this;

                axios.post(url,{
                    id_aufgabe: this.id_aufgabe_in,
                    rueckmeldungsergebnis: this.rueckmeldungsergebnis,
                    text: this.aufgabe_text,
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Die Rückmeldung wurde erfolgreich übermittelt!",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                        window.location.href = self.signed_back_to_aufgaben_in;

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            change_options_rueckmeldungsergebnis: function(){},
            resetModal: function () {},
            cancel: function () {},
        }
    };
</script>
<style scoped>
</style>
