<template lang="pug">
  .row
    .col-6
      div(v-if="org_treedata.length || new_treedata.length")
        Tree(:data="org_treedata" draggable cross-tree)
          div(slot-scope="{data, store}")
            template(v-if="!data.isDragPlaceHolder")
              div(@click="editElement(data)")
                b(v-if="data.children && data.children.length" @click="store.toggleOpen(data)") {{data.open ? '-' : '+'}}&nbsp;
                span {{data.bezeichnung ? data.bezeichnung : data.name }}
                br
                div(v-if="data.verantwortungen && data.verantwortungen.length > 0")
                  div(v-for="(element, idx) in data.verantwortungen")
                    span.badge.badge-primary.ml-10(v-if="element") {{element.rangfolge}}. {{element.mitarbeiterfunktion.bezeichnung}}
        .mt-4
          button.btn.btn-primary(@click="collapseAll") {{ lang.get('default.btn_alle_einklappen') }}
          button.btn.btn-primary.ml-1(@click="expandAll") {{ lang.get('default.btn_alle_ausklappen') }}
      div(v-else)
        | {{ lang.get('admin.organisationselement_keine_eintraege') }}
    .col-6
      div.he-tree.sticky(v-if="edit_node")
        div.h5.ml-5.mt-10.mb-25
          | {{lang.get('admin.nav_tab_funktionen')}} {{ lang.get('admin.card_fuer') }}
          br
          | {{edit_node.bezeichnung ? edit_node.bezeichnung : edit_node.name}}
        div.scrollable
            draggable.list-group(tag="ul" v-bind="dragOptions" :list="verfuegbare_funktionen" handle=".handle" @end="onEnd")
              li.list-group-item(v-for="(element, idx) in verfuegbare_funktionen" :key="element.bezeichnung")
                div(v-if="element.checked")
                  i.fa.fa-align-justify.handle
                  span
                    input.form-check-input.ml-15.position-relative(type="checkbox" value="" :id="'checkbox_' + idx" :name="'checkbox_' + idx" @click="checkElement(element, idx)" checked="true")
                  label.form-check-label.ml-15.position-relative.text(:for="'checkbox_' + idx")
                    | {{element.bezeichnung}}
                    i.fa.fa-info-circle.ml-15(v-b-popover.hover.top-left="element.rollen" title="Berechtigungsrollen")
                  span.badge.badge-secondary.close
                    | {{element.rangfolge}}
                div(v-else)
                  i.fa.fa-align-justify.handle
                  span
                    input.form-check-input.ml-15.position-relative(type="checkbox" value="" :id="'checkbox_' + idx" :name="'checkbox_' + idx" @click="checkElement(element, idx)")
                  label.form-check-label.ml-15.position-relative.text(:for="'checkbox_' + idx")
                    | {{element.bezeichnung}}
                    i.fa.fa-info-circle.ml-15(v-b-popover.hover.top-left="element.rollen" title="Berechtigungsrollen")
      .row(v-if="!edit_node")
        .col-12
          .alert.alert-warning(role="alert")
            | {{lang.get('admin.alert_bitte_auswaehlen')}}
</template>

<script>
    //import Treeselect from '@riophae/vue-treeselect';

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import draggable from 'vuedraggable'

    export default {
        props: ['org_gruppe_id_in', 'org_treedata_in', 'locale_in'],
        components: {Tree: vueDraggableNestedTree.DraggableTree, draggable},
        data() {
            return {
                org_treedata: this.org_treedata_in,
                new_treedata: [],
                edit_node: null,
                edit_input: '',
                new_input: '',
                verfuegbare_funktionen: [],
                lang: null,
            }
        },
        computed: {
            dragOptions() {
                return {
                    sort: true,
                };
            },
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }

            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
            this.editElement(this.org_treedata[0]);
            this.org_treedata[0].draggable = false;
        },
        methods: {
            expandAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = true
                })
            },
            collapseAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = false
                })
            },
            onEnd: function (evt) {
                this.saveElement();
            },
            editElement(data) {
                this.edit_node = data;
                this.verfuegbare_funktionen = [];
                this.getAllFunctions();
            },
            getAllFunctions() {
                self = this;
                axios.get('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_get_alle_funktionen/' + this.edit_node.id)
                    .then(function (res) {
                        self.verfuegbare_funktionen =[];
                        for (let k in res.data) {
                            self.verfuegbare_funktionen.push({
                                rangfolge: res.data[k].rangfolge,
                                id_mitarbeiterfunktion: res.data[k].id_mitarbeiterfunktion,
                                bezeichnung: res.data[k].bezeichnung,
                                checked: res.data[k].checked,
                                rollen:res.data[k].rollen
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveElement() {
                let self = this;

                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_post_funktionen/' + this.edit_node.id, {
                    data_zugeordnet: JSON.stringify(this.verfuegbare_funktionen),
                })
                    .then(function (res) {
                        self.refreshTreeData();
                        self.getAllFunctions();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            checkElement(element, idx) {
                element.checked = document.getElementById('checkbox_' + idx).checked;
                this.saveElement();
            },
            refreshTreeData() {
                self = this;
                axios.get('/admin/org_elemente/gruppen/' + this.org_gruppe_id_in + '/api_get_refresh_tree/')
                    .then(function (res) {
                        self.org_treedata = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
    }
</script>

<style lang="scss">


    .he-tree {
        //border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;

        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;

        border-color: #eaecec;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 14px;
    }

    .tree-node {
    }

    .tree-node-inner {
        //padding: 5px;
        padding: .75rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: .25rem;
        cursor: pointer;
    }

    .draggable-placeholder {
    }

    .draggable-placeholder-inner {
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .edit_input_field {
        width: 80% !important;
        display: inline !important;
    }

    .list-group-item {
        border: 1px solid #ccc !important;
    }


    /*.tree3{

        .tree-node-inner{
            border: none;
            padding: 0px;
        }
        .tree-node-inner-back:hover{
            background: #ddd;
        }
    }*/

    /* .tree4{
         .tree-node-inner{
             border: none;
             border-bottom: 1px solid #ccc;
             padding: 5px 10px;
             backgroud: #ccc;
     }
         .draggable-placeholder-inner{
             background-color: orangered;
         }
     }*/

    .button {
        margin-top: 35px;
    }

    .handle {
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .close {
        float: right;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .text {
        color: #5e7d8a;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
    }

    .scrollable {
        overflow-style: auto !important;
        overflow: scroll !important;
        height:50vh !important;
    }
</style>
