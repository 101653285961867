<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-sm">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5 col-sm-12 pt-15 pb-15">
                                <span class="mt-5 mr-5 text-dark font-weight-500">Zeitraum von: </span>
                                <div>
                                    <b-form-input id="label" type="date" v-model="zeitraum_von"></b-form-input>
                                </div>
                            </div>
                            <div class="col-md-5 col-xs-12 pt-15 pb-15">
                                <span class="mt-5 mr-5 text-dark font-weight-500">Zeitraum bis: </span>
                                <div>
                                    <b-form-input id="label" type="date" v-model="zeitraum_bis"></b-form-input>
                                </div>
                            </div>
                            <div class="col-md-2 mt-40">
                                <b-button variant="primary" class="btn-sm" @click="getData">Anzeigen</b-button>
                                <b-button variant="primary" class="btn-sm" @click="exportReports">Export</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="kpis.length > 0 || kpis.summe" class="hk-sec-wrapper">
            <div class="hk-row">
                <div class="col-sm-12">
                    <div class="card-group hk-dash-type-2">
                        <div class="card card-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between mb-5">
                                    <div>
                                        <span class="d-block font-15 text-dark font-weight-500">Insgesamt</span>
                                    </div>
                                    <div v-if="kpis.length > 0 || kpis.summe">
                                    <span v-if="kpis.summe.prozent > 0" class="text-success font-14 font-weight-500"> +{{
                                            kpis.summe.prozent
                                        }}% </span>
                                        <span v-else-if="kpis.summe.prozent === 0"
                                              class="font-14 font-weight-500"> +{{ kpis.summe.prozent }}%</span>
                                        <span v-else-if="kpis.summe.prozent < 0"
                                              class="text-danger font-14 font-weight-500"> {{
                                                kpis.summe.prozent
                                            }}%</span>
                                    </div>
                                </div>
                                <div v-if="kpis.length > 0 || kpis.summe">
                                    <span class="d-block display-4 text-dark mb-5">{{ kpis.summe.jetzt }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="card card-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between mb-5">
                                    <div>
                                            <span
                                                class="d-block font-15 text-dark font-weight-500">Neu in Zeitraum</span>
                                    </div>
                                    <div v-if="kpis.length > 0 || kpis.neu">
                                    <span v-if="kpis.neu.prozent > 0"
                                          class="text-success font-14 font-weight-500"> +{{ kpis.neu.prozent }}% </span>
                                        <span v-else-if="kpis.neu.prozent === 0"
                                              class="font-14 font-weight-500"> +{{ kpis.neu.prozent }}%</span>
                                        <span v-else-if="kpis.neu.prozent < 0"
                                              class="text-danger font-14 font-weight-500"> {{
                                                kpis.neu.prozent
                                            }}%</span>
                                    </div>
                                </div>
                                <div v-if="kpis.length > 0 || kpis.neu">
                                    <span class="d-block display-4 text-dark mb-5">{{ kpis.neu.jetzt }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="card card-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between mb-5">
                                    <div>
                                        <span class="d-block font-15 text-dark font-weight-500">Erledigt</span>
                                    </div>
                                    <div v-if="kpis.length > 0 || kpis.erledigt">
                                    <span v-if="kpis.erledigt.prozent > 0" class="text-success font-14 font-weight-500"> +{{
                                            kpis.erledigt.prozent
                                        }}% </span>
                                        <span v-else-if="kpis.erledigt.prozent === 0"
                                              class="font-14 font-weight-500"> +{{ kpis.erledigt.prozent }}%</span>
                                        <span v-else-if="kpis.erledigt.prozent < 0"
                                              class="text-danger font-14 font-weight-500"> {{
                                                kpis.erledigt.prozent
                                            }}%</span>
                                    </div>
                                </div>
                                <div v-if="kpis.length > 0 || kpis.erledigt">
                                    <span class="d-block display-4 text-dark mb-5">{{ kpis.erledigt.jetzt }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="card card-sm">
                            <div class="card-body">
                                <div class="d-flex justify-content-between mb-5">
                                    <div>
                                    <span
                                        class="d-block font-15 text-dark font-weight-500">In Bearbeitung</span>
                                    </div>
                                    <div v-if="kpis.length > 0 || kpis.bearbeitung">
                                    <span v-if="kpis.bearbeitung.prozent > 0"
                                          class="text-success font-14 font-weight-500"> +{{
                                            kpis.bearbeitung.prozent
                                        }}% </span>
                                        <span v-else-if="kpis.bearbeitung.prozent === 0"
                                              class="font-14 font-weight-500"> +{{
                                                kpis.bearbeitung.prozent
                                            }}%</span>
                                        <span v-else-if="kpis.bearbeitung.prozent < 0"
                                              class="text-danger font-14 font-weight-500"> {{
                                                kpis.bearbeitung.prozent
                                            }}%</span>
                                    </div>
                                </div>
                                <div v-if="kpis.length > 0 || kpis.bearbeitung">
                                    <span class="d-block display-4 text-dark mb-5">{{ kpis.bearbeitung.jetzt }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="hk-sec-wrapper">
                <h6 class="hk-sec-title">Anzahl erfasster Meldungen</h6>
                <div class="row mt-35">
                    <div class="col-sm">
                        <v-chart
                            class="chart"
                            :option="area"
                            :autoresize="true"
                        />
                    </div>
                </div>
            </section>
            <section class="hk-sec-wrapper">
                <div class="row mb-15">
                    <div class="col-md-9"><h6 class="hk-sec-title">Auswertung der Formularinhalte</h6></div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-end">
                            <b-form-select v-model="show_antworten" :options="show_antworten_options"
                                           @change="getData"></b-form-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <div class="accordion accordion-type-2" id="accordion" v-for="(single_pie,index) in pie_data">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between">
                                    <a class="collapsed" role="button" data-toggle="collapse"
                                       :href="'#collapse_' + index"
                                       aria-expanded="false"><h6 class="text-primary mt-5">{{ single_pie.frage }}?</h6>
                                    </a>
                                </div>
                                <div class="card-body collapse" :id="'collapse_' + index"
                                     data-parent="#accordion"
                                     role="tabpanel">
                                    <div class="row" v-if="pie.length">
                                        <v-chart
                                            class="chart"
                                            :option="pie[index]"
                                            :autoresize="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-else>
            <div class="hk-sec-wrapper">
                <h6 class="hk-sec-title">Für den ausgewählten Zeitraum stehen <strong>keine Zahlen</strong> zur
                    Verfügung</h6>
                <div class="row mt-35">
                </div>
            </div>
        </template>

    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************
import ECharts from 'vue-echarts'
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {PieChart} from "echarts/charts";
import {GridComponent} from "echarts/components";
import {LineChart} from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from "echarts/components";

import VChart, {THEME_KEY} from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    LineChart
]);

import moment from 'moment'

export default {
    props: ['get_statistik_data_url_in', 'export_reports_url_in'],
    name: 'EMelderComponentStatistikShow',
    components: {'v-chart': ECharts, 'moment': moment},
    provide: {
        [THEME_KEY]: "macarons"
    },
    data() {
        return {
            area: {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    padding: 6,
                    axisPointer: {
                        lineStyle: {
                            width: 0,
                        }
                    },
                    textStyle: {
                        color: '#324148',
                        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                        fontSize: 12
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#5e7d8a'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#5e7d8a'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#eaecec',
                        }
                    }
                },
                grid: {
                    top: '3%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        symbolSize: 6,
                        lineStyle: {
                            color: '#00acf0',
                            width: 2,
                        },
                        itemStyle: {
                            color: '#00acf0',
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#59c4e6'
                                }, {
                                    offset: 1,
                                    color: '#cbb0e3'
                                }])
                            }
                        },
                    }
                ]
            },
            pie_template: {
                tooltip: {
                    show: true,
                    backgroundColor: '#fff',
                    borderRadius: 6,
                    padding: 6,
                    axisPointer: {
                        lineStyle: {
                            width: 0,
                        }
                    },
                    textStyle: {
                        color: '#324148',
                        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                        fontSize: 12
                    }
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '55%',
                        startAngle: 270,
                        color: ['#516b91', '#59c4e6', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3'],
                        data: [],
                        label: {
                            normal: {
                                formatter: '{b}\n{d}%'
                            },

                        }
                    }
                ]
            },
            pie: [],
            pie_data: null,
            kpis: {},
            lang: null,
            show_antworten: 15,
            show_antworten_options: [
                {value: 1, text: 'Top 1 Antworten'},
                {value: 5, text: 'Top 5 Antworten'},
                {value: 10, text: 'Top 10 Antworten'},
                {value: 15, text: 'Top 15 Antworten'},
                {value: 25, text: 'Top 25 Antworten'},
                {value: 50, text: 'Top 50 Antworten'},
            ],
            zeitraum_von: null,
            zeitraum_bis: null,
        }
    },
    setup() {
        const option = {};
        return {option};
    },
    computed: {},
    created() {
        var lang = new Lang();
        lang.setLocale(this.locale_in);
        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }

        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
        this.setStartDates();
        this.getData();
    },
    methods: {
        setStartDates() {
            let today = new Date();
            this.zeitraum_bis = today.toISOString().substr(0, 10);

            /*            var oneYearAgo = new Date(
                            new Date().getFullYear() - 1,
                            new Date().getMonth(),
                            1
                        );
                        this.zeitraum_von = oneYearAgo.toISOString().substr(0, 10);
            */

        },
        getData() {
            self = this;
            axios.post(this.get_statistik_data_url_in, {
                zeitraum_von: this.zeitraum_von,
                zeitraum_bis: this.zeitraum_bis,
                show_antworten: this.show_antworten,
            })
                .then(function (res) {
                    self.area.xAxis.data = res.data.areaLabels;
                    self.area.series[0].data = res.data.areaData;
                    self.kpis = res.data.kpis;
                    self.pie_data = res.data.pieData;
                    self.zeitraum_von = res.data.startDate.substr(0, 10);
                    self.processPieData();

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        exportReports() {
            axios.post(this.export_reports_url_in, {
                zeitraum_von: this.zeitraum_von,
                zeitraum_bis: this.zeitraum_bis,
                show_antworten: this.show_antworten,
            }, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },
        processPieData() {

            let self = this;
            self.pie = [];
            this.pie_data.map(function (item) {
                let template = _.cloneDeep(self.pie_template);
                template.series[0].data = [];
                template.series[0].data = _.cloneDeep(item.antworten);
                self.pie.push(template);
            });
        },
    },
}
</script>

<style>
.echarts {
    width: 100%;
}

.chart {
    height: 400px;
}
</style>
