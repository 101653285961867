<template>
    <div class="card card-refresh">
        <div class="card-header card-header-action">
            <h6>{{(parseInt(index_spalte) + 1)}}. Spalte</h6>
            <div class="d-flex align-items-center card-action-wrap">
                <a class="inline-block mr-15" data-toggle="collapse"
                   :href="'#collapse_' + index_spalte" aria-expanded="true">
                    <i class="zmdi zmdi-chevron-down"></i>
                </a>
                <div class="inline-block dropdown mr-15">
                    <a class="dropdown-toggle no-caret"
                       data-toggle="dropdown"
                       href="#" aria-expanded="false" role="button"><i
                        class="ion ion-md-more"></i></a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click="addSpalte()"><i
                            class="dropdown-icon zmdi zmdi-plus-square"></i><span>Spalte hinzufügen</span></a>
                        <a class="dropdown-item" href="#" @click="removeSpalte()"><i
                            class="dropdown-icon zmdi zmdi-minus-square"></i><span>Spalte entfernen</span></a>
                    </div>
                </div>
                <a class="inline-block card-close" href="#"
                   data-effect="fadeOut" @click="removeSpalte()">
                    <i class="ion ion-md-close"></i>
                </a>
            </div>
        </div>
        <div :id="'collapse_' + index_spalte" class="collapse show">
            <div class="card-body">
                <div class="card-title d-inline-flex w-100">
                    <h5 class="w-75">{{spalte.baustein_titel}}</h5>
                    <span v-if="spalte.required" class="text-danger text-right w-25">[Erforderlich]</span>
                </div>
                <h6 class="card-text text-primary">{{spalte.label}}</h6>
                <p v-if="spalte.id_baustein_typ == 9 && auswahlliste">{{auswahlliste.text}}</p>
                <p v-else-if="spalte.id_baustein_typ == 10 && auswahlskala">{{auswahlskala.text}}</p>
                <p v-else>{{spalte.description}}</p>
                <a href="#" class="btn btn-primary mt-15" @click="showEditSpalte">auswählen</a>
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue'

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    //import chatElementRightMessage from './EMelderComponentMeldungenChatRightMessage';

    // ***********  Localization ***************

    export default {
        props: ['spalte', 'index_zeile','index_spalte'],
        components: {},
        data() {
            return {
                lang: null,
                api_einstellungen_meldungsvorlagen_layout_get_auswahlliste : null,
                api_einstellungen_meldungsvorlagen_layout_get_auswahlskala : null,
                api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen : null,
                auswahlliste: null,
                auswahlskala: null,
                kontrollkaestchen: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
            this.changeAuswahlliste();
            this.changeAuswahlskala();
            this.changeKontrollkaestchen();
        },
        mounted() {
        },
        watch: {
            spalte: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    this.changeAuswahlliste();
                    this.changeAuswahlskala();
                    this.changeKontrollkaestchen();
                }
            }
        },
        computed: {},
        methods: {
            addSpalte() {
                this.$eventBus.$emit('emelder_meldungsvorlage:add_Spalte', {
                    index_zeile: this.index_zeile,
                    index_spalte: this.index_spalte
                })
            },
            removeSpalte() {
                this.$eventBus.$emit('emelder_meldungsvorlage:remove_Spalte', {
                    index_zeile : this.index_zeile,
                    index_spalte : this.index_spalte
                })
            },
            showEditSpalte() {
                this.$eventBus.$emit('emelder_meldungsvorlage:edit_Spalte', {
                    index_zeile : this.index_zeile,
                    index_spalte : this.index_spalte,
                    kontrollkaestchen : this.kontrollkaestchen
                })
            },
            getAuswahlliste(idAuswahlliste){
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_layout_get_auswahlliste,{
                    id_auswahlliste: idAuswahlliste,
                })
                .then(function (res) {
                    self.auswahlliste = res.data.auswahlliste;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            changeAuswahlliste(){
                if(this.spalte.id_baustein_typ === 9){
                    this.api_einstellungen_meldungsvorlagen_layout_get_auswahlliste = Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_auswahlliste;
                    this.getAuswahlliste(this.spalte.option1);
                }
            },
            getAuswahlskala(idAuswahlskala){
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_layout_get_auswahlskala,{
                    id_auswahlskala: idAuswahlskala,
                })
                    .then(function (res) {
                        self.auswahlskala = res.data.auswahlskala;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            changeAuswahlskala(){
                if(this.spalte.id_baustein_typ === 10){
                    this.api_einstellungen_meldungsvorlagen_layout_get_auswahlskala = Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_auswahlskala;
                    this.getAuswahlskala(this.spalte.option3);
                }
            },
            changeKontrollkaestchen(){
                if(this.spalte.id_baustein_typ === 11){
                    this.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen = Vue.prototype.$api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen;
                    this.getKontrollkaestchen(this.spalte.option2);
                }
            },
            getKontrollkaestchen(idKontrollkaestchen){
                let self = this;
                axios.post(this.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen,{
                    id_kontrollkaestchen: idKontrollkaestchen,
                })
                    .then(function (res) {
                        self.kontrollkaestchen = res.data.kontrollkaestchen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    };
</script>
<style scoped>
</style>
