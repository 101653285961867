<template>
    <div>
        <a id="close_fmapp_sidebar" href="javascript:void(0)" class="close-fmapp-sidebar">
            <span class="feather-icon"><i data-feather="chevron-left"></i></span>
        </a>
        <input type="text" class="form-control form-control-sm" name="volltextsuche"
               placeholder="Volltextsuche" @keyup="InputVolltextsuche()" v-model="volltextsuche">
        <hr>

        <div class="row mb-10">
            <button class="btn btn-sm btn-primary ml-auto mr-auto" @click="resetStoreData()">
                Reset Filter
            </button>
        </div>

        <select v-if="org_gruppe_data.length" name="org_gruppe" id="org_gruppe"
                class="form-control form-control-sm mb-10" @change="triggerNewOrgGruppe" v-model="org_gruppe">
            <option value="" selected hidden disabled> - Organisationsgruppe -</option>
            <option value="all"> Alle</option>
            <option v-for="single_org_gruppe in org_gruppe_data" :value="single_org_gruppe.id">
                {{ single_org_gruppe.bezeichnung }}
            </option>
        </select>
        <div class="tree-style">
            <tree
                :data="org_tree_data"
                :options="treeOptions"
                ref="org_tree_view"
                @node:clicked="compute_nodes_selected"
            />

            <!-- @node:unselected="compute_nodes_unselected"  -->
        </div>
        <select v-if="dokumentenart_data.length" name="dokumentenart" id="dokumentenart"
                class="form-control form-control-sm mb-10 mt-25" v-model="dokumentenart" @change="triggerSearchEvent()">
            <option value="" selected> - Dokumentenart -</option>
            <option v-for="dokumentenart in dokumentenart_data" :value="dokumentenart.id">
                {{ dokumentenart.bezeichnung }}
            </option>
        </select>
        <select name="status" id="status" class="form-control form-control-sm mb-10" v-model="status"
                @change="triggerSearchEvent()">
            <option value="" selected> - Status -</option>
            <option value="0.01">Entwurf</option>
            <option value="1.00">Lenkung</option>
            <option value="2.00">Freigabe</option>
            <option value="3.00">Prüfung</option>
            <option value="4.00">Archiv</option>
            <option value="5.00">Revision</option>
            <option value="10.00">Veröffentlicht</option>
            <option value="11.00">Privat</option>
        </select>
        <select v-if="redakteur_data != ''" name="redakteur" id="redakteur"
                class="form-control form-control-sm mb-10 mt-25" @change="triggerSearchEvent()" v-model="redakteur">
            <option value="" selected> - Redakteur -</option>
            <option v-for="single_redakteur in redakteur_data" :value="single_redakteur.id">
                {{ single_redakteur.user_data.anrede ? single_redakteur.user_data.anrede.bezeichnung : '' }}
                {{ single_redakteur.user_data.titel ? single_redakteur.user_data.titel.bezeichnung : '' }}
                {{ single_redakteur.user_data.nachname }}, {{ single_redakteur.user_data.vorname }}
            </option>
        </select>
        <div class="row mt-25">
            <div class="col-3">
                <toggle-button v-model="switch_eigene" :sync="true" @change="toggleSearch('switch_eigene')"
                               color="#00acf0" :value="switch_eigene"/>
            </div>
            <div class="col-9">
                Nur eigene Dokumente
            </div>
        </div>
        <div class="row mt-10">
            <div class="col-3">
                <toggle-button v-model="switch_nur_hgb" :sync="true" @change="toggleSearch('switch_nur_hgb')"
                               color="#00acf0" :value="switch_nur_hgb"/>
            </div>
            <div class="col-9">
                Nur Hauptgeltende
            </div>
        </div>
        <div class="row mt-10">
            <div class="col-3">
                <toggle-button v-model="switch_fuehrende" :sync="true" @change="toggleSearch('switch_fuehrende')"
                               color="#00acf0" :value="switch_fuehrende"/>
            </div>
            <div class="col-9">
                Nur führende
            </div>
        </div>
        <div class="row mt-10">
            <div class="col-3">
                <toggle-button v-model="switch_urlaubsfenster" :sync="true"
                               @change="toggleSearch('switch_urlaubsfenster')"
                               color="#00acf0" :value="switch_urlaubsfenster"/>
            </div>
            <div class="col-9">
                Neue Dokumente <div class="small">(letzte 30 Tage)</div>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../vue-translations.js';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************


export default {
    props: ['org_gruppen_in', 'dokumentenarten_in', 'page_in', 'org_gruppen_selected_in'],
    components: {},
    data() {
        return {
            volltextsuche: null,
            org_gruppe_data: this.org_gruppen_in,
            org_gruppe: "",
            org_element: "",
            dokumentenart_data: this.dokumentenarten_in,
            dokumentenart: "",
            status: "",
            funktion_data: this.funktionen_in,
            funktion: "",
            redakteur_data: "",
            redakteur: "",
            switch_eigene: false,
            switch_nur_hgb: false,
            switch_fuehrende: false,
            switch_urlaubsfenster: false,
            page: this.page_in,
            lang: null,
            treeOptions: {
                multiple: false,
                propertyNames: {
                    'text': 'bezeichnung',
                    'children': 'children'
                },
                autoCheckChildren: false,
                parentSelect: false,
                checkbox: false,
                dnd: false,
            },
            org_tree_data: [],
            debug: false,
        }
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
        this.getStoreData();

        this.org_gruppe = (this.org_gruppe) ? this.org_gruppe : this.org_gruppen_in[0].id;

        this.get_org_elemente_by_org_gruppe(this.org_gruppe, true);

        self = this;
        setTimeout(function () {
            self.select_node(self.$store.$store.state.search_dokman.org_element);
            self.triggerSearchEvent()
        }, 300);
    },
    beforeCreate() {
        this.$store.$store.commit('restoreQueryDokman');
    },
    watch: {},
    computed: {},
    methods: {
        InputVolltextsuche() {
            if (this.volltextsuche.length > 1 || this.volltextsuche == '') {
                this.triggerSearchEvent();
            }
        },
        getRedakteure() {
            let self = this;
            axios.get('/dokman/mein_bereich/api/' + self.org_gruppe + '/get_redakteure/')
                .then(function (res) {
                    self.redakteur_data = res.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        triggerSearchEvent() {

            if (this.debug)
                console.log('triggerSearchEvent')
            if (this.debug)
                console.log('Trigger:' + this.org_element)

            let volltextsuche_str = this.$route.query.volltextsuche ? this.$route.query.volltextsuche : null;

            if (volltextsuche_str != null) {
                this.volltextsuche = volltextsuche_str;
                this.org_gruppe = "all"
            }


            this.$eventBus.$emit('searchQuery', {
                volltextsuche: volltextsuche_str == null ? this.volltextsuche : volltextsuche_str,
                org_gruppe: this.org_gruppe,
                org_element: this.org_element,
                org_element_objekt: this.org_element_objekt,
                dokumentenart: this.dokumentenart,
                status: this.status,
                redakteur: this.redakteur,
                switch_eigene: this.switch_eigene,
                switch_nur_hgb: this.switch_nur_hgb,
                switch_fuehrende: this.switch_fuehrende,
                switch_urlaubsfenster: this.switch_urlaubsfenster,
                page: this.page,
            });

            this.$route.query.volltextsuche = null
        },
        triggerNewOrgGruppe() {
            this.org_element = null;
            this.get_org_elemente_by_org_gruppe(this.org_gruppe);
            this.triggerSearchEvent();
        },
        toggleSearch(value) {
            this.triggerSearchEvent();
        },
        select_node(org_element) {

            if (this.debug) {
                console.log('select_node - Start');
                console.log('Store:' + this.$store.$store.state);
                console.log('org_element:' + this.org_element);
                console.log('local:' + org_element);
                console.log('select_node - End');
            }

            if (org_element != null) {
                const nodes_all = this.$refs['org_tree_view'].findAll({});

                let self = this;
                nodes_all.forEach(single_node => {
                    //document.querySelector('[data-id="'+single_node.id+'"]').classList.add("selected");

                    if (single_node.id == org_element) {

                        if (self.debug) {
                            console.log('Condition: single_node.id == org_element');
                            console.log('Single_node.id:' + single_node.id);
                            console.log('org_element:' + org_element);
                            console.log('');
                            console.log(single_node);
                        }

                        single_node.select();
                        self.node_expand_parent(single_node);
                    }
                });
            }
        },
        node_expand_parent(node) {

            if (this.debug) {
                console.log('node_expand_parent: ');
                console.log(node);
            }

            if (node.parent) {
                node.parent.expand();
                this.node_expand_parent(node.parent);
            }
        },
        compute_nodes_selected(node) {

            if (this.debug) {
                console.log('node_selected: ');
            }

            const nodes_all = this.$refs['org_tree_view'].findAll({});

            nodes_all.forEach(single_node => {

                // Prüfe ob der ausgewählte node und der iterierte node identisch sind.
                // Außerdem prüfe ob select oder unselect

                if (single_node.id == node.id && (this.org_element == null || this.org_element != node.id)) {

                    if (this.debug) {
                        console.log("select");
                    }
                    document.querySelector('[data-id="' + single_node.id + '"]').classList.add("selected");
                    this.org_element = node.id;
                } else if (single_node.id == node.id && this.org_element == node.id) {

                    if (this.debug) {
                        console.log("unselect");
                    }
                    single_node.unselect();
                    this.$refs['org_tree_view'].selected().unselect();
                    document.querySelector('[data-id="' + single_node.id + '"]').classList.remove("selected");
                    this.org_element = null;
                }
            });
            let self = this;
            setTimeout(function () {
                self.triggerSearchEvent();
            }, 200);
        },
        getStoreData() {
            this.dokumentenart = this.$store.$store.state.search_dokman.dokumentenart;
            this.org_element = this.$store.$store.state.search_dokman.org_element;
            this.org_element_objekt = this.$store.$store.state.search_dokman.org_element_objekt;

            if (this.$store.$store.state.search_dokman.org_gruppe) {
                this.org_gruppe = this.$store.$store.state.search_dokman.org_gruppe;
            }

            this.page = this.$store.$store.state.search_dokman.page;
            this.redakteur = this.$store.$store.state.search_dokman.redakteur;
            this.status = this.$store.$store.state.search_dokman.status;
            this.switch_eigene = this.$store.$store.state.search_dokman.switch_eigene;
            this.switch_fuehrende = this.$store.$store.state.search_dokman.switch_fuehrende;
            this.switch_nur_hgb = this.$store.$store.state.search_dokman.switch_nur_hgb;
            this.switch_urlaubsfenster = this.$store.$store.state.search_dokman.switch_urlaubsfenster;
            this.volltextsuche = this.$store.$store.state.search_dokman.volltextsuche;

            if (this.debug) {
                console.log('getStoreData - Start');
                console.log(this.$store.$store.state);
                console.log(this.org_element);
                console.log('getStoreData - End');
            }

        },
        resetStoreData() {
            this.dokumentenart = "";
            if (document.querySelector('[data-id="' + this.org_element + '"]') != null)
                document.querySelector('[data-id="' + this.org_element + '"]').classList.remove("selected");
            this.org_element = "";
            this.org_element_objekt = "";

            if (this.$store.$store.state.search_dokman.org_gruppe) {
                this.org_gruppe = this.$store.$store.state.search_dokman.org_gruppe;
            }

            this.page = null;
            this.redakteur = "";
            this.status = "";
            this.switch_eigene = false;
            this.switch_fuehrende = false;
            this.switch_nur_hgb = false;
            this.switch_urlaubsfenster = false;
            this.volltextsuche = null;

            this.$store.$store.commit('resetQueryDataDokman');

            this.$swal({
                title: 'Erfolg',
                text: "Ihre Filterung wurden zurückgesetzt",
                type: 'success',
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000
            });

            this.triggerSearchEvent();
        },
        get_org_elemente_by_org_gruppe(geltung_key, start) {

            let id_org_element_gruppe = this.org_gruppe;
            this.org_tree_data.id_org_element_selected = [];

            self = this;

            if (!this.$route.query.volltextsuche && geltung_key !== 'all') {

                axios.get('/dokman/mein_bereich/api/' + id_org_element_gruppe + '/api_get_org_elemente_with_parent/')
                    .then(function (res) {
                        self.org_tree_data.org_element_options = res.data[0]['children'];

                        // Kürze Bezeichnung auf 40 Zeichen
                        self.org_tree_data.org_element_options.forEach((item, index) => {
                            self.org_tree_data.org_element_options[index].bezeichnung = self.trim_org_element_bezeichnung(item.bezeichnung)
                        });
                        self.$refs['org_tree_view'].setModel(self.org_tree_data.org_element_options);

                        if (!start) {
                            self.org_element = "";
                        }
                        self.getRedakteure();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                self.$refs['org_tree_view'].setModel(null);
            }
        },
        trim_org_element_bezeichnung(bezeichnung) {

            if (bezeichnung.length > 38) {
                return bezeichnung.substr(0, 38) + " [..]";
            }
            return bezeichnung;
        },
    }
}
;
</script>
<style>

.tree-style {
    display: inline-block;
    width: 100%;
    padding: 0 !important;
    line-height: 1.5;
    color: #324148 !important;
    vertical-align: middle;
    background: #fff;
    background-size: 8px 10px;
    border: 1px solid #eaecec !important;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 0.875rem;
    height: 350px !important;
    overflow-x: hidden !important;
}

.tree-node {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
}

.tree-node.selected > .tree-content > .tree-anchor {
    background: #00acf0 !important;
    color: #FFF !important;
    opacity: 0.8 !important;
}

.tree-content {
    display: flex;
    align-items: center;
    padding: 1px !important;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.tree-node:not(.selected) > .tree-content:hover {
    background: #f6f8fb;
}

.tree-node.selected > .tree-content {
    background-color: #e7eef7;
}

.tree-node.disabled > .tree-content:hover {
    background: inherit;
}

.tree-arrow {
    flex-shrink: 0;
    height: 15px !important;
    cursor: pointer;
    margin-left: 15px !important;
    width: 0;
    padding-left: 17px !important;
}

.tree-arrow.has-child {
    margin-left: 0;
    width: 7px !important;
    position: relative;
    padding-left: -17px !important;
}

.tree-arrow.has-child:after {
    border: 1.5px solid #494646;
    position: absolute;
    border-left: 0;
    border-top: 0;
    left: 5px !important;
    top: 50%;
    height: 7px !important;
    width: 7px !important;
    transform: rotate(-45deg) translateY(-50%) translateX(0);
    transition: transform .25s;
    transform-origin: center;
}

.tree-arrow.has-child.rtl:after {
    border: 1.5px solid #494646;
    position: absolute;
    border-right: 0;
    border-bottom: 0;
    right: 0px;
    top: 50%;
    height: 7px !important;
    width: 7px !important;
    transform: rotate(-45deg) translateY(-50%) translateX(0);
    transition: transform .25s;
    transform-origin: center;
}

.tree-children {
    margin-left: 15px !important;
}

.tree > .tree-filter-empty, .tree > .tree-root {
    margin-left: -15px !important;
}


.tree-anchor {
    flex-grow: 2;
    outline: 0;
    display: flex;
    text-decoration: none;
    color: #343434;
    vertical-align: top;
    margin-left: -5px !important;
    line-height: 15px !important;
    padding: 3px 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tree-content {
    display: flex;
    align-items: center;
    padding: 0px !important;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
}

.tree-checkbox {
    flex-shrink: 0;
    position: relative;
    width: 20px !important;
    height: 20px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: .25rem !important;
    background: #fff;
    transition: border-color .25s, background-color .25s;
}

.tree-checkbox.checked:after {
    transform: rotate(
        45deg
    ) scaleY(1);
}

.tree-checkbox.checked:after {
    box-sizing: content-box;;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 3.5px !important;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 3.5px !important;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-left: 0;
    border-top: 0;
    left: 5px !important;
    top: 1px !important;
    height: 8px !important;
    width: 4px !important;
    transform: rotate(
        45deg
    ) scaleY(0);
    transition: transform .25s;
    transform-origin: center;
}

.tree-node.selected > .tree-content {
    background: #fff !important;
}

.tree-node.selected > .tree-content > .tree-anchor {
    color: #495057 !important;
}

.tree-content {
    padding: 2px 0;
}

.tree-scope {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

</style>
