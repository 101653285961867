<template>
    <div class="row">
        <div v-if="kopfzeile"  class="card emelder_einstellungen_card_zeile">
            <div>
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            {{kopfzeile}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card emelder_einstellungen_card_zeile">
            <div id="collapse_zeile_1" class="collapse show">
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            <MeldenFormular
                            :api_get_grid_url_in="api_einstellungen_meldungsvorlagen_vorschau_show_in"
                            :api_get_org_element_gruppen_in="api_get_org_element_gruppen_in"
                            :api_get_org_elemente_in="api_get_org_elemente_in"
                            :api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in="api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in"
                            :api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in="api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in"
                            ></MeldenFormular>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fusszeile"  class="card emelder_einstellungen_card_zeile">
            <div>
                <div class="card-body">
                    <div class="col-sm">
                        <div class="row">
                            {{fusszeile}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    import MeldenFormular from '../../../melden/EMelderComponentMeldenFormular';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['api_einstellungen_meldungsvorlagen_vorschau_show_in','api_einstellungen_meldungsvorlagen_kopfzeile_show_in','api_einstellungen_meldungsvorlagen_fusszeile_show_in','api_get_org_element_gruppen_in','api_get_org_elemente_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in','api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in'],
        components: {MeldenFormular},
        data() {
            return {
                lang: null,
                kopfzeile: null,
                fusszeile: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.getInput();
        },
        mounted() {
        },
        watch: {},
        computed: {
        },
        methods: {
            getInput() {
                let self = this;
                axios.get(this.api_einstellungen_meldungsvorlagen_kopfzeile_show_in, {})
                    .then(function (res) {
                        self.kopfzeile = res.data.kopfzeile;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                axios.get(this.api_einstellungen_meldungsvorlagen_fusszeile_show_in, {})
                    .then(function (res) {
                        self.fusszeile = res.data.fusszeile;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    };
</script>
<style scoped>

    .right_page {
        width: 100%;
    }

    .chat-body {
        height: calc(100vh - 458px) !important;
    }

    .emelder_einstellungen_card_zeile {
        width: 100% !important;
        border-left-color: #00acf0 !important;
        border-left-width: 15px !important;
    }

</style>
