<template>
    <div class="chatapp-right" style="height:100% !important;">
        <div class="hk-pg-header align-items-top ml-20" style="margin-bottom: 0px !important;">
            <div>
                <h2 class="hk-pg-title font-weight-600 mt-15 mb-10">Kommunikation</h2>
                <p>Sie haben Fragen zum Thema Chat? <a
                    href="/support">Hilfe</a></p>
            </div>
        </div>
        <header>
            <div class="d-flex">
                <div :class="'emelder_status ' + meldung_obj.status_css ">
                    <div class="emelder_status_letter">{{meldung_obj.status_txt_short}}</div>
                </div>
                <span class="text-dark ml-15"><h5
                    class="">{{meldung_obj.vorlage_bezeichnung}}</h5> (Ticket: {{meldung_obj.ticket_id}})</span>
                <br>
            </div>
            <div class="mr-30">
            <span
                class="text-success">Frist bis zum: {{meldung_obj.frist}}</span><br>
                <span
                    class="text-dark">Erstellt am: {{meldung_obj.erstellt}}</span><br>
            </div>
        </header>
        <div class="chat-body">
            <div class="nicescroll-bar">
                <ul class="chat-body list-unstyled" id="message-list">
                    <li v-for="message in messages" class="media" :class="message.type === 'sent' ? 'sent justify-content-end' : 'received'">
                        <chatElementRightMessage
                            :message="message"
                        >
                        </chatElementRightMessage>
                    </li>
                </ul>
            </div>
        </div>
        <footer>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="btn-file  btn btn-light">
                        <div class="btn-icon-wrap">
                            <span class="feather-icon"><i data-feather="paperclip"></i></span>
                            <input type="file">
                        </div>
                    </div>
                </div>
                <input type="text" id="input_msg_send_chatapp" name="send-msg"
                       class="input-msg-send form-control" placeholder="Nachricht schreiben" v-model="text"
                       v-on:keyup.enter="onEnter">
                <div class="input-group-append">
                    <button id="send_button" type="button"
                            class="btn btn-light dropdown-toggle no-caret" @click="postMessage"
                    > <span class="feather-icon"><i data-feather="arrow-right-circle"></i></span></button>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    import chatElementRightMessage from './EMelderComponentMeldungenChatRightMessage';

    // ***********  Localization ***************

    export default {
        props: ['chat','meldung'],
        components: {chatElementRightMessage},
        data() {
            return {
                meldung_obj: JSON.parse(this.meldung),
                messages: JSON.parse(this.chat),
                lang: null,
                text: '',
            };
        },
        created() {
            var lang = new Lang();

            console.log(this.meldung);

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {

        },
        watch: {},
        computed: {},
        methods: {
            postMessage: function () {
                    axios.post('/emelder/meldungen/api/message/' + this.meldung_obj.id, {message: this.text}).then(({data}) => {
                        this.text = '';
                       // this.getMessages();
                        location.reload();
                    })
            },
            onEnter: function () {
                this.postMessage();
            },

        }
    };
</script>
<style scoped>
    .chat-body{
        height: calc(100vh - 400px) !important;
    }

    .chatapp-wrap .chatapp-right {
        -webkit-box-flex: 0;
        flex: 0 0 70%;
        max-width: 70%;
    }

    #message-list {
        display: list-item;
        height: calc(100vh - 220px);
        overflow: auto;
    }
</style>
