<template>
    <div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" name="count" :value="objectCount(freigeber)">
                <div v-if="objectCount(freigeber) > 0">
                    <div class="card">
                        <div class="card-header card-header-action">
                            <h6>Freigabeprozess</h6>
                            <div class="d-flex align-items-center card-action-wrap">
                            </div>
                        </div>
                        <div class="list-group-item" v-for="freigabeschritt,key_freigabeschritt in freigeber">
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-inline-block float-left">
                                        {{get_freigabeschritt(key_freigabeschritt).rangfolge + 1}}. Schritt:
                                        {{get_freigabeschritt(key_freigabeschritt).name}}
                                    </div>
                                    <div class="d-inline-block float-right">
                                        <span v-html="get_freigabeschritt_status(get_freigabeschritt(key_freigabeschritt)).badge"></span>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">

                                            <span v-if="get_freigabeschritt(key_freigabeschritt).status == 1"
                                                  class="text-danger">Warte auf Auslösung <a href="" @click.prevent><button class="btn btn-xs btn-primary ml-30" @click="post_manuell_ausloesen">Manuell auslösen</button></a></span>
                                            <span v-else-if="!get_freigabeschritt(key_freigabeschritt).erledigt_am">Frist bis: {{get_freigabeschritt(key_freigabeschritt).frist}}</span>
                                            <span v-else>Erledigt am: {{get_freigabeschritt(key_freigabeschritt).erledigt_am}}</span>
                                            <br>
                                            Min. positive Freigaben:
                                            {{get_freigabeschritt(key_freigabeschritt).min_anzahl_freigaben}}
                                        </div>
                                    </div>
                                    <div class="row" v-for="geltung,key_geltung in freigabeschritt">
                                        <div class="col-md-12">
                                            <div v-if="get_geltung( key_freigabeschritt, key_geltung).bezeichnung"
                                                 class="mt-15 mb-10 pt-5 pl-5 pr-5 pb-5 bg-secondary text-white">
                                                <strong>Geltungsbereich : {{get_geltung( key_freigabeschritt,
                                                    key_geltung).bezeichnung != null? get_geltung( key_freigabeschritt,
                                                    key_geltung).bezeichnung : 'Verantwortliche Personen'}}</strong>
                                            </div>
                                            <div class="row"
                                                 v-for="freigeber_beziehung,key_freigeber_beziehung in geltung">
                                                <div class="col-md-12">
                                                    <div>
                                                        <span class="d-block font-14 font-weight-500 text-dark text-uppercase">{{get_freigeber_beziehung( key_freigabeschritt, key_geltung, key_freigeber_beziehung).bezeichnung}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Pflicht</span>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Status</span>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Frist / Erledigung</span>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Optionen</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="col-md-12">
                                                    <div v-for="single_freigeber,key_freigeber in freigeber_beziehung">
                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <span class="text-info">
                                                                    <span v-if="single_freigeber.user.user_data_limited.anrede">{{single_freigeber.user.user_data_limited.anrede.bezeichnung}}</span>
                                                                    <span v-if="single_freigeber.user.user_data_limited.titel">{{single_freigeber.user.user_data_limited.titel.bezeichnung}}</span>
                                                                    {{single_freigeber.user.user_data_limited.nachname}},  {{single_freigeber.user.user_data_limited.vorname}}</span>
                                                            </div>
                                                            <div class="col-md-1">
                                                                <span class=""><input type="checkbox"
                                                                                      name="pflichtteilnahme"
                                                                                      :checked="single_freigeber.pflichtteilnahme ? true : false"
                                                                                      disabled="disabled"></span>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <span v-html="get_freigeber_status(single_freigeber).badge"></span>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <span v-if="single_freigeber.status == 10">
                                                                    {{single_freigeber.updated_at}}
                                                                </span>
                                                                <span v-else> {{parseDate(single_freigeber.aufgabe)}}</span>

                                                            </div>
                                                            <div class="col-md-2">
                                                                <span v-if="single_freigeber.aufgabe && ( get_freigeber_status(single_freigeber).id == 2 || get_freigeber_status(single_freigeber).id == 3 || get_freigeber_status(single_freigeber).id == 4)">
                                                                    <a href="javascript:void(0);" v-b-tooltip.hover
                                                                       title="Erinnerung senden"
                                                                       @click="post_sende_erinnerung(single_freigeber.id)">
                                                                    <strong><i
                                                                            class="zmdi zmdi-alarm font-20 mr-10 text-light-40"></i></strong></a>
                                                                </span>
                                                                <span v-else-if="single_freigeber.aufgabe && get_freigeber_status(single_freigeber).id == 9">
                                                                    <a href="javascript:void(0);" v-b-tooltip.hover
                                                                       title="Trotzdem freigeben"
                                                                       @click="post_trotzdem_freigeben(single_freigeber)">
                                                                    <strong><i
                                                                            class="zmdi zmdi-replay font-20 mr-10 text-light-40"></i></strong></a>
                                                                </span>
                                                                <span v-else>-</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mt-10 mb-10">
                                                <div class="mt-10 mb-10">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else="objectCount(freigeber) == 0">
                    <div class="alert alert-info mb-15 mb-15 w-100">Keine Schritte vorhanden</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dokument_in', 'freigeber_in', 'signed_api_post_sende_erinnerung_in','signed_api_post_ueberschreibe_freigabe_in'],
        name: "FreigabeFreigabe",
        display: "FreigabeFreigabe",
        order: 0,
        components: {},
        data() {
            return {
                collapsed: true,

                dokument: this.dokument_in,
                freigeber: this.freigeber_in,
            };
        },
        mounted() {
        },
        computed: {},
        methods: {
            objectCount: function (obj) {
                return Object.keys(obj).length;
            },
            objectFirstElement: function (obj) {
                return Object.keys(obj)[0];
            },
            get_freigabeschritt: function (key_freigabe_schritt) {
                let id_freigabeschritt = key_freigabe_schritt;
                let id_geltung = this.objectFirstElement(this.freigeber[id_freigabeschritt]);
                let id_freigeber_beziehung = this.objectFirstElement(this.freigeber[id_freigabeschritt][id_geltung]);
                let freigabeschritt = this.freigeber[id_freigabeschritt][id_geltung][id_freigeber_beziehung][0].freigabeschritt;
                return freigabeschritt;
            },
            get_geltung: function (key_freigabe_schritt, key_geltung) {
                let id_freigabeschritt = key_freigabe_schritt;
                let id_geltung = key_geltung;
                let id_freigeber_beziehung = this.objectFirstElement(this.freigeber[id_freigabeschritt][id_geltung]);
                let geltung = this.freigeber[id_freigabeschritt][id_geltung][id_freigeber_beziehung][0].geltung;
                return geltung;
            },
            get_freigeber_beziehung: function (key_freigabe_schritt, key_geltung, key_freigeber_beziehung) {
                let id_freigabeschritt = key_freigabe_schritt;
                let id_geltung = key_geltung;
                let id_freigeber_beziehung = key_freigeber_beziehung;
                let freigeber_beziehung = this.freigeber[id_freigabeschritt][id_geltung][id_freigeber_beziehung][0].freigabeschritt_freigeber_beziehungstyp;
                return freigeber_beziehung;
            },
            get_freigeber_aufgabe: function (key_freigabe_schritt, key_geltung, key_freigeber_beziehung) {
                let id_freigabeschritt = key_freigabe_schritt;
                let id_geltung = key_geltung;
                let id_freigeber_beziehung = key_freigeber_beziehung;
                let freigeber_aufgabe = this.freigeber[id_freigabeschritt][id_geltung][id_freigeber_beziehung][0].aufgabe;
                return freigeber_aufgabe;
            },
            get_freigabeschritt_status: function (freigabeschritt) {

                //  0 : Gelöscht / Archiviert
                //  1 : Erstellt
                //  2 : Ausgelöst
                // 10 : Erledigt

                let status;

                switch (freigabeschritt.status) {
                   case 0:
                     status = {
                                'badge' : '<span class="badge badge-danger">gelöscht</span>',
                                'text':'gelöscht',
                                'css' : 'danger'
                            };
                        break;

                    case 1:
                        status = {
                            'badge' : '<span class="badge badge-info">wartet auf Auslösung</span>',
                            'text':'wartet auf Auslösung',
                            'css' : 'info'
                        };
                        break;

                    case 2:
                        status = {
                            'badge' : '<span class="badge badge-warning">ausgelöst</span>',
                            'text':'ausgelöst',
                            'css' : 'warning'
                        };

                        break;


                    case 10:
                        status = {
                                'badge' : '<span class="badge badge-success">erledigt</span>',
                                'text':'erledigt',
                                'css' : 'success'
                            };
                        break;
                }

                return status
            },
            get_freigeber_status: function (freigeber) {

                // Status 0  -> Aufgabe gelöscht / storniert
                // Status 1  -> Freigabe gestartet - Noch keine Aufgabe erstellt
                // Status 2  -> Freigabe gestartet - Aufgabe erstellt
                // Status 3  -> Freigabe gestartet - Aufgabe erstellt - Alarmierung vor Fristende
                // Status 4  -> Freigabe gestartet - Aufgabe erstellt - Frist abgelaufen
                // Status 9  -> Freigabe gestartet - Aufgabe erstellt - Dokument nicht freigegeben
                // Status 10  -> Freigabe gestartet - Aufgabe erstellt - Dokument freigegeben

                let status;

                switch (freigeber.status) {

                    case 0:
                        status = {
                            'badge' : '<span class="badge badge-danger">gelöscht</span>',
                            'text':'gelöscht',
                            'css' : 'secondary',
                            'id' : 0
                        };
                        break;

                    case 1:
                        status = {
                            'badge' : '<span class="badge badge-light text-dark">Warte auf Auslösung</span>',
                            'text':'Warte auf Auslösung',
                            'css' : 'light text-dark',
                            'id' : 1
                        };
                        break;

                    case 2:
                        status = {
                            'badge' : '<span class="badge badge-info">erstellt</span>',
                            'text':'Aufgabe erstellt',
                            'css' : 'info',
                            'id' : 2
                        };
                        break;

                    case 3:

                        status = {
                            'badge' : '<span class="badge badge-text-light">läuft bald ab</span>',
                            'text':'Frist läuft bald ab',
                            'css' : 'warning text-light',
                            'id' : 3
                        };
                        break;

                    case 4:

                        status = {
                            'badge' : '<span class="badge badge-danger">abgelaufen</span>',
                            'text':'Frist abgelaufen',
                            'css' : 'danger',
                            'id' : 4
                        };
                        break;

                    case 9:

                        status = {
                            'badge' : '<span class="badge badge-danger">nicht freigegeben</span>',
                            'text':'Dokument nicht freigegeben',
                            'css' : 'danger',
                            'id' : 9
                        };

                        break;

                    case 10:

                        status = {
                            'badge' : '<span class="badge badge-success">freigegeben</span>',
                            'text':'Dokument freigegeben',
                            'css' : 'success',
                            'id' : 10
                        };
                        break;
                }

                return status
            },

            parseDate: function (date) {
                //let parsed_date = Date.parse(date);
                if (date == null || typeof (date) === 'undefined') {
                    date = "-";
                } else {
                    date = date.frist;
                }
                return date;
            },
            post_sende_erinnerung: function (id_freigabeschritt_freigeber) {

                let self = this;
                axios.post(this.signed_api_post_sende_erinnerung_in, {
                    id_freigabeschritt_freigeber: id_freigabeschritt_freigeber
                })
                    .then(function (res) {
                        self.$swal({
                            title: 'Erfolg',
                            text: "Ihre Erinnerung wurde erfolgreich versandt",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            post_trotzdem_freigeben: function (freigabeschritt_freigeber) {

                let self = this;
                axios.post(this.signed_api_post_ueberschreibe_freigabe_in, {
                    id_freigabeschritt_freigeber: freigabeschritt_freigeber.id
                })
                    .then(function (res) {

                        freigabeschritt_freigeber.status = 10;

                        self.$swal({
                            title: 'Erfolg',
                            text: "Dokument wurde trotzdem freigegeben",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            post_manuell_ausloesen: function(){

                let self = this;

                self.$swal({
                    title: 'Achtung',
                    text: "Möchten Sie diesen Freigabeschritt wirklich manuell auslösen?",
                    type: 'warning',
                    toast: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Auslösen'
                }).then((result) => {
                    if (result.value) {
                        document.getElementById('form_manuell_ausloesen').submit();
                    }
                });
            },
            onDeselect: function () {
            },
            onInput: function () {
            },
        }
    };
</script>
<style scoped>

    .cursor-pointer {
        cursor: pointer;
    }

    .buttons {
        margin-top: 35px;
    }

    .list-group {
        border: unset !important;
        border-color: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .list-group-item {
        border: unset !important;
        border-color: unset !important;
        border: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }
</style>
