<template>
    <div class="chatapp-left">
        <header>
            <span class="">Beteiligte</span>
        </header>
        <div class="chatapp-users-list">
            <div class="nicescroll-bar">
                <div v-for="user in users" :key="user.id">
                    <chatElementLeftUser
                        :id="user.id"
                        :name="user.name"
                        :avatar="user.avatar"
                        :badge_color="user.badge_color"
                        :last_message="user.last_message"
                        :time_last_message="user.time_last_message"
                        :unread_messages_count="user.unread_messages_count"
                        :link_to_communication_in="user.link_to_communication"
                        :melder="user.melder"
                    >
                    </chatElementLeftUser>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    import chatElementLeftUser from './EMelderComponentMeldungenChatLeftUser';

    // ***********  Localization ***************

    export default {
        props: ['link_to_communication_in','verantwortliche'],
        components: {chatElementLeftUser},
        data() {
            return {
                users : JSON.parse(this.verantwortliche),

           /*     users: [
                    {
                        id: 1,
                        name: 'Rene Broo',
                        avatar: '/images/avatar_m_placeholder.png',
                        badge_color: 'success',
                        last_message: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
                        time_last_message: Math.floor(Math.random() * 10) + ' min',
                        unread_messages_count: Math.floor(Math.random() * 10),
                        link_to_communication: this.link_to_communication_in
                    },
                    {
                        id: 2,
                        name: 'Ticket: e5d94538',
                        avatar: '/images/avatar_m_placeholder.png',
                        badge_color:'warning',
                        last_message: 'nonumy eirmod tempor invidunt ut labore et ',
                        time_last_message: Math.floor(Math.random() * 10) + ' min',
                        unread_messages_count: Math.floor(Math.random() * 10),
                        link_to_communication: this.link_to_communication_in
                    },
                    {
                        id: 3,
                        name: 'Sandrina Jagals',
                        avatar: '/images/avatar_m_placeholder.png',
                        badge_color:'danger',
                        last_message: 'no sea takimata sanctus est Lorem ipsum dolor sit amet',
                        time_last_message: Math.floor(Math.random() * 10) + ' min',
                        unread_messages_count: Math.floor(Math.random() * 10),
                        link_to_communication: this.link_to_communication_in
                    },
                ], */
                lang: null,
            }
        },
        created() {

            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        }
        ,
        mounted() {
        }
        ,
        watch: {}
        ,
        computed: {}
        ,
        methods: {


        }
    }
    ;
</script>
<style scoped>

    .chatapp-left{
        -webkit-box-flex: 0;
        flex: 0 0 30% !important;
        max-width: 30% !important;
        border-right: 1px solid #eaecec;
        position: relative;
        z-index: 4;
        left: 0;
    }
</style>
