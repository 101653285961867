<template>
    <div class="col-lg-12">
        <div v-for="(row,row_key) in grid">
            <div class="row">
                <div v-for="(col,col_key) in row" class="w-100"
                     :class="'col-md-' + (12 / row.length) + ' col-sm-12 mb-30'">
                    <MeldenFormularBaustein
                        :grid_col="col"
                        input_in=""
                        disabled_in="false"
                        :api_org_element_gruppen_in="api_get_org_element_gruppen_in"
                        :api_org_elemente_in="api_get_org_elemente_in"
                        :api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in="api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in"
                        :api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in="api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in"
                        options_in="{}"
                    >
                    </MeldenFormularBaustein>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import MeldenFormularBaustein from './components/EMelderComponentMeldenBaustein';

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['api_get_grid_url_in', 'api_get_org_element_gruppen_in', 'api_get_org_elemente_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in','api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in'],
        components: {MeldenFormularBaustein},
        data() {
            return {
                lang: null,
                grid: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;

            this.getInput();
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getInput() {
                let self = this;
                axios.get(this.api_get_grid_url_in, {})
                    .then(function (res) {
                        self.grid = res.data.grid;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    };
</script>
<style scoped>
</style>
