<template>
  <div>
      <b-form-group
              :id="grid_col.baustein_key + '-group'"
              :description="baustein.description"
              :label="baustein.label"
              :label-for="grid_col.baustein_key"
              :invalid-feedback="invalidFeedback"
              :valid-feedback="validFeedback"
              :state="state"
      >
          <template v-slot:label>
              {{baustein.label}} <span v-if="baustein.required" class="text-danger">*</span>
          </template>
              <b-form-select
                  v-model="input_org_gruppe"
                  :options="options_org_gruppe"
                  value-field="id"
                  text-field="bezeichnung"
                  :id="grid_col.baustein_key"
                  :state="state"
                  autocomplete="off"
                  @input="init"
                  @blur="blur"
                  @change="changeOrgGruppe"
                  :disabled="disabled"
                  class="w-45"
              ></b-form-select>
          <b-form-select
              v-model="input_org_element"
              :options="options_org_elemente"
              :id="grid_col.baustein_key +'1'"
              :state="state"
              autocomplete="off"
              value-field="id"
              text-field="bezeichnung"
              @input="init"
              @blur="blur"
              :disabled="disabled"
              class="w-45"
          ></b-form-select>

          <input type="hidden" :name="grid_col.baustein_key" v-model="input_org_element">
      </b-form-group>
  </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['grid_col','baustein','input_org_gruppe_in','input_org_elemente_in','options_in','api_org_element_gruppen_in','api_org_elemente_in','disabled_in'],
        name: 'EMelderComponentMeldenBs7OrgElemente',
        components: {},
        data() {
            return {
                data: [],
                lang: null,
                input_org_element: null,
                input_org_gruppe: null,
                initial: null,
                disabled: null,
                options: this.options_in,
                options_org_gruppe: null,
                options_org_elemente: null,

            }
        },
        computed: {
            state() {
            },
            invalidFeedback() {
            },
            validFeedback() {
            }
        },
        created() {
            var lang = new Lang();
            lang.setLocale(this.locale_in);
            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }

            lang.setMessages(translations);
            this.lang = lang;

            this.getOrgElementGruppen();

            if(this.input_org_gruppe_in && this.input_org_elemente_in) {
                this.input_org_gruppe = this.input_org_gruppe_in ? this.input_org_gruppe_in : 1;
                this.input_org_element = this.input_org_elemente_in;
                this.getOrgElemente(this.input_org_gruppe,this.input_org_elemente_in);
            }

            this.disabled = JSON.parse(this.disabled_in);
        },
        mounted() {
        },
        methods: {
            getOrgElementGruppen(){
                let self = this;
                axios.get(this.api_org_element_gruppen_in, {})
                    .then(function (res) {
                        self.options_org_gruppe = res.data.org_element_gruppen;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getOrgElemente(id_org_element_gruppe,id_org_elemente){
                let self = this;
                axios.post(this.api_org_elemente_in, {
                    id_org_element_gruppe: id_org_element_gruppe
                })
                    .then(function (res) {
                        self.options_org_elemente = res.data.org_elemente;

                        if(id_org_elemente)
                            self.input_org_element = id_org_elemente;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            changeOrgGruppe(){
                this.getOrgElemente(this.input_org_gruppe);
            },
            init() {
                this.initial = 1;
            },
            blur(){
                this.initial = null;
            }
        },
    }
</script>

<style lang="scss"></style>
