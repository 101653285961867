<template>
    <div class="chatapp-right">
        <div class="hk-pg-header align-items-top ml-20" style="margin-bottom: 0px !important;">
            <div>
                <h2 class="hk-pg-title font-weight-600 mt-15 mb-10">Kommunikation - Webcam</h2>
                <p>Sie haben Fragen zum Thema Chat? <a
                    href="/support">Hilfe</a></p>
            </div>
            <div>
                <!-- @component('components.schnellzugriff.component_schnellzugriff_leiste')
                 @endcomponent-->
            </div>
        </div>
        <header>
            <div class="d-flex">
                <div :class="'emelder_status ' + meldung.status_css ">
                    <div class="emelder_status_letter">{{meldung.status_txt_short}}</div>
                </div>
                <span class="text-dark ml-15"><h5
                    class="">{{meldung.vorlage_bezeichnung}}</h5> (Ticket: {{meldung.ticket_id}})</span>
                <br>
            </div>
            <div class="mr-30">
            <span
                class="text-success">Frist bis zum: {{meldung.frist}}</span><br>
                <span
                    class="text-dark">Erstellt am: {{meldung.erstellt}}</span><br>
            </div>
        </header>
        <div class="chat-body">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-md-8 w-100">
                        <div class="video h-100">
                                <div class="video_div h-70">
                                    <span class="loading_text blink_me">Baue Verbindung auf...</span>
                                </div>
                                <div class="video_icons">
                                    <img :src="icons_path_in">
                                </div>
                        </div>
                    </div>
                    <div id="communication_sidebar" v-if="windowWidth > 1440" class="col-md-4 border-left">
                        <div class="video_own_cam h-40 border-bottom">
                            <div class="video_own_cam_div">
                                <span class="loading_text_own blink_me">Baue Verbindung auf...</span>
                            </div>
                        </div>
                        <div class="communication_chat_div h-60">
                            <h3 class="ml-15 mb-15 mt-15">Chat</h3>
                            <ul class="list-unstyled">
                                <li v-for="message in messages" class="media" :class="message.type === 'sent' ? 'sent justify-content-end' : 'received'">
                                    <chatElementRightMessage
                                        :message="message"
                                    >
                                    </chatElementRightMessage>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <footer>
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="btn-file  btn btn-light">
                        <div class="btn-icon-wrap">
                            <span class="feather-icon"><i data-feather="paperclip"></i></span>
                            <input type="file">
                        </div>
                    </div>
                </div>
                <input type="text" id="input_msg_send_chatapp" name="send-msg" class="input-msg-send form-control"
                       placeholder="Bitte Nachricht eingeben...">
                <div class="input-group-append">
                    <button type="button" class="btn  btn-light  dropdown-toggle no-caret" data-toggle="dropdown"><span
                        class="feather-icon"><i data-feather="at-sign"></i></span></button>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    import chatElementRightMessage from './EMelderComponentMeldungenChatRightMessage';

    // ***********  Localization ***************

    export default {
        props: ['meldungskategorien_in', 'page_in', 'org_gruppen_selected_in','icons_path_in'],
        components: {chatElementRightMessage},
        data() {
            return {
                meldung: {
                    id: 12,
                    vorlage_bezeichnung: 'CIRS-Meldung',
                    ticket_id: 'e5d94538',
                    link: '#',
                    status_css: 'emelder_status_neu',
                    status_txt_short: 'N',
                    erstellt: '15.06.2020',
                    frist: '15.07.2020',
                },
                messages: [
                    {
                        id: 1,
                        id_user: 10,
                        user_name: 'Rene Broo',
                        avatar: '/images/avatar_m_placeholder.png',
                        message: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
                        time: '11:45',
                        type: 'received',
                    },
                    {
                        id: 2,
                        id_user: 11,
                        user_name: 'Ticket: e5d94538',
                        avatar: '/images/avatar_m_placeholder.png',
                        message: 'nonumy eirmod tempor invidunt ut labore et ',
                        time: '12:15',
                        type: 'received',
                    },
                    {
                        id: 2,
                        id_user: 10,
                        user_name: 'Rene Broo',
                        avatar: '/images/avatar_m_placeholder.png',
                        message: 'Dolor sit amet, tempor invidunt ut labore et ',
                        time: '12:23',
                        type: 'sent',
                    },
                ],
                lang: null,
                windowWidth: window.innerWidth,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
            this.org_gruppe = this.org_gruppen_selected_in;
            this.getOrgElemente();
            window.onresize = () => {
                this.windowWidth = window.innerWidth
            }
        },
        watch: {},
        computed: {},
        methods: {
            pushOrgElement(orgElemente, prefix = '-') {
                for (let singleOrgElement in orgElemente) {
                    this.org_element_data_tree.push(
                        {
                            id: orgElemente[singleOrgElement].id,
                            bezeichnung: prefix + ' ' + orgElemente[singleOrgElement].bezeichnung,
                        });
                    this.pushOrgElement(orgElemente[singleOrgElement].children, prefix + '-');
                }
            },
            InputVolltextsuche() {
                if (this.volltextsuche.length > 1 || this.volltextsuche == '') {
                    this.triggerSearchEvent();
                }
            },
            getOrgElemente() {
                let self = this;
                axios.get('/dokman/mein_bereich/api/' + self.org_gruppe + '/get_org_elemente')
                    .then(function (res) {
                        self.org_element_data = res.data;
                        self.getRedakteure();
                        self.org_element_data_tree = [];
                        self.pushOrgElement(self.org_element_data, '');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getRedakteure() {
                let self = this;
                axios.get('/dokman/mein_bereich/api/' + self.org_gruppe + '/get_redakteure/')
                    .then(function (res) {
                        self.redakteur_data = res.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            triggerSearchEvent() {
                this.$eventBus.$emit('searchQuery', {
                    volltextsuche: this.volltextsuche,
                    org_gruppe: this.org_gruppe,
                    org_element: this.org_element,
                    dokumentenart: this.dokumentenart,
                    status: this.status,
                    redakteur: this.redakteur,
                    switch_eigene: this.switch_eigene,
                    page: this.page,
                });
            },
            toggleSearch(value) {
                switch (value) {
                    case 'switch_eigene':
                        this.switch_eigene = !this.switch_eigene;
                        break;
                }
                this.triggerSearchEvent();
            },
        }
    };
</script>
<style scoped>
    .chat-body{
        //height: calc(100vh - 458px) !important;
    }

    .chatapp-wrap .chatapp-right {
        -webkit-box-flex: 0;
        flex: 0 0 70%;
        max-width: 70%;
    }

    .video_div{
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        max-height: 650px;
        min-height: 250px;
        width: 650px ;
        background-color: #000000;
        //line-height: 650px;
        text-align: left;
    }

    .video_own_cam_div{
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        max-height: 200px;
        min-height: 160px;
        width: 200px;
        background-color: #000000;
        line-height: 200px;
        text-align: left;
    }

    .video_icons {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .loading_text {
        margin-top: 60px;
        margin-left: 60px;
        font-weight: 600;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }

    .loading_text_own {
        margin-top: 15px;
        margin-left: 15px;
        font-weight: 600;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }

    .blink_me {
        animation: blinker 2s linear infinite;
    }

    #communication_sidebar{
        display: none !important;
    }

    @keyframes blinker {
        50% {
            opacity: 0.7;
        }
    }

    communication_chat_div {
        overflow: auto;
        max-height: 100%;
    }

    @media (min-width: 1441px) {
        #communication_sidebar{
           display: block !important;
        }
    }
</style>
