<template>
    <div>
        <div class="d-flex" v-if="message.type==='received'">
            <div class="avatar">
                <img :src="message.avatar" alt="user" class="avatar-img rounded-circle">
            </div>
            <div class="media-body">
                <div class="msg-box">
                    <div>
                        <p>{{message.message}}</p>
                        <span class="chat-time">{{message.time}}</span>
                        <div class="arrow-triangle-wrap">
                            <div class="arrow-triangle right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" v-else-if="message.type==='sent'">
            <div class="media-body" >
                <div class="msg-box">
                    <div>
                        <p>{{message.message}}</p>
                        <span class="chat-time">{{message.time}}</span>
                        <div class="arrow-triangle-wrap">
                            <div class="arrow-triangle left"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['message'],
        components: {},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {
            getData() {
            },
        }
    };
</script>
<style scoped>
</style>
