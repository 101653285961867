<template>
    <div>
        <div class="row">
            <div class="col-md-10">
                <label>Verwendete Vorlage</label><br>
                <b-form-select v-model="vorlage_selected" :options="vorlage_options" value-field="id"
                               text-field="bezeichnung" class="mt-3" @change="getVorlageSchritte()"></b-form-select>
            </div>
            <div class="col-md-2 text-right">
                <b-button class="btn btn-sm btn-primary mt-55 w-100" @click="resetToVorlage()">Zurücksetzen</b-button>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-inline-flex ml-30">
                            <div>
                                <span class="d-block font-10 font-weight-500 text-dark text-uppercase mb-5"
                                      style="font-size:10px;">Prüfungsinterval</span>
                                <span class="d-block font-14 font-weight-600 text-dark">{{pruefungs_interval}}</span>
                            </div>
                        </div>
                        <div class="d-inline-flex ml-60">
                            <div>
                                <span class="d-block font-10 font-weight-500 text-dark text-uppercase mb-5"
                                      style="font-size:10px;">Prüfung endet nach</span>
                                <span class="d-block font-14 font-weight-600 text-dark">{{pruefung_letzte}}</span>
                            </div>
                        </div>
                        <div class="d-inline-flex ml-60">
                            <div>
                                 <span class="d-block font-10 font-weight-500 text-dark text-uppercase mb-5"
                                       style="font-size:10px;">Nächste Prüfung vorraussichtlich am</span>
                                <span class="d-block font-14 font-weight-600 text-success">{{pruefung_naechste}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <input type="hidden" name="count" :value="list.length">
                <div v-if="list.length > 0">
                    <div class="card">
                        <div class="card-header card-header-action">
                            <h6>Prüfungsschritte</h6>
                            <div class="d-flex align-items-center card-action-wrap">
                                <a class="btn btn-primary btn-xs inline-block text-white" style="cursor: pointer;"
                                   v-b-toggle.collapse-1>
                                    <i class="ion ion-md-add-circle-outline"></i> Details {{collapsed ? 'ausblenden' :
                                    'anzeigen'}}
                                </a>
                            </div>
                        </div>
                        <b-collapse id="collapse-1" v-model="collapsed">
                            <draggable
                                    :list="list"
                                    :disabled="!enabled"
                                    class="list-group"
                                    ghost-class="ghost"
                                    :move="draggElement"
                                    @start="dragging = true"
                                    @end="function(){triggerStore(); dragging = false}"
                            >
                                <div
                                        class="list-group-item"
                                        v-for="element,key in list"
                                        :key="element.id"
                                >
                                    <div class="card mt-15 mb-15 ml-15 mr-15">
                                        <div class="card-header ">
                                            <div class="d-inline-block float-left mr-20"><i
                                                    class="fa-2x d-inline cursor-pointer ion ion-md-menu"></i>
                                            </div>
                                            <div class="d-inline-block float-left">{{ key + 1 }}. Schritt:
                                                {{vorlage_typ}} -
                                                {{ element.name }}
                                            </div>
                                            <div class="d-flex align-items-center card-action-wrap float-right">
                                                <a class="inline-block card-close" href="#" data-effect="fadeOut"
                                                   @click="remove(key)">
                                                    <i class="ion ion-md-close"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-12"><strong><h4>{{element.aufgabe_text}}</h4>
                                                        </strong>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 mt-10">
                                                            <strong>Frist:</strong>
                                                            (beginnt zum Zeitpunkt der Aktivierung ) <br>

                                                            <span v-if="element.frist_monate || element.frist_wochen || element.frist_tage">
                                                        <span v-if="element.frist_monate"
                                                              class="font-weight-600 text-success mr-30">+ {{element.frist_monate}} Monate</span>
                                                        <span v-if="element.frist_wochen"
                                                              class="font-weight-600 text-success mr-30">+ {{element.frist_wochen}} Wochen </span>
                                                        <span v-if="element.frist_tage"
                                                              class="font-weight-600 text-success mr-30">+ {{element.frist_tage}} Tage </span>
                                                    </span>
                                                            <span v-else>
                                                         <span class="font-weight-600 text-info mr-30">Ohne Fristvorgabe</span>
                                                    </span>
                                                            <b-button class="btn btn-primary btn-sm float-right"
                                                                      @click="selected_schritt_key = key"
                                                                      v-b-modal.modal-2>
                                                                Mitarbeiter hinzufügen
                                                            </b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="mt-10 mb-10">
                                                        <div class="mt-10 mb-10">
                                                            <div v-if="typeof element.geltungen == 'undefined' || (element.geltungen.length == 1 && element.geltungen[0]['id'] == 0)" class="mt-15 mb-10 pt-5 pl-5 pr-5 pb-5 bg-secondary text-white">
                                                                <strong>Verantwortliche Personen</strong>
                                                            </div>
                                                            <div v-for="geltung,key_geltung in element.geltungen">
                                                                <div v-if="geltung.bezeichnung" class="mt-15 mb-10 pt-5 pl-5 pr-5 pb-5 bg-secondary text-white">
                                                                    <strong >Geltungsbereich : {{geltung.bezeichnung != null? geltung.bezeichnung : 'Verantwortliche Personen'}}</strong>
                                                                </div>
                                                                <div v-for="ebenen,key_ebenen in geltung.beziehungen">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <span class="d-block font-14 font-weight-500 text-dark text-uppercase">{{ebenen.bezeichnung}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="ebenen.verantwortliche.length > 0">
                                                                        <div class="row">
                                                                            <div class="col-md-4">
                                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Name</span>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Option</span>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <span class="d-block font-12 font-weight-500 text-dark text-uppercase">Pflichtteilnahme</span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-for="verantwortlicher,key_verantwortlicher in ebenen.verantwortliche">
                                                                            <div class="row">
                                                                                <div class="col-md-4">
                                                                                    <i v-if="verantwortlicher.doppelte"
                                                                                       class="ion ion-md-warning text-danger"
                                                                                       v-b-tooltip.hover
                                                                                       title="Person mehrfach vorhanden"></i>
                                                                                    <span class="text-info">{{verantwortlicher.name}} ({{verantwortlicher.rolle}})</span>
                                                                                </div>
                                                                                <div class="col-md-2">
                                                                                    <a href="javascript:void(0)"
                                                                                       @click='deleteMitarbeiter(key, key_geltung, key_ebenen, key_verantwortlicher)'>
                                                                                        <i class="zmdi zmdi-delete font-20 mr-10 text-light-40"></i></a>
                                                                                </div>
                                                                                <div class="col-md-2">
                                                                    <span class=""><input type="checkbox"
                                                                                          name="pflichtteilnahme"
                                                                                          @change='toggleMandatory()'
                                                                                          v-model="verantwortlicher.pflicht"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br>
                                                                    </div>
                                                                    <div v-else>
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <span class="text-danger">Keine Verantwortlichen festgelegt</span>
                                                                            </div>
                                                                        </div>
                                                                        <br>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 d-inline">
                                                           <span class="font-16">Ab wieviel positiven Entscheidungen,
                                                                wird dieser Schritt erfolgreich bewertet (min.): </span>
                                                                <span class="badge badge-primary ml-15">{{getMinPositive(element)}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="hidden" :name="'id_' + key" :value="element.id">
                                            <input type="hidden" :name="'vorlage_typ_' + key" :value="vorlage_typ">
                                            <input type="hidden" :name="'vorlage_name_' + key" :value="vorlage_typ">
                                            <input type="hidden" :name="'name_' + key" :value="element.name">
                                            <input type="hidden" :name="'id_pruefungsschritte_typen_' + key"
                                                   :value="element.id_pruefungsschritte_typen">
                                            <input type="hidden" :name="'aufgabe_text_' + key"
                                                   :value="element.aufgabe_text">
                                            <input type="hidden" :name="'frist_monate_' + key"
                                                   :value="element.frist_monate">
                                            <input type="hidden" :name="'frist_wochen_' + key"
                                                   :value="element.frist_wochen">
                                            <input type="hidden" :name="'frist_tage_' + key"
                                                   :value="element.frist_tage">
                                            <input type="hidden" :name="'min_positive_' + key"
                                                   :value="element.min_positive">
                                        </div>
                                    </div>
                                </div>
                            </draggable>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group ml-20">
                                        <b-button class="btn btn-primary" v-b-modal.modal-1>Neuen Schritt hinzufügen
                                        </b-button>
                                    </div>
                                </div>
                            </div>

                        </b-collapse>
                    </div>


                </div>
                <div v-else="list.length > 0">
                    <div class="alert alert-info mb-15 mb-15 w-100">Keine Schritte vorhanden</div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <b-button class="btn btn-primary" v-b-modal.modal-1>Neuen Schritt hinzufügen
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="modal-1" title="Neuen Schritt hinzufügen" @ok="add_schritt()" @show="resetModal()">
            <div class="row mb-15">
                <div class="col-md-8">
                    <input type="hidden" name="id_aufgabeart" id="id_aufgabeart" value="1">
                </div>
            </div>
            <div class="row mb-30">
                <div class="col-md-8">
                    <label for="aufgabeschritt_vorlage" class="required">{{vorlage_typ}}</label>
                    <select name="aufgabeschritt_vorlage" id="aufgabeschritt_vorlage" class="form-control"
                            v-model="new_aufgabeschritt_vorlage">
                        <option v-for="pruefungsschritte_typ in pruefungsschritte_typen"
                                :value="[pruefungsschritte_typ.id, pruefungsschritte_typ.bezeichnung]">
                            {{pruefungsschritte_typ.bezeichnung}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="aufgabeschritt_aufgabe_text" class="required">Aufgabetext</label>
                <input type="text" class="form-control" id="aufgabeschritt_aufgabe_text"
                       name="aufgabeschritt_aufgabe_text" placeholder="Bitte eingeben" required
                       autocomplete="off" v-model="new_aufgabeschritt_aufgabe_text">
            </div>
            <label class="required">Frist</label>
            <div class="row">
                <div class="col-4">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupMonth">+ Monate</span>
                        </div>
                        <input type="text" class="form-control" id="aufgabeschritt_frist_monate"
                               name="aufgabeschritt_frist_monate" placeholder="" required
                               autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupMonth"
                               v-model="new_aufgabeschritt_frist_monate">
                    </div>
                </div>
                <div class="col-4">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupWeek">+ Wochen</span>
                        </div>
                        <input type="text" class="form-control" id="aufgabeschritt_frist_wochen"
                               name="aufgabeschritt_frist_wochen" placeholder="" required
                               autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupWeek"
                               v-model="new_aufgabeschritt_frist_wochen">
                    </div>
                </div>
                <div class="col-4">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupDay">+ Tage</span>
                        </div>
                        <input type="text" class="form-control" id="aufgabeschritt_frist_tage"
                               name="aufgabeschritt_frist_tage" placeholder="" required
                               autocomplete="off" aria-label="+ Monate" aria-describedby="inputGroupDay"
                               v-model="new_aufgabeschritt_frist_tage">
                    </div>
                </div>
            </div>
            <label class="required mt-5">Positive Entscheidungen nötig</label>
            <div class="row">
                <div class="col-4">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroupMin">min.</span>
                        </div>
                        <input type="text" class="form-control" id="aufgabeschritt_min_positive"
                               name="aufgabeschritt_min_positive" placeholder="" required
                               autocomplete="off" aria-label="min." aria-describedby="inputGroupMin"
                               value="" v-model="new_aufgabeschritt_min_positive">
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="modal-2" title="Neuen Mitarbeiter hinzufügen" @ok="add_mitarbeiter()"
                 @show="resetModalMitarbeiter()">
            <div class="row mb-15">
                <div class="col-md-8"></div>
            </div>
            <!-- <div class="row mb-30">
                 <div class="col-md-8">
                     <label for="neuer_mitarbeiter_typ" class="required">Hinzufügen von</label>
                     <select name="neuer_mitarbeiter_typ" id="neuer_mitarbeiter_typ" class="form-control"
                             v-model="neuer_mitarbeiter_typ" @change="reloadOptions()">
                         <option value="mitarbeiter">Mitarbeiter</option>
                         <option value="mitarbeiterfunktion">Mitarbeiterfunktion</option>
                     </select>
                 </div>
             </div>-->
            <input type="hidden" value="mitarbeiter" v-model="neuer_mitarbeiter_typ">

            <!--  <div v-if="neuer_mitarbeiter_typ == 'mitarbeiterfunktion'" class="row mb-30">
                  <div class="col-md-8">
                      <label for="org_gruppe" class="required">Organiationsgruppe</label>
                      <select name="org_gruppe" id="org_gruppe" class="form-control"
                              v-model="org_gruppe" @change="reloadOptions()">
                          <option v-for="gruppe in org_gruppen_in"
                                  :value="gruppe.id">{{gruppe.bezeichnung}}
                          </option>
                      </select>
                  </div>
              </div>-->
            <div class="form-group d-inline-block">
                <label class="required">Auswahl</label>
                <treeselect
                        name="element_auswahl"
                        :multiple="multiple"
                        :clearable="clearable"
                        :searchable="searchable"
                        :searchNested="searchNested"
                        :async="async"
                        :disabled="disabled"
                        :open-on-click="openOnClick"
                        :open-on-focus="openOnFocus"
                        :clear-on-select="clearOnSelect"
                        :close-on-select="closeOnSelect"
                        :always-open="alwaysOpen"
                        :append-to-body="appendToBody"
                        :options="options"
                        :value-consists-of="valueConsistsOf"
                        :loading="loading"
                        :auto-load-root-options="auto_load_root_options"
                        :load-options="loadOptions"
                        :show-count="showCount"
                        :showCountOf="showCountOf"
                        :loadingText="loadingText"
                        :noChildrenText="noChildrenText"
                        :noOptionsText="noOptionsText"
                        :noResultsText="noResultsText"
                        :openDirection="openDirection"
                        :placeholder="placeholder"
                        :required="required"
                        :limit="limit"
                        :max-height="maxHeight"
                        :disableFuzzyMatching="disableFuzzyMatching"
                        v-model="value"
                        :value-format="valueFormat"
                        :normalizer="normalizer"
                        @select="onSelect()"
                        @deselect="onDeselect()"
                        @input="onInput()"
                        @open="onOpen()"
                ></treeselect>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import draggable from "vuedraggable";

    // import the component
    import Treeselect from '@riophae/vue-treeselect'
    import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
    import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'

    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'


    let id = 1;
    export default {
        props: ['vorlage_options_in', 'vorlage_typ_in', 'pruefungsschritte_typen_in', 'pruefungsschritte_in', 'id_dokument_in', 'id_vorlage_in','org_gruppen_in', 'signed_api_get_pruefungsschritte_in', 'signed_api_get_vorlage_route_in', 'signed_api_get_mitarbeiter_route_in', 'signed_api_get_mitarbeiterfunktionen_route_in', 'signed_api_store_in', 'signed_api_delete_pruefungsschritte_in','signed_api_get_pruefungsparameter_route_in'],
        name: "PruefungsVorlage",
        display: "PruefungsVorlage",
        order: 0,
        components: {
            draggable,
            Treeselect
        },
        data() {
            return {
                enabled: true,
                vorlage_selected: null,
                vorlage_options: this.vorlage_options_in,
                vorlage_typ: this.vorlage_typ_in,

                collapsed: false,

                list: this.pruefungsschritte_in,
                pruefungsschritte_typen: this.pruefungsschritte_typen_in,

                neuer_mitarbeiter_typ: 'mitarbeiter',

                org_gruppe: null,

                new_aufgabeschritt_vorlage: '',
                new_aufgabeschritt_aufgabe_text: '',
                new_aufgabeschritt_frist_monate: '',
                new_aufgabeschritt_frist_wochen: '',
                new_aufgabeschritt_frist_tage: '',
                new_aufgabeschritt_min_positive: '',

                pruefungs_interval: null,
                pruefung_letzte: null,
                pruefung_naechste: null,

                dragging: false,

                selected_schritt_key: null,

                // Switch Parameter
                multiple: true,
                disableFuzzyMatching: true,
                include_ids: null,
                exclude_ids: null,
                clearable: true,
                searchable: true,
                searchNested: false,
                async: false,
                disabled: false,
                openOnClick: true,
                openOnFocus: false,
                clearOnSelect: true,
                closeOnSelect: false,
                alwaysOpen: false,
                appendToBody: false,
                valueConsistsOf: 'LEAF_PRIORITY',
                loadingText: 'Lade...',
                noChildrenText: 'Keine Mitarbeiter zugeordnet',
                noOptionsText: 'Keine Daten',
                noResultsText: 'Keine Daten',
                openDirection: 'below',
                placeholder: 'Bitte wählen Sie Ihre Mitarbeiter aus',
                auto_load_root_options: false,
                loading: true,
                required: false,
                maxHeight: 440,
                limit: Infinity,
                showCount: false,
                showCountOf: 'ALL_CHILDREN',
                value: null,
                valueFormat: 'object',
                options: null,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        //children: node.subOptions,
                    }
                },
            };
        },
        mounted() {
            this.vorlage_selected = this.id_vorlage_in;
            this.org_gruppe = 1;
            this.getAllPruefungSchritte();
            this.getPruefungsParameter();
        },
        computed: {
            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        methods: {
            resetModal: function () {
                this.new_aufgabeschritt_vorlage = [1, "Nach Unternehmensstruktur"];
                this.new_aufgabeschritt_aufgabe_text = "Bitte prüfen Sie dieses Dokument";
                this.new_aufgabeschritt_frist_monate = "";
                this.new_aufgabeschritt_frist_wochen = "";
                this.new_aufgabeschritt_frist_tage = "";
                this.new_aufgabeschritt_min_positive = 2;
            },
            resetModalMitarbeiter: function () {
                this.neuer_mitarbeiter_typ = 'mitarbeiter';
                this.value = null;
            },
            resetToVorlage: function () {
                let self = this;
                axios.post(this.signed_api_delete_pruefungsschritte_in, {})
                    .then(function (res) {
                        self.getVorlageSchritte();

                        self.$swal({
                            title: 'Erfolg',
                            text: "Ihre Änderungen wurden zurückgesetzt",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            add_schritt: function () {
                this.list.push({
                    id: this.list.length +1,
                    name: this.new_aufgabeschritt_vorlage[1],
                    id_pruefungsschritte_typen: this.new_aufgabeschritt_vorlage[0],
                    aufgabe_text: this.new_aufgabeschritt_aufgabe_text,
                    frist_monate: this.new_aufgabeschritt_frist_monate,
                    frist_wochen: this.new_aufgabeschritt_frist_wochen,
                    frist_tage: this.new_aufgabeschritt_frist_tage,
                    min_positive: this.new_aufgabeschritt_min_positive
                });
                this.triggerStore();
            },
            add_mitarbeiter: function () {
                for (let new_element in this.value) {

                    if (typeof this.list[this.selected_schritt_key].geltungen === 'undefined') {
                        let obj = {
                            'bezeichnung': 'zusätzlich hinzugefügt',
                            'verantwortliche': []
                        };
                        this.list[this.selected_schritt_key].geltungen = [{'beziehungen': {0: obj}}];
                    }

                    let obj_element = {
                        'id': this.value[new_element].id,
                        'name': this.value[new_element].name,
                        'rolle': this.value[new_element].rolle,
                        'doppelte': 0,
                        'pflicht': 0,
                    };

                    let obj = {
                        'bezeichnung': 'zusätzlich hinzugefügt',
                        'verantwortliche': [obj_element]
                    };


                    if (typeof this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0] === 'undefined') {
                        this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0] = obj;
                        //this.checkForDulplicates(this.selected_schritt_key, 0, 0, 0, obj_element,false);
                    } else {
                        let count = Object.keys(this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche).length + 1;
                        this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche[count] = obj_element;
                        //this.checkForDulplicates(this.selected_schritt_key, 0, 0, count, obj_element,false);
                    }
                }

                this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche = this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche.filter(function (x, i, a) {
                    return a.indexOf(x) == i;
                });

                this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche = this.list[this.selected_schritt_key].geltungen[0]['beziehungen'][0].verantwortliche.filter(function (el) {
                    return el != null;
                });

                this.checkForDulplicates();
                this.$forceUpdate();
                this.triggerStore();
            },
            toggleMandatory: function () {
                this.triggerStore();
            },
            deleteMitarbeiter: function (list, geltung, ebenen, verantwortlicher) {
                this.list[list].geltungen[geltung]['beziehungen'][ebenen].verantwortliche.splice(verantwortlicher, 1);
                this.checkForDulplicates();
                this.$forceUpdate();
                this.triggerStore();
            },
            checkForDulplicates: function () {
                for (let element in this.list) {
                    if (typeof this.list[element].geltungen !== 'undefined') {
                        for (let single_geltung in this.list[element].geltungen)
                            for (let single_element in this.list[element].geltungen[single_geltung]['beziehungen'])
                                for (let single_verantwortliche in this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche']) {
                                    this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche'][single_verantwortliche].doppelte = 0;
                                    this.checkIfAlreadyExists(element, single_geltung, single_element, single_verantwortliche, this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche'][single_verantwortliche])
                                }
                    }
                }
            },
            checkIfAlreadyExists: function (list, geltung, ebenen, verantwortlicher, verantwortlicher_obj) {
                for (let element in this.list) {
                    if (typeof this.list[element].geltungen !== 'undefined') {
                        for (let single_geltung in this.list[element].geltungen) {
                            for (let single_element in this.list[element].geltungen[single_geltung]['beziehungen']) {
                                for (let single_verantwortliche in this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche']) {

                                    if (this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche'][single_verantwortliche] !=
                                        this.list[list].geltungen[geltung]['beziehungen'][ebenen]['verantwortliche'][verantwortlicher] && this.list[element].geltungen[single_geltung]['beziehungen'][single_element]['verantwortliche'][single_verantwortliche].id == verantwortlicher_obj.id) {
                                        verantwortlicher_obj.doppelte = 1;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            getAllPruefungSchritte: function () {

                let self = this;
                axios.post(this.signed_api_get_pruefungsschritte_in, {
                    id_vorlage: this.vorlage_selected
                })
                    .then(function (res) {
                        if (res.data.length) {
                            self.list = [];
                            for (let vorlage in res.data) {
                                self.list.push(res.data[vorlage]);
                            }
                        } else {
                            self.getVorlageSchritte();
                        }
                        self.checkForDulplicates();
                        self.$forceUpdate();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            switchVorlage: function(){
                this.getAllPruefungSchritte();
            },
            getVorlageSchritte: function () {
                let self = this;
                axios.post(this.signed_api_get_vorlage_route_in, {
                    id_vorlage: this.vorlage_selected
                })
                    .then(function (res) {
                        self.list = [];
                        for (let vorlage in res.data) {
                            self.list.push(res.data[vorlage]);
                        }
                        self.getPruefungsParameter();
                        self.triggerStore();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getPruefungsParameter: function () {

                let self = this;
                axios.post(this.signed_api_get_pruefungsparameter_route_in, {
                    id_vorlage: this.vorlage_selected
                })
                    .then(function (res) {
                        self.pruefungs_interval = res.data.pruefungsinterval_human_readable;
                        self.pruefung_letzte = res.data.pruefung_letzte;
                        self.pruefung_naechste = res.data.pruefung_naechste;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            getMinPositive: function (element) {
                let min_count = 0;
                for (let geltung in element.geltungen) {
                    for (let single_element in element.geltungen[geltung]['beziehungen'])
                        min_count += element.geltungen[geltung]['beziehungen'][single_element]['verantwortliche'].length;
                }
                return element.min_positive >= min_count ? min_count : element.min_positive;
            },
            triggerStore: function () {
                let self = this;
                setTimeout(function () {
                    self.storeAll();
                }, 200)
            },
            storeAll: function () {
                let self = this;
                axios.post(this.signed_api_store_in, {
                    id_vorlage: this.vorlage_selected,
                    pruefung_data: this.list
                }).then(function (res) {

                })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            remove: function (key) {

                //console.log('remove' + key);
                //console.log(this.list);
                this.list.splice(key, 1);
                //console.log(this.list);
                this.triggerStore();
            },
            draggElement: function (e) {
                //window.console.log("Future index: " + e.draggedContext.futureIndex);
                //this.triggerStore();
            },
            loadOptions({action, parentNode, callback}) {

                if (action === LOAD_ROOT_OPTIONS) {

                    let url = (this.neuer_mitarbeiter_typ == "mitarbeiter") ? this.signed_api_get_mitarbeiter_route_in : this.signed_api_get_mitarbeiterfunktionen_route_in;

                    self = this;
                    axios.get(url)
                        .then(function (res) {
                            self.options = res.data;
                            callback();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            },
            reloadOptions: function () {

                self = this;
                this.value = null;
                if (this.neuer_mitarbeiter_typ == "mitarbeiter") {
                    axios.get(this.signed_api_get_mitarbeiter_route_in)
                        .then(function (res) {
                            self.options = res.data;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                } else {
                    axios.post(this.signed_api_get_mitarbeiterfunktionen_route_in, {'id_org_gruppe': this.org_gruppe})
                        .then(function (res) {
                            self.options = res.data;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }

            },
            onOpen: function () {
            },
            onSelect: function () {
            },
            onDeselect: function () {
            },
            onInput: function () {
            },
        }
    }
    ;
</script>
<style scoped>

    .cursor-pointer {
        cursor: pointer;
    }

    .buttons {
        margin-top: 35px;
    }

    .list-group {
        border: unset !important;
        border-color: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .list-group-item {
        border: unset !important;
        border-color: unset !important;
        border: unset !important;
        box-shadow: unset !important;
        border-radius: unset !important;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
</style>
