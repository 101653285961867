<template lang="pug">
  .row
    .col-6
      div(v-if="org_treedata.length || new_treedata.length")
        Tree(:data="org_treedata" draggable cross-tree :ondragend="onDragEnd" ref="ref_org_treedata")
          div(slot-scope="{data, store}")
            template(v-if="!data.isDragPlaceHolder")
              div(v-if="!edit_node || data.id != edit_node.id")
                b(v-if="data.children && data.children.length" @click="store.toggleOpen(data)") {{data.open ? '-' : '+'}}&nbsp;
                span {{data.bezeichnung ? data.bezeichnung_kurz ? data.bezeichnung + ' (' + data.bezeichnung_kurz + ')' : data.bezeichnung : data.name }}
                span.float-right(v-if="data.id != org_treedata[0].id")
                  i.glyphicon.glyphicon-pencil(@click="editElement(data)")
                  i.ml-10.glyphicon.glyphicon-trash(@click="function(){deleteElement(data);}")
                span.float-right.ml-5.mr-10(v-if="data.id != org_treedata[0].id")
                  i.glyphicon.glyphicon-chevron-up(@click="moveElement(data,'up')")
                  i.glyphicon.glyphicon-chevron-down(@click="moveElement(data,'down')")
              div(v-else)
                div
                  input(name="edit_org_element" v-model="edit_input" class="form-control edit_input_field" placeholder="Bezeichnung")
                  span.float-right.mt-10
                    i.glyphicon.glyphicon-floppy-disk(@click="editElementSave()")
                    i.ml-10.glyphicon.glyphicon-remove-circle(@click="abortElementSave()")
                div.mt-10
                  input(name="edit_org_element_short" v-model="edit_input_short" class="form-control edit_input_field" placeholder="Bezeichnung Kurz")
        .mt-4
          button.btn.btn-primary(@click="collapseAll") {{ lang.get('default.btn_alle_einklappen') }}
          button.btn.btn-primary.ml-1(@click="expandAll") {{ lang.get('default.btn_alle_ausklappen') }}
      div(v-else)
        | {{ this.lang.get('admin.organisationselement_keine_eintraege') }}
    .col-6
      .sticky
        div(v-if="new_treedata.length")
          Tree(:data="new_treedata" draggable cross-tree @change="treeChange" ref="ref_new_treedata")
            div(slot-scope="{data, store}")
              template(v-if="!data.isDragPlaceHolder")
                b(v-if="data.children && data.children.length" @click="store.toggleOpen(data)") {{data.open ? '-' : '+'}}&nbsp;
                span {{data.name}}
                // span.float-right
                    i.glyphicon.glyphicon-pencil(@click="editElement(data)")
                    i.ml-10.glyphicon.glyphicon-trash(@click="store.deleteNode(data)")
        .mt-2.input-group
           .input-group-prepend
             .input-group-text
               i.glyphicon.glyphicon-pencil
           input.form-control.edit_input_field(name="new_org_element" v-model="new_input")
           button.btn.btn-sm.btn-primary.mt-15(@click="addChild") {{ lang.get('admin.organisationselement_sinular') }} {{ lang.get('default.btn_anlegen') }}
        .row
          .col-12
            button.btn.btn-sm.btn-success.mt-30(@click="saveAllElements()") {{ lang.get('default.btn_speichern') }}
            br
</template>

<script>
    //import Treeselect from '@riophae/vue-treeselect';

    // ***********  Localization ***************
    import Lang from 'lang.js';

    // get the data source
    import translations from '../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['org_gruppe_id_in', 'org_treedata_in', 'locale_in','root_id_in'],
        components: {Tree: vueDraggableNestedTree.DraggableTree},
        data() {
            return {
                org_treedata: this.org_treedata_in,
                new_treedata: [],
                new_input: '',
                edit_node: null,
                edit_input: '',
                edit_input_short: '',
                lang: null,
                root_id: this.root_id_in,
            }
        },
        created() {

            let lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }

            lang.setMessages(translations);

            this.lang = lang;
        },
        mounted() {},

        methods: {
            treeChange(node, targetTree, oldTree) {
                if (this.org_treedata[0]._vm.store.dplh.parent.isRoot || this.org_treedata[0]._vm.store.data[0].length > 1) {
                    targetTree.deleteNode(node);
                    this.new_input = node.name;
                    this.addChild();
                }
                return false;
            },
            onDragEnd(node) {
                if (this.org_treedata[0].id != this.root_id || this.org_treedata[0]._vm.store.data.length > 1) {

                    this.$swal({
                        title: 'Warning',
                        text: "Dieses Element kann nicht verschoben werden!",
                        type: 'warning',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });

                    return false;
                }
            },
            addChild() {
                if (this.new_input.length) {

                    let new_data = {
                        id: null,
                        name: this.new_input,
                        bezeichnung: this.new_input,
                        bezeichnung_kurz: null,
                        draggable: true,
                    };

                    // Ansonsten verhindere das der Eintrag zwei mal erstellt wird
                    let push_new_data = true;

                    for (let check_new_tree in this.new_treedata) {
                        if (this.new_treedata[check_new_tree].name.toLowerCase() === this.new_input.toLowerCase()) {
                            push_new_data = false;
                            break;
                        }
                    }

                    for (let check_org_tree in this.org_treedata) {
                        if (this.org_treedata[check_org_tree].bezeichnung.toLowerCase() === this.new_input.toLowerCase()) {
                            push_new_data = false;
                            break;
                        }
                    }

                    if (push_new_data) {
                        this.new_treedata.push(new_data);
                    } else {
                        this.$swal({
                            title: 'Warning',
                            text: "Dieses Element kann nicht angelegt oder verschoben werden!",
                            type: 'warning',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }

                    this.new_input = '';
                }
            },
            expandAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = true
                })
            },
            collapseAll() {
                treeHelper.breadthFirstSearch(this.org_treedata, node => {
                    node.open = false
                })
            },
            editElement(data) {
                this.edit_node = data;
                this.edit_input = data.bezeichnung ? data.bezeichnung : data.name;
                this.edit_input_short = data.bezeichnung_kurz ? data.bezeichnung_kurz : '';
            },
            editElementSave() {
                this.edit_node.bezeichnung = this.edit_input;
                this.edit_node.bezeichnung_kurz = this.edit_input_short;
                this.edit_node = null;
            },
            abortElementSave() {
                this.edit_node = null;
            },
            deleteElement(data) {
                if (data.parent_id === null) {
                    this.$swal({
                        title: 'Warning',
                        text: "Dieses Element kann nicht gelöscht werden!",
                        type: 'warning',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });

                    return false;
                }

                let self = this;

                self.$swal({
                    title: 'Achtung',
                    text: "Möchten Sie dieses Org-Element wirklich endgültig löschen?",
                    type: 'warning',
                    toast: false,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Löschen'
                }).then((result) => {
                    if (result.value) {

                        if (!data.id) return self.org_treedata[0]._vm.store.deleteNode(data);

                        axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_remove_org_element_from_tree/' + data.id, {})
                            .then(function () {

                                self.org_treedata[0]._vm.store.deleteNode(data);

                                self.$swal({
                                    title: 'Erfolg',
                                    text: "Ihr Org-Element wurde gelöscht",
                                    type: 'success',
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                });
            },
            moveElement(data,direction) {
                if (data.parent_id === null) {
                    this.$swal({
                        title: 'Warning',
                        text: "Dieses Element kann nicht bewegt werden!",
                        type: 'warning',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    return false;
                }

                let self = this;

                if (!data.id) return self.org_treedata[0]._vm.store.deleteNode(data);

                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_move_org_element_in_tree/' + data.id + '/' + direction, {})
                    .then(function () {
                        self.reloadTree();

                        self.$swal({
                            title: 'Erfolg',
                            text: "Dieses Element wurde erfolgreich bewegt!",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            saveAllElements() {

                //console.log(this.org_treedata[0]._vm.store.getPureData());

                let self = this;

                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_store_org_tree', {
                    data: JSON.stringify(this.org_treedata[0]._vm.store.getPureData()),
                })
                    .then(function () {

                        self.$swal({
                            title: 'Erfolg',
                            text: "Ihre Änderungen wurden gespeichert",
                            type: 'success',
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000
                        });

                        window.location.reload();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            reloadTree(){

                self = this;
                axios.post('/admin/org_elemente/gruppen/show/' + this.org_gruppe_id_in + '/api_reload_org_tree', {})
                    .then(function (data) {
                        self.org_treedata = data.data.org_tree;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
        }
        ,
    }
</script>

<style lang="scss">

    .he-tree {
        //border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;

        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: .25rem;

        border-color: #eaecec;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 14px;
    }

    .tree-node {
    }

    .tree-node-inner {
        //padding: 5px;
        padding: .75rem 1.25rem;
        border: 1px solid #ccc;
        border-radius: .25rem;
        cursor: pointer;
    }

    .draggable-placeholder {
    }

    .draggable-placeholder-inner {
        border: 1px dashed #0088F8;
        box-sizing: border-box;
        background: rgba(0, 136, 249, 0.09);
        color: #0088f9;
        text-align: center;
        padding: 0;
        display: flex;
        align-items: center;
    }

    .edit_input_field {
        width: 80% !important;
        display: inline !important;
    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 80px;
    }

    /*.tree3{

        .tree-node-inner{
            border: none;
            padding: 0px;
        }
        .tree-node-inner-back:hover{
            background: #ddd;
        }
    }*/

    /* .tree4{
         .tree-node-inner{
             border: none;
             border-bottom: 1px solid #ccc;
             padding: 5px 10px;
             backgroud: #ccc;
     }
         .draggable-placeholder-inner{
             background-color: orangered;
         }
     }*/

</style>
