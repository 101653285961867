import Kopfzeile from "./pages/Kopfzeile";
import Layout from "./pages/Layout";
import Sortierung from "./pages/Sortierung";
import Fußzeile from "./pages/Fußzeile";
import AufgabenPersonen from "./pages/AufgabenPersonen";
import Sichtbarkeit from "./pages/Sichtbarkeit";
import Anonymisieren from "./pages/Anonymisieren";
import Vorschau from "./pages/Vorschau";
import Wirksamkeitspruefung from "./pages/Wirksamkeitspruefung";
import Risikobewertung from "./pages/Risikobewertung";
import Link from "./pages/Link.vue";

const routes = [
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/kopfzeile',
        name: 'kopfzeile',
        component: Kopfzeile,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/layout*',
        name: 'layout',
        component: Layout,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/sortierung',
        name: 'sortierung',
        component: Sortierung,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/fußzeile',
        name: 'fußzeile',
        component: Fußzeile,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/aufgaben*',
        name: 'aufgaben',
        component: AufgabenPersonen,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/sichtbarkeit*',
        name: 'sichtbarkeit',
        component: Sichtbarkeit,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/anonymisieren',
        name: 'anonymisieren',
        component: Anonymisieren,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/risikobewertung',
        name: 'risikobewertung',
        component: Risikobewertung,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/wirksamkeitspruefung',
        name: 'wirksamkeitspruefung',
        component: Wirksamkeitspruefung,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/vorschau',
        name: 'vorschau',
        component: Vorschau,
    },
    {
        path: '/*/emelder/einstellungen/meldungsvorlagen/edit/*/link',
        name: 'link',
        component: Link,
    }
]

export default routes
