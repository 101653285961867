<template>
    <div>
        <div class="card">
            <div class="card-header card-header-action bg-light">
                <h6>Zusatzinformationen</h6>
                <div class="d-flex align-items-center card-action-wrap">
                    <a class="inline-block mr-15" data-toggle="collapse" href="#collapse_2"
                       aria-expanded="true">
                        <i class="zmdi zmdi-chevron-down"></i>
                    </a>
                </div>
            </div>
            <div id="collapse_2" class="collapse" v-bind:class="{ show: zusatzinformationen_in != '' }">
                <div class="card-body bg-light">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                            <textarea @blur="saveData()" v-model="zusatzinformationen" class="form-control" rows="6"
                                      placeholder="Zusatzinformationen" name="zusatzinformationen"><pre>{{ zusatzinformationen }}</pre></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input"
                                       id="customCheck" v-model="schnellzugriff" :value="schnellzugriff">
                                <label class="custom-control-label" for="customCheck" >Schnellzugriffselement</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input"
                                       id="customCheck2" v-model="notfalldokument" :value="notfalldokument">
                                <label class="custom-control-label" for="customCheck2">Notfalldokument</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: ['id_dokument', 'zusatzinformationen_in', 'schnellzugriff_in', 'notfalldokument_in','locale_in','signed_api_store_route'],
        components: {},
        data() {
            return {
                zusatzinformationen: this.zusatzinformationen_in,
                schnellzugriff: this.schnellzugriff_in,
                notfalldokument: this.notfalldokument_in,
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted(){
            console.log('mounted');
        },
        watch: {
            schnellzugriff: function (val) {
                this.saveData();
            },
            notfalldokument: function (val) {
                this.saveData();
            }
        },
        computed: {},
        methods: {
            saveData() {
                let self = this;

                axios.post(this.signed_api_store_route, {
                    zusatzinformationen: this.zusatzinformationen ? this.zusatzinformationen : '',
                    schnellzugriff: this.schnellzugriff,
                    notfalldokument: this.notfalldokument,
                })
                .then(function (res) {

                })
                .catch(function (error) {
                    console.log(error);
                });
            },
        }
    };
</script>
<style scoped>
</style>