<template>
    <div>
        <div class="chatapp-wrap">
            <chatElementLeft
                :link_to_communication_in="link_to_communication_in"
                :verantwortliche="verantwortliche"
            >
            </chatElementLeft>

            <chatElementRight :chat="chat" :meldung="meldung">
            </chatElementRight>
        </div>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../vue-translations.js';

import chatElementLeft from './components/EMelderComponentMeldungenChatLeftElement';
import chatElementRight from './components/EMelderComponentMeldungenChatRightElement';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

export default {
    props: ['link_to_communication_in', 'chat', 'verantwortliche', 'meldung'],
    components: {chatElementLeft, chatElementRight},
    data() {
        return {
            lang: null,
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {}
};
</script>
<style scoped>
</style>
